import React, { useState, useEffect } from 'react'
import { Button, Grid } from '@mui/material'
import Header from '../Header/Header'
// import ApexChart from './Apexchart'
import Tradeview from './Tradeview';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './Trade.scss'
import OrderBook from './OrderBook';
import { useTheme, useMediaQuery } from '@mui/material';
import Placeorder from './Placeorder';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import usercalls from "../../Axios";
import nodata from '../../Img/thankyou.gif'
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Btcoin from '../../Img/btcoin.png'
import { Drop, Drop2, Info, M1, M2, M3, Msg, Trading, Wifi } from '../../SvgCollection';
import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { useParams, useLocation } from 'react-router-dom';
import consts from '../../constant';
import { toast } from 'react-toastify';
import io from "socket.io-client";
// import FormHelperText from '@mui/material/FormHelperText';
// import FormControl from '@mui/material/FormControl';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const socketInstance = io.connect(consts.socketUrl);

function TradeHistory({ historyStatus, setBalanceStatus }) {

    const loaction = useLocation();
    const placeRoute = loaction.pathname?.split('/')[1];

    const [value, setValue] = useState(0);
    const path = usercalls()
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [orderType, setOrderType] = useState("open")


    const { id } = useParams()
    const [value5, setValue5] = useState(0);

    const handleChange5 = (event, newValue) => {
        var data = ["open", 'history']
        setOrderType(data[newValue])
        setValue5(newValue);
        console.log(newValue, 'newvalue');

    };

    const [value6, setValue6] = useState(0);

    const handleChange6 = (event, newValue) => {
        setValue6(newValue);
    };

    const [value7, setValue7] = useState(0);

    const handleChange7 = (event, newValue) => {
        setValue7(newValue);
    };

    const [age, setAge] = useState(10);

    const [dropDown, setDropDown] = useState(false)

    const handleChangeAge = (event) => {
        setAge(event.target.value);
    };

    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

    const mobilemd = useMediaQuery(theme.breakpoints.up('md'));
    /*drawer */
    const [bysel, setBysel] = useState(null);

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open, content) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
        setBysel(content)
    };
    const [orderHistory, setOrderHistory] = useState([])
    const [positionHistory, setPositionHistory] = useState([])

    const [orderCount, setOrderCount] = useState(0)

    const [connect, setConnect] = useState(false)

    const getHistory = async () => {
        try {
            setOrderHistory([])

            const url = `${consts.BackendUrl}/binanceTrade/orderHistory`

            const payload = {
                pair: id?.split("_")?.join(""),
                orderType: placeRoute == 'future' ? "FUTURE" : orderType
            }

            // console.log(payload, 'payloada');
            const response = await path.postCall({ url, payload });
            const result = await response.json();
            if (result?.success) {
                var ids
                if (orderType == "history") {
                    console.log(result?.result, "result?.result")
                    setOrderHistory(result?.result?.slice(0, 5))
                    setOrderCount(result?.orderCount ? result?.orderCount : 0)
                    ids = result?.result?.length > 0 ? result?.result[0]?.user_id?.toString() : ""
                } else {
                    setOrderCount(result?.result ? result?.result?.length : 0)
                    setOrderHistory(result?.result)
                    ids = result?.result?.length > 0 ? result?.result[0]?.user_id?.toString() : ""
                }
                console.log(ids, "ids")
                if (connect == false && ids != "") {
                    socketInstance.emit('join', {
                        room: ids
                    })
                    setConnect(true)
                }
            }
        } catch (error) {
            console.log("🚀 ~ getHistory ~ error:", error)
        }
    }

    const [socData, setSocData] = useState("")

    useEffect(() => {
        if (socData) {
            if (orderType != "history") {
                setOrderHistory(prevOrders =>
                    prevOrders.filter(order => order._id !== socData?._id)
                );
                setOrderCount(orderCount - 1)
            }
        }
    }, [socData])

    useEffect(() => {
        socketInstance.on('open_order', (data) => {
            console.log(data, "data")
            if (data?.status == "FILLED") {
                setSocData(data)
            }
        })
    }, [socketInstance])

    useEffect(() => {
        if (id && orderType) {
            getHistory()
        }
    }, [id, orderType, historyStatus])

    const cancelOrder = async (orderId) => {
        try {
            var url;
            if (placeRoute == "trade") {
                url = `${consts.BackendUrl}/binanceTrade/cancelOrder`
            } else {
                url = `${consts.BackendUrl}/binanceTrade/future/cancelOrder`
            }

            const payload = {
                orderId: orderId
            }
            const response = await path.postCall({ url, payload });
            const result = await response.json();
            if (result?.success == true) {
                toast.success(result?.message)
                getHistory()
                setBalanceStatus(Math.floor(Math.random() * 90) + 10)
            } else {
                toast.error(result?.message)
            }
        } catch (error) {
            console.log("🚀 ~ cancelOrder ~ error:", error)
        }
    }

    const getPositions = async () => {

        try {

            const url = `${consts.BackendUrl}/binanceTrade/future/openOrders`;

            const payload = {
                symbol: id
            }

            const response = await path.postCall({ url, payload });
            const result = await response.json();

            console.log(result, "positttiiiiooonnn");



            if (result?.success == true) {
                setPositionHistory(result?.result)
            } else {
                toast.error(result?.message)
            }

        } catch (error) {
            console.log("POsition error", error);
        }

    };

    useEffect(() => {
        getPositions();
    }, []);

    return (
        <div className='Tradepage'>
            <Grid container>
                <Grid xs={12} sm={12} md={12} lg={12} xl={10} order={!isLgUp ? 4 : 4}>
                    <Box sx={{ width: '100%' }}>
                        <Box >
                            <Tabs value={value5} onChange={handleChange5} aria-label="basic tabs example" className='tabs-trade'>
                                <Tab label={`Open Orders (${orderCount})`} {...a11yProps(0)} />
                                <Tab label="Order History" {...a11yProps(1)} />
                                <Tab style={{ display: placeRoute == "trade" ? "none" : "" }} label="Positions" {...a11yProps(2)} />
                                {/* <Tab label="Trade History" {...a11yProps(2)} />
                                <Tab label=" Positions" {...a11yProps(3)} />
                                <Tab label=" Trading Algorithm" {...a11yProps(4)} />
                                <Tab label=" Realized PNL" {...a11yProps(5)} /> */}
                            </Tabs>
                        </Box>
                        {/* <CustomTabPanel >
                        <TableContainer >
                            <Table aria-label="simple table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='left'>Date & Time</TableCell>
                                        <TableCell align='center'>Pair</TableCell>
                                        <TableCell align='center'>Type</TableCell>
                                        <TableCell align='center'>Side</TableCell>
                                        <TableCell align='center'>Price</TableCell>
                                        <TableCell align='center'>Quantity</TableCell>
                                        <TableCell align='center'>Total</TableCell>
                                        <TableCell align='center'>Status</TableCell>
                                        {
                                            orderType == "open" &&
                                            <TableCell align='center'>Cancel</TableCell>
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        orderHistory?.length > 0 ? orderHistory?.map((row, index) => {
                                            return (
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    style={{ cursor: 'pointer' }}
                                                    key={index}
                                                >
                                                    <TableCell component="th" scope="row" align='left' >
                                                        {row?.createdAt?.split("T")[0]}: {row?.createdAt?.split("T")[1].split(".")[0]}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align='center'>
                                                        {row?.ccy}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align='center'>
                                                        {row?.type}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align='center' >
                                                        {row?.side}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align='center' >
                                                        {row?.price}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align='center' >
                                                        {row?.quantity}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align='center' >
                                                        {(Number(row?.price) * Number(row?.quantity)).toFixed(4)}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align='center' >
                                                        {row?.status}
                                                    </TableCell>
                                                    {
                                                        orderType == "open" &&
                                                        <TableCell component="th" scope="row" align='center' >
                                                            <Button onClick={() => { cancelOrder(row?.orderId) }} >Cancel</Button>
                                                        </TableCell>
                                                    }
                                                </TableRow>
                                            )
                                        })
                                            :
                                            <TableRow>
                                                <TableCell align='center' colSpan={10}>
                                                    <div className='nodata'>
                                                        <img width="100" height="100" src="https://img.icons8.com/fluency/100/exchange-money-ethereum.png" alt="exchange-money-ethereum" />
                                                        No Record Found
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CustomTabPanel> */}


                        {value5 === 2 ?
                            <CustomTabPanel >
                                <TableContainer >
                                    <Table aria-label="simple table" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align='left'>Date</TableCell>
                                                <TableCell align='center'>Pair</TableCell>
                                                {/* <TableCell align='center'>Type</TableCell> */}
                                                <TableCell align='center'>Side</TableCell>
                                                <TableCell align='center'>Price</TableCell>
                                                <TableCell align='center'>Quantity</TableCell>
                                                {/* <TableCell align='center'>Total</TableCell> */}
                                                {/* <TableCell align='center'>Status</TableCell> */}
                                                {/* {
                                             orderType == "open" &&
                                             <TableCell align='center'>Cancel</TableCell>
                                         } */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {/* {console.log(positionHistory,"positionHistory")} */}
                                            {
                                                positionHistory?.length > 0 ? positionHistory?.map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            style={{ cursor: 'pointer' }}
                                                            key={index}
                                                        >
                                                            <TableCell component="th" scope="row" align='left' >
                                                                {row?.updateTime ? `${String( new Date(row?.updateTime).getDate()).padStart(2, '0')}/${String( new Date(row?.updateTime).getMonth() + 1).padStart(2, '0')}/${ new Date(row?.updateTime).getFullYear()}` : ""}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align='center'>
                                                                {row?.symbol}
                                                            </TableCell>
                                                            {/* <TableCell component="th" scope="row" align='center'>
                                                                {row?.type}
                                                            </TableCell> */}
                                                            <TableCell component="th" scope="row" align='center' >
                                                                {row?.positionSide}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align='center' >
                                                                {row?.entryPrice}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align='center' >
                                                                {row?.positionAmt}
                                                            </TableCell>
                                                            {/* <TableCell component="th" scope="row" align='center' >
                                                         {(Number(row?.price) * Number(row?.quantity)).toFixed(4)}
                                                     </TableCell>
                                                     <TableCell component="th" scope="row" align='center' >
                                                         {row?.status}
                                                     </TableCell> */}
                                                            {/* {
                                                         orderType == "open" &&
                                                         <TableCell component="th" scope="row" align='center' >
                                                             <Button onClick={() => { cancelOrder(row?.orderId) }} >Cancel</Button>
                                                         </TableCell>
                                                     } */}
                                                        </TableRow>
                                                    )
                                                })
                                                    :
                                                    <TableRow>
                                                        <TableCell align='center' colSpan={10}>
                                                            <div className='nodata'>
                                                                <img width="100" height="100" src="https://img.icons8.com/fluency/100/exchange-money-ethereum.png" alt="exchange-money-ethereum" />
                                                                No Record Found
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CustomTabPanel>

                            :
                            <CustomTabPanel >
                                <TableContainer >
                                    <Table aria-label="simple table" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align='left'>Date & Time</TableCell>
                                                <TableCell align='center'>Pair</TableCell>
                                                <TableCell align='center'>Type</TableCell>
                                                <TableCell align='center'>Side</TableCell>
                                                <TableCell align='center'>Price</TableCell>
                                                <TableCell align='center'>Quantity</TableCell>
                                                <TableCell align='center'>Total</TableCell>
                                                <TableCell align='center'>Status</TableCell>
                                                {
                                                    orderType == "open" &&
                                                    <TableCell align='center'>Cancel</TableCell>
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                orderHistory?.length > 0 ? orderHistory?.map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            style={{ cursor: 'pointer' }}
                                                            key={index}
                                                        >
                                                            <TableCell component="th" scope="row" align='left' >
                                                                {row?.createdAt?.split("T")[0]}: {row?.createdAt?.split("T")[1].split(".")[0]}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align='center'>
                                                                {row?.ccy}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align='center'>
                                                                {row?.type}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align='center' >
                                                                {row?.side}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align='center' >
                                                                {row?.price}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align='center' >
                                                                {row?.quantity}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align='center' >
                                                                {(Number(row?.price) * Number(row?.quantity)).toFixed(4)}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align='center' >
                                                                {row?.status}
                                                            </TableCell>
                                                            {
                                                                orderType == "open" &&
                                                                <TableCell component="th" scope="row" align='center' >
                                                                    <Button onClick={() => { cancelOrder(row?.orderId) }} >Cancel</Button>
                                                                </TableCell>
                                                            }
                                                        </TableRow>
                                                    )
                                                })
                                                    :
                                                    <TableRow>
                                                        <TableCell align='center' colSpan={10}>
                                                            <div className='nodata'>
                                                                <img width="100" height="100" src="https://img.icons8.com/fluency/100/exchange-money-ethereum.png" alt="exchange-money-ethereum" />
                                                                No Record Found
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CustomTabPanel>
                        }


                    </Box>
                </Grid>

                {/* <Grid xs={12} sm={12} md={12} lg={12} xl={2} order={!isLgUp ? 4 : 4}>
                    <Box sx={{ width: '100%' }}>
                        <Box >
                            <Tabs value={value7} onChange={handleChange7} aria-label="basic tabs example" className='tabs-trade'>
                                <Tab label="Asset Overview" {...a11yProps(0)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value7} index={0}>
                            <div className="display-2 mt-10">
                                <div className="aval">
                                    Trading Account
                                </div>
                                <div className="display-1 usd">
                                    <div className="">
                                        0 USDT
                                    </div>
                                </div>
                            </div>
                            <div className="display-2 mt-10">
                                <div className="aval">
                                    USDT
                                </div>
                                <div className="display-1 usd">
                                    <div className="">
                                        0 USDT
                                    </div>
                                </div>
                            </div>
                            <div className="display-2 mt-10">
                                <div className="aval">
                                    BTC
                                </div>
                                <div className="display-1 usd">
                                    <div className="">
                                        0 USDT
                                    </div>
                                </div>
                            </div>

                            <div className="display-2 mt-10">
                                <div className="aval">
                                    Funding Account
                                </div>
                                <div className="display-1 usd">
                                    <div className="">
                                        0 USDT
                                    </div>
                                </div>
                            </div>

                            <div className="display-2 mt-10">
                                <div className="aval">
                                    BTC
                                </div>
                                <div className="display-1 usd">

                                    <div className="">
                                        0 USDT
                                    </div>
                                </div>
                            </div>
                            <div className="display-2 mt-20">
                                <div className="dep-trns-tbn ">
                                    <Button variant='contained'>Deposit </Button>
                                </div>
                                <div className="dep-trns-tbn ">
                                    <Button variant='contained'>Transfer </Button>
                                </div>
                            </div>

                        </CustomTabPanel>
                        <CustomTabPanel value={value6} index={1}>

                        </CustomTabPanel>

                    </Box>
                </Grid> */}
            </Grid>
        </div >
    )
}

export default TradeHistory
