import { Grid } from '@mui/material'
import React, { useState } from 'react'
import Header from '../Header/Header'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import darkarrow from "../../Img/dark-Layer.png";
import lightarrow from "../../Img/light-layer.png";
import { Link } from "react-router-dom";
import Support2 from '../Support/Support2'
import Stack from '@mui/material/Stack';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {
    TextField,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


function OrderDetail() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    
    const confirmpay = (
        <div className='makepay-main'>
            <div>
                Confirm Order info
            </div>
            <div className='display-1 time-details'>
                <div>
                    Amount
                    <div className='order green'>
                        98INR
                    </div>
                </div>
                <div>
                    Price
                    <div className='order'>
                        98 INR
                    </div>
                </div>
                <div>
                    Receive Quantity
                    <div className='order'>
                        1 USDT
                    </div>
                </div>
            </div>
        </div>

    )

    const makepay = (
        <div className='makepay-main'>
            <div>
                Make Payment
            </div>
            <div>
                Transfer the funds to the seller’s account provided below.
            </div>
            <div className='make-pay-inner'>
                <div className='display-1 added-list'>
                    <div>
                        <div className='paymeny-type-text main'>
                            Bank
                        </div>
                    </div>


                </div>
                <div>
                    <div className='order-num'>
                        Name:<span>vishnu</span>
                    </div>
                    <div className='order-num'>
                        ACCOUNT NO:<span>875757943759702</span>
                    </div>
                    <div className='order-num'>
                        IFSC CODE<span>IO354435</span>
                    </div>
                </div>

                <div className='display-1 added-list'>
                    <div>
                        <div className='paymeny-type-text main'>
                            UPI
                        </div>
                    </div>


                </div>
                <div>
                    <div className='order-num'>
                        Name:<span>vishnu</span>
                    </div>
                    <div className='order-num'>
                        UPI ID:<span>Sankar@ybl</span>
                    </div>

                </div>

            </div>

        </div>
    )


    const cnfpay = (
        <div className='makepay-main'>
            <div>
                Confirm Payment
            </div>
            <div>
                After transferring the funds, click on the "Transferred, Notify Seller" button.
            </div>
        </div>
    )
    const steps = [confirmpay, makepay, cnfpay];

    const [perChat, setPerChat] = useState([])
    const [activeStep, setActiveStep] = React.useState(3);
    const [skipped, setSkipped] = React.useState(new Set());

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };



    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState("");
    const [updated, setUpdated] = useState('');
    const [message, setMessage] = useState([]);
    const [movileView, setMovileView] = useState(false)

    const handleSend = () => {
        if (input.trim()) {
            setMessages([...messages, input.trim()]);
            setInput("");
        }
    };

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };


    const handleChangeSend = (event) => {
        setUpdated(event.target.value);

    };

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleClickSend();
        }
    };


    const handleClickSend = (e) => {
        // 👇 "message" stores input field value

        if (updated?.trim() !== '') {
            setMessage([...message, updated])
            setUpdated('')
        }
    };

    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleToggle = () => {
        setIsCollapsed(!isCollapsed);
    };

    const handleChatData = (val) => {
        setPerChat(val)
        if (!isCollapsed && matches) {
            handleToggle()
        } else {
            setMovileView(true)
        }

    }
    return (
        <div className='order-details'>
            <Grid container className='justi-center'>

                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Header />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <div class="ivon-back">
            <Link to="/p2p" className="light-mode-img">
              <img src={darkarrow} />
            </Link>
            <Link to="/p2p" className="dark-mode-img">
              <img src={lightarrow} />
            </Link>
          </div>
                    <div className='display-2 order-details-mian'>
                        <div className='order-cancel-text'>
                        Order Confirmed
                        </div>
                        <div>
                            <div className='order-num'>
                                Order Number:<span>TXNIAFH879A4D6A</span>
                            </div>

                            <div className='order-num'>
                                Time Created: <span>2024-05-16</span>
                            </div>
                        </div>
                    </div>

                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={10}>
                    <Grid container className='justi-center'>
                        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                            <div>
                                <Box sx={{ width: '100%' }}>
                                    <Stepper activeStep={activeStep} orientation="vertical" className="stepper-line">
                                        {steps.map((label, index) => {
                                            const stepProps = {};
                                            const labelProps = {};
                                            if (isStepOptional(index)) {
                                                labelProps.optional = (
                                                    <Typography variant="caption"></Typography>
                                                );
                                            }
                                            if (isStepSkipped(index)) {
                                                stepProps.completed = false;
                                            }
                                            return (
                                                <Step key={label} {...stepProps}>
                                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                    {activeStep === steps.length ? (
                                        <React.Fragment>
                                            {/* <Typography sx={{ mt: 2, mb: 1 }}>
                                        All steps completed - you&apos;re finished
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button onClick={handleReset}>Reset</Button>
                                    </Box> */}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                                <Button
                                                    color="inherit"
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                    sx={{ mr: 1 }}
                                                >
                                                    Back
                                                </Button>
                                                <Box sx={{ flex: '1 1 auto' }} />
                                                {isStepOptional(activeStep) && (
                                                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                                        Skip
                                                    </Button>
                                                )}

                                                <Button onClick={handleNext}>
                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                </Button>
                                            </Box>
                                        </React.Fragment>
                                    )}
                                </Box>
                            </div>

                            <div className='transfer-notify'>
                                <Button>Transferred, notify seller</Button>
                                <Button className='bg-red'>Cancel</Button>
                            </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                            {/* <div className="ticket-box">
                                <div className="ticket display-1">
                                    <div className="ticket-head">
                                        <p>Chat</p>
                                    </div>
                                </div>
                                <div className="colab-text-input">
                                    <div className='chat-space'>
                                        <div className="message-send-box">
                                            <div className="profile-img">
                                                <Avatar src="/broken-image.jpg" />
                                            </div>
                                            <span>Test</span>
                                        </div>
                                        <div className="message-send-box">

                                            <List sx={{ maxHeight: 300, overflowY: "auto" }}>
                                                {messages.map((message, index) => (
                                                    <ListItem key={index}>
                                                        <div className="profile-img">
                                                            <Avatar src="/broken-image.jpg" />
                                                        </div>
                                                        <ListItemText primary={message} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </div>
                                    </div>
                                    <div className="message-sent">

                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            value={input}
                                            onChange={handleInputChange}
                                            onKeyPress={(event) => {
                                                if (event.key === "Enter") {
                                                    handleSend();
                                                }
                                            }}
                                        />
                                        <div className="colab-send">
                                            <SendIcon onClick={handleSend} />
                                        </div>

                                    </div>
                                </div>
                            </div> */}
                                    <div className="msglist">
            <div className="colab-avatar-cont vr">
                <Stack direction="row" spacing={-0.5}>
                    {<div div className="cursor">
                        {!isCollapsed ? <ArrowBackIosIcon sx={{ fontSize: '25px !important', fill: '#7a7a7a' }}
                            onClick={(() => { movileView ? setMovileView(false) : handleToggle() })} /> : <ArrowForwardIosIcon sx={{ fontSize: '25px !important', fill: '#7a7a7a' }} onClick={handleToggle} />}
                    </div>}
                    {/* <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={artist1} />
                    <Avatar sx={{ width: 30, height: 30 }} alt="Travis Howard" src={artist2} />
                    <Avatar sx={{ width: 30, height: 30 }} alt="Cindy Baker" src={artist3} /> */}
                </Stack>
            </div>
            <div className="colab-chat-area msg">


                <div className="chat-area-inner-text vr">
                    <div>
                        <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={perChat.img} />
                    </div>
                    <div className="collab-send-text-main">
                        <div className="username">{perChat.name}(dev)</div>
                        <div className="txt1">{perChat.noftify}</div>
                        <div className="time">11:45am</div>
                    </div>
                </div>
                {message.map((todo, ind) => {
                    return (

                        <div className="chat-area-inner-text user vr" key={ind}>
                            <div>
                                <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={perChat.img} />
                            </div>
                            <div className="collab-send-text-main2 user">
                                <div className="username">Me(dev)</div>
                                <div className="txt1">{todo}</div>
                                <div className="time">11:45am</div>
                            </div>
                        </div>
                    )
                })}
            </div>



            <div class="messageBox">
                <div class="fileUploadWrapper">
                    <label for="file">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 337 337">
                            <circle
                                stroke-width="20"
                                stroke="#6c6c6c"
                                fill="none"
                                r="158.5"
                                cy="168.5"
                                cx="168.5"
                            ></circle>
                            <path
                                stroke-linecap="round"
                                stroke-width="25"
                                stroke="#6c6c6c"
                                d="M167.759 79V259"
                            ></path>
                            <path
                                stroke-linecap="round"
                                stroke-width="25"
                                stroke="#6c6c6c"
                                d="M79 167.138H259"
                            ></path>
                        </svg>
                        <span class="tooltip">Add an image</span>
                    </label>
                    <input type="file" id="file" name="file" />
                </div>
                <input required="" placeholder="Message..." type="text" id="messageInput"
                    value={updated}
                    onChange={handleChangeSend}
                    onKeyDown={handleEnterKeyPress}
                />
                <button id="sendButton" onClick={handleClickSend}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 664 663">
                        <path
                            fill="none"
                            d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"
                        ></path>
                        <path
                            stroke-linejoin="round"
                            stroke-linecap="round"
                            stroke-width="33.67"
                            stroke="#6c6c6c"
                            d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"
                        ></path>
                    </svg>
                </button>
            </div>

        </div>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </div>
    )
}

export default OrderDetail
