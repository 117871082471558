import { Grid } from '@mui/material'
import React, { useState } from 'react'
import construct from '../../Img/404 1.png'
import maintain from '../../Img/maintainance.png'
import './construction.scss'
import Header from '../Header/Header'

function Page404() {
    return (
        <div className='page-404'>
            <Grid container>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Header />
                </Grid>
            </Grid>
            <div className='underconstruction'>

                <Grid container>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='underconst-main-div new'>
                            <div className='main-banner-div' >

                                <div className='under'>
                                    Oops,
                                    <div className='construct-text'>
                                        something <div>
                                            went wrong...
                                        </div>
                                    </div>
                                </div>
                                <div className='return-home'>
                                    Return to Home Page
                                </div>
                            </div>
                            <div className='construct-image'>
                                <img src={construct} />
                            </div>
                        </div>

                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Page404
