import { Button, Grid } from "@mui/material";
import React from "react";
import Header from "../Header/Header";
import "./Swap.scss";
import { TextField } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const Swap = () => {
    const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <div className="swap-full">
      <Grid container>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header />
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="support-head contain-width">
            <h1>Swap</h1>
          </div>
          <Grid container className="contain-width" spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="ticket-box">
                <div className="spend-input">
                  <div className=" display-1">
                    <div className="balance">
                      <label>Spend</label>
                    </div>

                    <div className="balance">
                      <label>Balance</label>
                      <br />
                      <label>0 BTC</label>
                    </div>
                  </div>
                  <div className="balance-input">
                    <div className="text-area">
                    <TextField fullWidth type="text" />
                    </div>
                   
                    <div className="select-drop">
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <Select
                          value={age}
                          onChange={handleChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value="">
                            <em>BTC</em>
                          </MenuItem>
                          <MenuItem value={10}>cheel</MenuItem>
                          <MenuItem value={20}>BTC</MenuItem>
                          <MenuItem value={30}>BNb</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>

                <div className="spend-input">
                  <div className=" display-1">
                    <div className="balance">
                      <label>Receive</label>
                    </div>

                    <div className="balance">
                      <label>Balance</label>
                      <br />
                      <label>0 BTC</label>
                    </div>
                  </div>
                  <div className="balance-input">
                    <div className="text-area">
                    <TextField fullWidth type="text" />
                    </div>
                   
                    <div className="select-drop">
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <Select
                          value={age}
                          onChange={handleChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value="">
                            <em>BTC</em>
                          </MenuItem>
                          <MenuItem value={10}>cheel</MenuItem>
                          <MenuItem value={20}>BTC</MenuItem>
                          <MenuItem value={30}>BNb</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className="pecentage-box">
                    <div className="display-1">
                        <div className="pecentage-value">
                            <span>Fee Percentage:</span>
                        </div>
                        <div className="pecentage-value">
                            <span>1</span>
                        </div>
                    </div>
                    <div className="display-1">
                        <div className="pecentage-value">
                            <span>Swap Fee :</span>
                        </div>
                        <div className="pecentage-value">
                            <span>0.0000 ETH</span>
                        </div>
                    </div>
                </div>
                <div className="convert-btn">
                    <Button variant="contained">Convert</Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Swap;
