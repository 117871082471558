import { Grid } from '@mui/material'
import React, { useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import nodata from '../../Img/thankyou.gif';
import darkarrow from "../../Img/dark-Layer.png";
import lightarrow from "../../Img/light-layer.png";
import { Link } from "react-router-dom";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function MyAds() {

    const navigate = useNavigate()

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [value2, setValue2] = useState(0);

    const handleChange2 = (event, newValue) => {
        setValue2(newValue);
    };

    return (
        <div className='Myads'>
             <Grid container>
             <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Header />
                </Grid>
             </Grid>
             <div className='myads-full'>
             <Grid container>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <div class="ivon-back">
            <Link to="/paymentMethod" className="light-mode-img">
              <img src={darkarrow} />
            </Link>
            <Link to="/paymentMethod" className="dark-mode-img">
              <img src={lightarrow} />
            </Link>
          </div>
                    <div className='post-ad-title text-center'>
                    
                        My Ads
                    </div>

                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} className='ticket-box'>
                    <Box sx={{ padding: '10px 0px' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Processing" {...a11yProps(0)} />
                            <Tab label="Order" {...a11yProps(1)} />

                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <div>
                            <TableContainer >
                                <Table aria-label="simple table" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align='left'>Type/coin </TableCell>
                                            <TableCell align='right'>Flat amount</TableCell>
                                            <TableCell align='right'>Price</TableCell>
                                            <TableCell align='right'>Crypto amount</TableCell>
                                            <TableCell align='right'>Status</TableCell>
                                            <TableCell align='right'>Price</TableCell>
                                            <TableCell align='right'>Operation</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align='center' colSpan={10}>
                                                <div className='nodata'>
                                                    <img src={nodata} alt='nodata' />
                                                    No Record Found
                                                </div>


                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Box className="completd-table">
                            <Tabs value={value2} onChange={handleChange2} aria-label="basic tabs example">
                                <Tab label="All" {...a11yProps(0)} />
                                <Tab label="Completed" {...a11yProps(1)} />
                                <Tab label="Cancelled" {...a11yProps(2)} />

                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value2} index={0}>
                            <div>
                                <TableContainer >
                                    <Table aria-label="simple table" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align='left'>Type/coin </TableCell>
                                                <TableCell align='left'>Flat amount</TableCell>
                                                <TableCell align='left'>Price</TableCell>
                                                <TableCell align='left'>Crypto amount</TableCell>
                                                <TableCell align='left'>Status</TableCell>

                                                <TableCell align='right'>Operation</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align='center' colSpan={10}>
                                                    <div className='display-1'>
                                                        2024-05-16
                                                        <div className='green cursor' onClick={(() => { navigate('/orderdetails') })}>
                                                            TXNIAFH879A4D6A
                                                        </div>
                                                    </div>
                                                </TableCell>

                                            </TableRow>
                                            <TableRow className='iner-bg-row'>
                                                <TableCell align='left'>
                                                    USDT
                                                </TableCell>
                                                <TableCell align='left'>
                                                    98
                                                </TableCell>
                                                <TableCell align='left'>
                                                    98
                                                </TableCell>
                                                <TableCell align='left'>
                                                    1
                                                </TableCell>
                                                <TableCell align='left'>
                                                    Cancelled
                                                </TableCell>
                                                <TableCell align='right'>
                                                    <div onClick={(() => { navigate('/support') })} className='cursor green'>
                                                        Contact
                                                    </div>

                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align='center' colSpan={10}>
                                                    <div className='display-1'>
                                                        2024-05-16
                                                        <div className='green cursor' onClick={(() => { navigate('/orderdetails') })}>
                                                            TXNIAFH879A4D6A
                                                        </div>
                                                    </div>
                                                </TableCell>

                                            </TableRow>
                                            <TableRow className='iner-bg-row'>
                                                <TableCell align='left'>
                                                    USDT
                                                </TableCell>
                                                <TableCell align='left'>
                                                    98
                                                </TableCell>
                                                <TableCell align='left'>
                                                    98
                                                </TableCell>
                                                <TableCell align='left'>
                                                    1
                                                </TableCell>
                                                <TableCell align='left'>
                                                    Cancelled
                                                </TableCell>
                                                <TableCell align='right'>
                                                    <div onClick={(() => { navigate('/support') })} className='cursor green'>
                                                        Contact
                                                    </div>

                                                </TableCell>
                                            </TableRow>
                                            {/* <TableRow >
                                                <TableCell align='center' colSpan={10}>
                                                    <div className='nodata'>
                                                        <img src={nodata} alt='nodata' />
                                                        No Record Found
                                                    </div>


                                                </TableCell>
                                            </TableRow> */}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value2} index={1}>
                            <div>
                                <TableContainer >
                                    <Table aria-label="simple table" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align='left'>Type/coin </TableCell>
                                                <TableCell align='right'>Flat amount</TableCell>
                                                <TableCell align='right'>Price</TableCell>
                                                <TableCell align='right'>Crypto amount</TableCell>
                                                <TableCell align='right'>Status</TableCell>
                                                <TableCell align='right'>Price</TableCell>
                                                <TableCell align='right'>Operation</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align='center' colSpan={10}>
                                                    <div className='nodata'>
                                                        <img src={nodata} alt='nodata' />
                                                        No Record Found
                                                    </div>


                                                </TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value2} index={2}>
                            <div>
                                <TableContainer >
                                    <Table aria-label="simple table" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align='left'>Type/coin </TableCell>
                                                <TableCell align='right'>Flat amount</TableCell>
                                                <TableCell align='right'>Price</TableCell>
                                                <TableCell align='right'>Crypto amount</TableCell>
                                                <TableCell align='right'>Status</TableCell>
                                                <TableCell align='right'>Price</TableCell>
                                                <TableCell align='right'>Operation</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align='center' colSpan={10}>
                                                    <div className='nodata'>
                                                        <img src={nodata} alt='nodata' />
                                                        No Record Found
                                                    </div>


                                                </TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </CustomTabPanel>
                    </CustomTabPanel>
                </Grid>


            </Grid>
             </div>
 
        </div>
    )
}
