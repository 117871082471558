import { Button, Grid, TextField, Avatar } from "@mui/material";
import React, { useState } from "react";
import Header from "../Header/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import darkarrow from "../../Img/dark-Layer.png";
import lightarrow from "../../Img/light-layer.png";
import { Link } from "react-router-dom";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function AddPayment() {
  const navigate = useNavigate();

  const val = useLocation();

  const result = val.state;

  console.log(result, "log");

  /*Data collection */

  const [name, setName] = useState("");
  const [upiId, setUpiId] = useState("");

  const [imageUrl, setImageUrl] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    console.log(reader, "result of file");

    reader.onloadend = () => {
      setImageUrl(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handleConfirm = () => {
    const formData = {
      name,
      upiId,
      imageUrl,
    };
    navigate("/paymentMethod", { state: formData });
  };

  return (
    <div className="Addpayment">
      <Grid container className="justi-center">
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header />
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
        <div class="ivon-back">
            <Link to="/paymentMethod" className="light-mode-img">
              <img src={darkarrow} />
            </Link>
            <Link to="/paymentMethod" className="dark-mode-img">
              <img src={lightarrow} />
            </Link>
          </div> 
                    <div className='post-ad-title text-center'>
                    
                        {/* <ArrowBackIcon onClick={(() => { navigate(-1) })} /> */}
                        Post Normal Ad
                    </div>
                </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={9}>
{/* 
          <div class="ivon-back post-ad-title text-center">
            <Link to="/wallet" className="light-mode-img">
              <img src={darkarrow} />
            </Link>
            <Link to="/wallet" className="dark-mode-img">
              <img src={lightarrow} />
            </Link>
            Post Normal Ad
          </div> */}
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={5}>
          <div className="add-payment-main">
            <div className="paymeny-type-text">
              {result === "0"
                ? "BANK TRANSFER"
                : result === "1"
                ? "GPAY"
                : "UPI"}
            </div>
            {result === "0" ? (
              <>
                <div className="add-pay-input-main">
                  <div className="text">Account Holder Name</div>
                  <div className="pric-vol">
                    <TextField
                      id="outlined-basic"
                      placeholder="Name"
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="add-pay-input-main">
                  <div className="text">Account Number</div>
                  <div className="pric-vol">
                    <TextField
                      id="outlined-basic"
                      placeholder="Account Number"
                      type="number"
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="add-pay-input-main">
                  <div className="text">IFSC Code</div>
                  <div className="pric-vol">
                    <TextField
                      id="outlined-basic"
                      placeholder="IFSC Code"
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="add-pay-input-main">
                  <div className="text">Account Type</div>
                  <div className="pric-vol">
                    <TextField
                      id="outlined-basic"
                      placeholder="Account Type"
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="add-pay-input-main">
                  <div className="text">Bank Name</div>
                  <div className="pric-vol">
                    <TextField
                      id="outlined-basic"
                      placeholder="Bank Name"
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="addpay-confm">
                  <Button variant="contained">Confirm</Button>
                </div>
              </>
            ) : result === "2" ? (
              <>
                <div className="add-pay-input-main">
                  <div className="text">Name</div>
                  <div className="pric-vol">
                    <TextField
                      id="outlined-basic"
                      placeholder="Name"
                      variant="outlined"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="add-pay-input-main">
                  <div className="text">UPI ID</div>
                  <div className="pric-vol">
                    <TextField
                      id="outlined-basic"
                      placeholder="Enter UPI ID"
                      variant="outlined"
                      value={upiId}
                      onChange={(e) => {
                        setUpiId(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="add-pay-input-main">
                  <div className="text">UPI QR code(Optional)</div>
                  <div className="pric-vol">
                    {imageUrl && (
                      <div className="qr-image-upload">
                        <img src={imageUrl} alt="img" />
                      </div>
                    )}
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      // startIcon={<CloudUploadIcon />}
                    >
                      Upload
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleImageUpload}
                      />
                    </Button>
                  </div>
                  <div className="addpay-confm">
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleConfirm();
                      }}
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="add-pay-input-main">
                  <div className="text">Name</div>
                  <div className="pric-vol">
                    <TextField
                      id="outlined-basic"
                      placeholder="Name"
                      variant="outlined"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="add-pay-input-main">
                  <div className="text">GPAY ID</div>
                  <div className="pric-vol">
                    <TextField
                      id="outlined-basic"
                      placeholder="Enter UPI ID"
                      variant="outlined"
                      value={upiId}
                      onChange={(e) => {
                        setUpiId(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="add-pay-input-main">
                  <div className="text">GPAY QR code(Optional)</div>
                  <div className="pric-vol">
                    {imageUrl && (
                      <div className="qr-image-upload">
                        <img src={imageUrl} alt="img" />
                      </div>
                    )}
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      // startIcon={<CloudUploadIcon />}
                    >
                      Upload
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleImageUpload}
                      />
                    </Button>
                  </div>
                  <div className="addpay-confm">
                    <Button variant="contained">Confirm</Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default AddPayment;
