import { Grid } from '@mui/material'
import React, { useState } from 'react'
import construct from '../../Img/construction.png'
import maintain from '../../Img/maintainance.png'
import './construction.scss'
import Header from '../Header/Header'


function UnderMaintain() {
    return (
        <div>
            <Grid container>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Header />
                </Grid>

            </Grid>
            <div className='underconstruction'>

                <Grid container sx={{ justifyContent: 'flex-end' }}>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='underconst-main-div new'>
                            <div className='main-banner-div new'>
                                <div className='we-are'>
                                    We are
                                </div>
                                <div className='under'>
                                    Under,
                                    <div className='construct-text'>
                                        Maintenance
                                    </div>
                                </div>
                                <div className='we-are'>
                                    Will be Back Soon!
                                </div>
                            </div>
                            <div className='construct-image'>
                                <img src={maintain} />
                            </div>
                        </div>

                    </Grid>
                </Grid>


            </div>
        </div>
    )
}

export default UnderMaintain
