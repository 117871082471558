import React, { useState } from "react";
import "./P2P.scss";
import { Grid, Box, Button } from "@mui/material";
import Header from "../../Components/Header/Header";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import PaymentIcon from "@mui/icons-material/Payment";
import AddBoxIcon from "@mui/icons-material/AddBox";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RefreshIcon from "@mui/icons-material/Refresh";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Avatar from "@mui/material/Avatar";
import nodata from "../../Img/thankyou.gif";
import Chip from "@mui/material/Chip";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";

const buyp2p = [
  { name: "Sankar", order: "13 Orders", live: "online", amt: "98.65$" },
  { name: "Vijay", order: "13 Orders", live: "offline", amt: "98.65$" },
  { name: "Kishpre", order: "13 Orders", live: "online", amt: "98.65$" },
  { name: "Barath", order: "13 Orders", live: "offline", amt: "98.65$" },
];

const primaryTabData = [
  {
    label: "Buy",
    content: [
      { label: "USDT", content: <P2PTable /> },
      { label: "BTC", content: <P2PTable /> },
    ],
  },
  {
    label: "Sell",
    content: [
      { label: "USDT", content: <P2PSellTable /> },
      { label: "BTC", content: <P2PSellTable /> },
    ],
  },
];

const tabData = [{ label: "Buy" }, { label: "Sell" }];
const tabUsdtData = [{ label: "USDT" }, { label: "BTC" }];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "900px",
  bgcolor: "var(--clr-card)",
  borderRadius: "12px",
  height: "500px",
  overflow: "scroll",
  "@media(max-width:991.98px)": {
    width: "75%",
  },
  boxShadow: 24,
  p: 4,
};

function P2PTable() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [Sell, setSell] = useState(false);
  const handleSellOpen = () => setSell(true);
  const handleSellClose = () => setSell(false);

  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const [Buy, setBuy] = useState(false);
  const handleBuyOpen = () => setBuy(true);
  const handleBuyClose = () => setBuy(false);
  const navigate = useNavigate();

  return (
    <div className="buy-sell-main-tab2 ">
      <TableContainer>
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left">Advertiser </TableCell>
              <TableCell align="center">Price</TableCell>
              <TableCell align="center">Available | Limits</TableCell>
              <TableCell align="center">Payment Methods</TableCell>
              <TableCell align="center">Transaction Fees</TableCell>
            </TableRow>
          </TableHead>
          {buyp2p.length > 0 ? (
            <TableBody>
              {buyp2p.map((row, ind) => (
                <TableRow key={ind}>
                  <TableCell align="left">
                    <div className="display-1 gap">
                      <Avatar
                        alt="Advertiser"
                        src="/static/images/avatar/1.jpg"
                      />
                      <div className="table-profile-text">
                        <div className="usr">{row.name}</div>
                        <div className="order">{row.order}</div>
                        <div
                          className={
                            row.live === "online" ? "green order" : "red order"
                          }
                        >
                          {row.live}
                        </div>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="p2p-table-price">{row.amt}</div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="display-2 aval">
                      Available: <span className="p2p-table-price">98.58$</span>
                    </div>
                    <div className="display-2 aval">
                      Limits: <span className="p2p-table-price">98.58$</span>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="display-2 gap aval">
                      <Chip icon={<AccountBalanceIcon />} label="Bank" />
                      <Chip icon={<AccountBalanceWalletIcon />} label="UPI" />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className="buyusdt">
                      <Button className="bg-green" onClick={handleOpen}>
                        Buy USDT
                      </Button>
                    </div>
                  </TableCell>

                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 1000,
                      },
                    }}
                  >
                    <Fade in={open}>
                      <Box sx={style}>
                        <div
                          className="close-menu text-end cursor"
                          onClick={() => {
                            handleClose();
                          }}
                        >
                          <CloseIcon />
                        </div>
                        <Grid container>
                          <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                            <div className="display-1 gap">
                              <div className="pop-avatar">
                                <Avatar
                                  alt="Remy Sharp"
                                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQE4gyGOCj-ir1zYqWIgZUBJWSBZsXJtevdpjJlWpzVAg&s"
                                  sx={{ width: 100, height: 100 }}
                                />
                              </div>
                              <div className="table-prfile-text pop">
                                <div className="usr">Sankar</div>
                                <div className="order">
                                  13 Orders | 69.23% completion
                                </div>
                              </div>
                            </div>
                            <div className="display-1 time-details">
                              <div>
                                15 Minutes
                                <div className="order">payment Time Limit</div>
                              </div>
                              <div>
                                5.81 Minutes
                                <div className="order">Avg Release Time</div>
                              </div>
                              <div>
                                8.97584 USDT
                                <div className="order">Available</div>
                              </div>
                            </div>
                            <div className="display-2 gap aval2">
                              <Chip
                                icon={<AccountBalanceIcon />}
                                label="Bank"
                              />
                              <Chip
                                icon={<AccountBalanceWalletIcon />}
                                label="UPI"
                              />
                            </div>
                            <div className="pop-desc-main">
                              <div className="pop-desc">
                                Advertiser's Terms{" "}
                                <span className="red">
                                  (Please read carefully)
                                </span>
                              </div>
                              <div className="pop-desc">
                                First Read condition.
                              </div>
                              <div className="pop-desc">
                                All payment options are available.
                              </div>
                            </div>
                          </Grid>
                          <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                            <div className="pop-right-main">
                              <div className="price">
                                Price: <span className="green">98 INR</span>
                              </div>
                              <div className="will-pay">
                                <div className="will-pay-text">I Will Pay</div>
                                <div className="pop-right-input">
                                  <FormControl
                                    sx={{ m: 1, width: "100%" }}
                                    variant="outlined"
                                  >
                                    <OutlinedInput
                                      id="outlined-adornment-weight"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          INR
                                        </InputAdornment>
                                      }
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        "aria-label": "weight",
                                      }}
                                      placeholder="0.00"
                                    />
                                  </FormControl>
                                </div>
                              </div>
                              <div className="will-pay">
                                <div className="will-pay-text">
                                  I Will recieve
                                </div>
                                <div className="pop-right-input">
                                  <FormControl
                                    sx={{ m: 1, width: "100%" }}
                                    variant="outlined"
                                  >
                                    <OutlinedInput
                                      id="outlined-adornment-weight"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          USDT
                                        </InputAdornment>
                                      }
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        "aria-label": "weight",
                                      }}
                                      placeholder="1-5"
                                    />
                                  </FormControl>
                                </div>
                              </div>
                              <div className="display-3 gap">
                                <Button
                                  onClick={handleClose}
                                  className="cancel-btn"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  variant="contained"
                                  className="bg-green"
                                  onClick={handleBuyOpen}
                                >
                                  Buy
                                </Button>
                                <Modal
                                  open={Buy}
                                  onClose={handleBuyClose}
                                  aria-labelledby="modal-modal-title"
                                  aria-describedby="modal-modal-description"
                                >
                                  <Box className="id-popup terms-modal">
                                    <div
                                      className="close-menu text-end cursor"
                                      onClick={() => {
                                        handleBuyClose();
                                      }}
                                    >
                                      <CloseIcon />
                                    </div>
                                    <Typography
                                      id="modal-modal-title"
                                      variant="h6"
                                      component="h2"
                                    >
                                      P2P Terms
                                    </Typography>
                                    <Typography
                                      id="modal-modal-description"
                                      sx={{ mt: 2 }}
                                    >
                                      Make sure to scroll through and read the
                                      Kaavi P2P Additional Verification
                                      Requirements & Guidelines before agreeing.
                                    </Typography>
                                    <p>If you are a P2P taker:</p>
                                    <List className="terms-lines">
                                      <ListItem disablePadding>
                                        You acknowledge and agree that Kaavi is
                                        not a party singly or together with your
                                        counterparty to the P2P transaction. You
                                        also acknowledge and agree that you are
                                        sharing your personal information for
                                        identity verification purposes with your
                                        counterparty and not with Kaavi.
                                      </ListItem>
                                      <ListItem disablePadding>
                                        Your conduct of a P2P transaction with a
                                        counterparty will be deemed your
                                        agreement to provide required personal
                                        information for identity verification
                                        purposes to that counterparty in
                                        accordance with the applicable laws and
                                        regulations. Such information may be
                                        used to verify your identity, identify
                                        traces of money laundering, terrorist
                                        financing, fraud, and other financial
                                        crimes or for other lawful purposes.
                                      </ListItem>
                                      <ListItem disablePadding>
                                        In addition to providing such
                                        information, you agree to allow your
                                        counterparty to keep a record of that
                                        information in compliance with the
                                        applicable laws and regulations. You
                                        also authorize the counterparty to
                                        conduct necessary investigations
                                        directly or through a third party to
                                        verify your identity or protect you
                                        and/or the counterparty from financial
                                        crimes, such as fraud or other financial
                                        crimes, and to take necessary actions
                                        based on the results of such
                                        investigations, including but not
                                        limited to, sharing of your data and/or
                                        rejecting to conclude a P2P transaction
                                        with you.
                                      </ListItem>
                                      <ListItem disablePadding>
                                        Disclaimer: Kaavi does not have any kind
                                        of involvement in your P2P transaction
                                        with your counterparty or in your
                                        collecting or sharing of information for
                                        identity verification purposes
                                        whatsoever. Thus, Kaavi shall not be
                                        held responsible with regards to how
                                        your personal data is used by the
                                        counterparty.
                                      </ListItem>
                                    </List>
                                    <div className="checkbox display-1">
                                      <Checkbox />
                                      <p>I have read and agree to the terms.</p>
                                    </div>
                                    <div className="cfrm-btn text-center">
                                      <Button
                                        onClick={() => {
                                          navigate("/orderdetails");
                                        }}
                                      >
                                        Confirm
                                      </Button>
                                    </div>
                                  </Box>
                                </Modal>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                    </Fade>
                  </Modal>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open2}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                      backdrop: {
                        timeout: 1000,
                      },
                    }}
                  >
                    <Fade in={open2}>
                      <Box sx={style}>
                        {/* <div className="close-icon cursor">
                <HighlightOffIcon onClick={handleClose2} />
              </div> */}
                        <div
                          className="close-menu text-end cursor"
                          onClick={() => {
                            handleClose2();
                          }}
                        >
                          <CloseIcon />
                        </div>
                        <Grid container>
                          <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                            <div className="display-1 gap">
                              <div className="pop-avatar">
                                <Avatar
                                  alt="Remy Sharp"
                                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQE4gyGOCj-ir1zYqWIgZUBJWSBZsXJtevdpjJlWpzVAg&s"
                                  sx={{ width: 100, height: 100 }}
                                />
                              </div>
                              <div className="table-prfile-text pop">
                                <div className="usr">Sankar</div>
                                <div className="order">
                                  13 Orders | 69.23% completion
                                </div>
                              </div>
                            </div>
                            <div className="display-1 time-details">
                              <div>
                                15 Minutes
                                <div className="order">payment Time Limit</div>
                              </div>
                              <div>
                                5.81 Minutes
                                <div className="order">Avg Release Time</div>
                              </div>
                              <div>
                                8.97584 USDT
                                <div className="order">Available</div>
                              </div>
                            </div>
                            <div className="display-2 gap aval2">
                              <Chip
                                icon={<AccountBalanceIcon />}
                                label="Bank"
                              />
                              <Chip
                                icon={<AccountBalanceWalletIcon />}
                                label="UPI"
                              />
                            </div>
                            <div className="pop-desc-main">
                              <div className="pop-desc">
                                Advertiser's Terms{" "}
                                <span className="red">
                                  (Please read carefully)
                                </span>
                              </div>
                              <div className="pop-desc">
                                First Read condition.
                              </div>
                              <div className="pop-desc">
                                All payment options are available.
                              </div>
                            </div>
                          </Grid>
                          <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                            <div className="pop-right-main">
                              <div className="price">
                                Price: <span className="green">98 INR</span>
                              </div>
                              <div className="will-pay">
                                <div className="will-pay-text">I Will Pay</div>
                                <div className="pop-right-input">
                                  <FormControl
                                    sx={{ m: 1, width: "100%" }}
                                    variant="outlined"
                                  >
                                    <OutlinedInput
                                      id="outlined-adornment-weight"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          INR
                                        </InputAdornment>
                                      }
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        "aria-label": "weight",
                                      }}
                                      placeholder="0.00"
                                    />
                                  </FormControl>
                                </div>
                              </div>
                              <div className="will-pay">
                                <div className="will-pay-text">
                                  I Will recieve
                                </div>
                                <div className="pop-right-input">
                                  <FormControl
                                    sx={{ m: 1, width: "100%" }}
                                    variant="outlined"
                                  >
                                    <OutlinedInput
                                      id="outlined-adornment-weight"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          USDT
                                        </InputAdornment>
                                      }
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        "aria-label": "weight",
                                      }}
                                      placeholder="1-5"
                                    />
                                  </FormControl>
                                </div>
                              </div>
                              <div className="display-3 gap">
                                <Button
                                  onClick={handleClose2}
                                  className="cancel-btn"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  variant="contained"
                                  className="bg-red"
                                  onClick={handleSellOpen}
                                >
                                  Sell
                                </Button>
                                <Modal
                                  open={Sell}
                                  onClose={handleSellClose}
                                  aria-labelledby="modal-modal-title"
                                  aria-describedby="modal-modal-description"
                                >
                                  <Box className="id-popup terms-modal">
                                    <div
                                      className="close-menu text-end cursor"
                                      onClick={() => {
                                        handleSellClose();
                                      }}
                                    >
                                      <CloseIcon />
                                    </div>
                                    <Typography
                                      id="modal-modal-title"
                                      variant="h6"
                                      component="h2"
                                    >
                                      P2P Terms
                                    </Typography>
                                    <Typography
                                      id="modal-modal-description"
                                      sx={{ mt: 2 }}
                                    >
                                      Make sure to scroll through and read the
                                      Kaavi P2P Additional Verification
                                      Requirements & Guidelines before agreeing.
                                    </Typography>
                                    <p>If you are a P2P taker:</p>
                                    <List className="terms-lines">
                                      <ListItem disablePadding>
                                        You acknowledge and agree that Kaavi is
                                        not a party singly or together with your
                                        counterparty to the P2P transaction. You
                                        also acknowledge and agree that you are
                                        sharing your personal information for
                                        identity verification purposes with your
                                        counterparty and not with Kaavi.
                                      </ListItem>
                                      <ListItem disablePadding>
                                        Your conduct of a P2P transaction with a
                                        counterparty will be deemed your
                                        agreement to provide required personal
                                        information for identity verification
                                        purposes to that counterparty in
                                        accordance with the applicable laws and
                                        regulations. Such information may be
                                        used to verify your identity, identify
                                        traces of money laundering, terrorist
                                        financing, fraud, and other financial
                                        crimes or for other lawful purposes.
                                      </ListItem>
                                      <ListItem disablePadding>
                                        In addition to providing such
                                        information, you agree to allow your
                                        counterparty to keep a record of that
                                        information in compliance with the
                                        applicable laws and regulations. You
                                        also authorize the counterparty to
                                        conduct necessary investigations
                                        directly or through a third party to
                                        verify your identity or protect you
                                        and/or the counterparty from financial
                                        crimes, such as fraud or other financial
                                        crimes, and to take necessary actions
                                        based on the results of such
                                        investigations, including but not
                                        limited to, sharing of your data and/or
                                        rejecting to conclude a P2P transaction
                                        with you.
                                      </ListItem>
                                      <ListItem disablePadding>
                                        Disclaimer: Kaavi does not have any kind
                                        of involvement in your P2P transaction
                                        with your counterparty or in your
                                        collecting or sharing of information for
                                        identity verification purposes
                                        whatsoever. Thus, Kaavi shall not be
                                        held responsible with regards to how
                                        your personal data is used by the
                                        counterparty.
                                      </ListItem>
                                    </List>
                                    <div className="checkbox display-1">
                                      <Checkbox />
                                      <p>I have read and agree to the terms.</p>
                                    </div>
                                    <div className="cfrm-btn text-center">
                                      <Button
                                        onClick={() => {
                                          navigate("/orderdetails");
                                        }}
                                      >
                                        Confirm
                                      </Button>
                                    </div>
                                  </Box>
                                </Modal>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                    </Fade>
                  </Modal>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  <div className="nodata">
                    <img src="/static/images/no-data.png" alt="No data" />
                    No Record Found
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}

function P2PSellTable() {
  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  return (
    <div className="buy-sell-main-tab2 ">
      <TableContainer>
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left">Advertiser </TableCell>
              <TableCell align="center">Price</TableCell>
              <TableCell align="center">Available|Limits</TableCell>
              <TableCell align="center">Payment Methods</TableCell>
              <TableCell align="center">Transation Fees</TableCell>
            </TableRow>
          </TableHead>

          {buyp2p.length > 0 ? (
            <TableBody>
              {buyp2p.map((row, ind) => {
                return (
                  <>
                    <TableRow>
                      <TableCell align="left">
                        <div className="display-1 gap">
                          <div>
                            <Avatar
                              alt="Remy Sharp"
                              src="/static/images/avatar/1.jpg"
                            />
                          </div>
                          <div className="table-prfile-text">
                            <div className="usr">{row.name}</div>
                            <div className="order">{row.order}</div>
                            <div
                              className={
                                row.live === "online"
                                  ? "green order"
                                  : "red order"
                              }
                            >
                              {row.live}
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div className="p2p-table-price">{row.amt}</div>
                      </TableCell>
                      <TableCell align="center">
                        <div
                          className="display-2 aval
                                      "
                        >
                          Available:
                          <div className="p2p-table-price">98.58$</div>
                        </div>
                        <div
                          className="display-2 aval
                                      "
                        >
                          Limits:
                          <div className="p2p-table-price">98.58$</div>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div className="display-2 gap aval">
                          <Chip icon={<AccountBalanceIcon />} label="Bank" />
                          <Chip
                            icon={<AccountBalanceWalletIcon />}
                            label="UPI"
                          />
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div className="buyusdt">
                          <Button className="bg-red" onClick={handleOpen2}>
                            Sell USDT
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  <div className="nodata">
                    <img src={nodata} alt="nodata" />
                    No Record Found
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}

function P2P() {
  const [value, setValue] = useState(0);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const [Buy, setBuy] = useState(false);
  const handleBuyOpen = () => setBuy(true);
  const handleBuyClose = () => setBuy(false);

  const [Sell, setSell] = useState(false);
  const handleSellOpen = () => setSell(true);
  const handleSellClose = () => setSell(false);
  //   const handleBuyOpen = () => {
  //     setBuy(true);
  //     setOpen(false);
  //   };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [age, setAge] = useState(10);

  const handleChange2 = (event) => {
    setAge(event.target.value);
  };
  const [payment, setPayment] = useState(10);

  const handleChangePayment = (event) => {
    setPayment(event.target.value);
  };

  const [selectedPrimaryTab, setSelectedPrimaryTab] = React.useState(0);
  const [selectedSubtab, setSelectedSubtab] = React.useState(
    Array(primaryTabData.length).fill(0)
  );

  const handlePrimaryChange = (event, newValue) => {
    setSelectedPrimaryTab(newValue);
  };

  const handleSubtabChange = (index, newValue) => {
    const newSelectedSubtab = [...selectedSubtab];
    newSelectedSubtab[index] = newValue;
    setSelectedSubtab(newSelectedSubtab);
  };

  const navigate = useNavigate();

  return (
    <div className="P2P">
      <Grid container>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header />
        </Grid>
      </Grid>
      <div className="p2p-full-cnt">
        <Grid container>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="buy-sell-main-tab">
              <div className="buy-sell-tab-cnt">
                <Box>
                  {/* <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Buy"
                  className={value === 0 && "bg-green"}
                  {...a11yProps(0)}
                />
                <Tab
                  label="Sell"
                  className={value === 1 && "bg-red"}
                  {...a11yProps(1)}
                />
              </Tabs> */}

                  <div className="buy-sell-text display-2">
                    <Tabs
                      value={selectedPrimaryTab}
                      onChange={handlePrimaryChange}
                    >
                      {primaryTabData.map((tab, index) => (
                        <Tab key={index} label={tab.label} />
                      ))}
                    </Tabs>
                    
                    <div className="display-2 gap main-payment-right">
                              <div
                                className="display-2 gap main-tab-right"
                                onClick={() => {
                                  navigate("/paymentMethod");
                                }}
                              >
                                <PaymentIcon /> Payment Method
                              </div>
                              <div
                                className="display-2 gap main-tab-right"
                                onClick={() => {
                                  navigate("/postnewad");
                                }}
                              >
                                <AddBoxIcon /> Post New Ad
                              </div>
                              <div
                                className="display-2 gap main-tab-right"
                                onClick={() => {
                                  navigate("/myads");
                                }}
                              >
                                <FontDownloadIcon /> My Ads
                              </div>
                            </div>

                  </div>

                  {primaryTabData.map((tab, index) => (
                    <Box
                      key={index}
                      role="tabpanel"
                      hidden={selectedPrimaryTab !== index}
                      id={`primary-tabpanel-${index}`}
                      aria-labelledby={`primary-tab-${index}`}
                    >
                      {selectedPrimaryTab === index && (
                        <Box sx={{ p: 3 }}>
                          <div className="buy-sell-text display-1">
                            <Tabs
                              className="usdt-btc"
                              value={selectedSubtab[index]}
                              onChange={(e, newValue) =>
                                handleSubtabChange(index, newValue)
                              }
                            >
                              {tab.content.map((subtab, subIndex) => (
                                <Tab key={subIndex} label={subtab.label} />
                              ))}
                            </Tabs>
                            <div className="display-2 table-top">
                              <div className="display-2 select-before buy-sell-main-tab2 ">
                                <FormControl
                                  sx={{ m: 1, minWidth: 120 }}
                                  size="small"
                                >
                                  {/* <InputLabel id="demo-select-small-label">All Coin</InputLabel> */}
                                  <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={age}
                                    // label="All Coin"
                                    onChange={handleChange2}
                                    className="p2p-select"
                                  >
                                    <MenuItem value={10}>All Coin</MenuItem>
                                    <MenuItem value={20}>INR</MenuItem>
                                    <MenuItem value={30}>USD</MenuItem>
                                    <MenuItem value={40}>Euro</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl
                                  sx={{ m: 1, minWidth: 120 }}
                                  size="small"
                                >
                                  {/* <InputLabel id="demo-select-small-label">All Coin</InputLabel> */}
                                  <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={payment}
                                    // label="All Coin"
                                    onChange={handleChangePayment}
                                    className="p2p-select"
                                  >
                                    <MenuItem value={10} className="p2p-select">
                                      All Payments
                                    </MenuItem>
                                    <MenuItem value={20}>Net Banking</MenuItem>
                                    <MenuItem value={30}>Gpay</MenuItem>
                                    <MenuItem value={40}>UPI</MenuItem>
                                  </Select>
                                </FormControl>
                                <div>
                                  <Button variant="contained">
                                    <FilterAltIcon /> Search
                                  </Button>
                                </div>
                              </div>
                              <div className="display-2 gap cursor">
                                <RefreshIcon /> Refresh
                              </div>
                            </div>

                         
                          </div>
                          {tab.content.map((subtab, subIndex) => (
                            <Box
                              key={subIndex}
                              role="tabpanel"
                              hidden={selectedSubtab[index] !== subIndex}
                              id={`subtab-panel-${index}-${subIndex}`}
                              aria-labelledby={`subtab-${index}-${subIndex}`}
                            >
                              {selectedSubtab[index] === subIndex && (
                                <Box>{subtab.content}</Box>
                              )}
                            </Box>
                          ))}
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              </div>
            </div>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="all-coin-box"
          >
            {/* <CustomTabPanel value={value} index={0}>
              <div className="buy-sell-main-tab2 ">
                <div className="display-2 table-top">
                  <div className="display-2 select-before">
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={age}
                        // label="All Coin"
                        onChange={handleChange2}
                        className="p2p-select"
                      >
                        <MenuItem value={10}>All Coin</MenuItem>
                        <MenuItem value={20}>INR</MenuItem>
                        <MenuItem value={30}>USD</MenuItem>
                        <MenuItem value={40}>Euro</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={payment}
                        // label="All Coin"
                        onChange={handleChangePayment}
                        className="p2p-select"
                      >
                        <MenuItem value={10} className="p2p-select">
                          All Payments
                        </MenuItem>
                        <MenuItem value={20}>Net Banking</MenuItem>
                        <MenuItem value={30}>Gpay</MenuItem>
                        <MenuItem value={40}>UPI</MenuItem>
                      </Select>
                    </FormControl>
                    <div>
                      <Button variant="contained">
                        <FilterAltIcon /> Search
                      </Button>
                    </div>
                  </div>
                  <div className="display-2 gap cursor">
                    <RefreshIcon /> Refresh
                  </div>
                </div>
                <div>
                  <TableContainer>
                    <Table aria-label="simple table" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Advertiser </TableCell>
                          <TableCell align="center">Price</TableCell>
                          <TableCell align="center">Available|Limits</TableCell>
                          <TableCell align="center">Payment Methods</TableCell>
                          <TableCell align="center">Transation Fees</TableCell>
                        </TableRow>
                      </TableHead>

                      {buyp2p.length > 0 ? (
                        <TableBody>
                          {buyp2p.map((row, ind) => {
                            return (
                              <>
                                <TableRow>
                                  <TableCell align="left">
                                    <div className="display-1 gap">
                                      <div>
                                        <Avatar
                                          alt="Remy Sharp"
                                          src="/static/images/avatar/1.jpg"
                                        />
                                      </div>
                                      <div className="table-prfile-text">
                                        <div className="usr">{row.name}</div>
                                        <div className="order">{row.order}</div>
                                        <div
                                          className={
                                            row.live === "online"
                                              ? "green order"
                                              : "red order"
                                          }
                                        >
                                          {row.live}
                                        </div>
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell align="center">
                                    <div className="p2p-table-price">
                                      {row.amt}
                                    </div>
                                  </TableCell>
                                  <TableCell align="center">
                                    <div
                                      className="display-2 aval
                                                    "
                                    >
                                      Available:
                                      <div className="p2p-table-price">
                                        98.58$
                                      </div>
                                    </div>
                                    <div
                                      className="display-2 aval
                                                    "
                                    >
                                      Limits:
                                      <div className="p2p-table-price">
                                        98.58$
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell align="center">
                                    <div className="display-2 gap aval">
                                      <Chip
                                        icon={<AccountBalanceIcon />}
                                        label="Bank"
                                      />
                                      <Chip
                                        icon={<AccountBalanceWalletIcon />}
                                        label="UPI"
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell align="center">
                                    <div className="buyusdt">
                                      <Button
                                        className="bg-green"
                                        onClick={handleOpen}
                                      >
                                        Buy USDT
                                      </Button>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      ) : (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={5}>
                              <div className="nodata">
                                <img src={nodata} alt="nodata" />
                                No Record Found
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </CustomTabPanel> */}
            {/* <CustomTabPanel value={value} index={1}>
              <div className="buy-sell-main-tab2 ">
                <div className="display-2 table-top">
                  <div className="display-2 select-before">
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={age}
                        // label="All Coin"
                        onChange={handleChange2}
                        className="p2p-select"
                      >
                        <MenuItem value={10}>All Coin</MenuItem>
                        <MenuItem value={20}>INR</MenuItem>
                        <MenuItem value={30}>USD</MenuItem>
                        <MenuItem value={40}>Euro</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={payment}
                        // label="All Coin"
                        onChange={handleChangePayment}
                        className="p2p-select"
                      >
                        <MenuItem value={10}>All Payments</MenuItem>
                        <MenuItem value={20}>Net Banking</MenuItem>
                        <MenuItem value={30}>Gpay</MenuItem>
                        <MenuItem value={40}>UPI</MenuItem>
                      </Select>
                    </FormControl>
                    <div>
                      <Button variant="contained">
                        <FilterAltIcon /> Search
                      </Button>
                    </div>
                  </div>
                  <div className="display-2 gap cursor">
                    <RefreshIcon /> Refresh
                  </div>
                </div>
                <div>
                  <TableContainer>
                    <Table aria-label="simple table" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Advertiser </TableCell>
                          <TableCell align="center">Price</TableCell>
                          <TableCell align="center">Available|Limits</TableCell>
                          <TableCell align="center">Payment Methods</TableCell>
                          <TableCell align="center">Transation Fees</TableCell>
                        </TableRow>
                      </TableHead>

                      {buyp2p.length > 0 ? (
                        <TableBody>
                          {buyp2p.map((row, ind) => {
                            return (
                              <>
                                <TableRow>
                                  <TableCell align="left">
                                    <div className="display-1 gap">
                                      <div>
                                        <Avatar
                                          alt="Remy Sharp"
                                          src="/static/images/avatar/1.jpg"
                                        />
                                      </div>
                                      <div className="table-prfile-text">
                                        <div className="usr">{row.name}</div>
                                        <div className="order">{row.order}</div>
                                        <div
                                          className={
                                            row.live === "online"
                                              ? "green order"
                                              : "red order"
                                          }
                                        >
                                          {row.live}
                                        </div>
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell align="center">
                                    <div className="p2p-table-price">
                                      {row.amt}
                                    </div>
                                  </TableCell>
                                  <TableCell align="center">
                                    <div
                                      className="display-2 aval
                                                    "
                                    >
                                      Available:
                                      <div className="p2p-table-price">
                                        98.58$
                                      </div>
                                    </div>
                                    <div
                                      className="display-2 aval
                                                    "
                                    >
                                      Limits:
                                      <div className="p2p-table-price">
                                        98.58$
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell align="center">
                                    <div className="display-2 gap aval">
                                      <Chip
                                        icon={<AccountBalanceIcon />}
                                        label="Bank"
                                      />
                                      <Chip
                                        icon={<AccountBalanceWalletIcon />}
                                        label="UPI"
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell align="center">
                                    <div className="buyusdt">
                                      <Button
                                        className="bg-red"
                                        onClick={handleOpen2}
                                      >
                                        Sell USDT
                                      </Button>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      ) : (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={5}>
                              <div className="nodata">
                                <img src={nodata} alt="nodata" />
                                No Record Found
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </CustomTabPanel> */}
          </Grid>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 1000,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <div
                  className="close-menu text-end cursor"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <CloseIcon />
                </div>
                <Grid container>
                  <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                    <div className="display-1 gap">
                      <div className="pop-avatar">
                        <Avatar
                          alt="Remy Sharp"
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQE4gyGOCj-ir1zYqWIgZUBJWSBZsXJtevdpjJlWpzVAg&s"
                          sx={{ width: 100, height: 100 }}
                        />
                      </div>
                      <div className="table-prfile-text pop">
                        <div className="usr">Sankar</div>
                        <div className="order">
                          13 Orders | 69.23% completion
                        </div>
                      </div>
                    </div>
                    <div className="display-1 time-details">
                      <div>
                        15 Minutes
                        <div className="order">payment Time Limit</div>
                      </div>
                      <div>
                        5.81 Minutes
                        <div className="order">Avg Release Time</div>
                      </div>
                      <div>
                        8.97584 USDT
                        <div className="order">Available</div>
                      </div>
                    </div>
                    <div className="display-2 gap aval2">
                      <Chip icon={<AccountBalanceIcon />} label="Bank" />
                      <Chip icon={<AccountBalanceWalletIcon />} label="UPI" />
                    </div>
                    <div className="pop-desc-main">
                      <div className="pop-desc">
                        Advertiser's Terms{" "}
                        <span className="red">(Please read carefully)</span>
                      </div>
                      <div className="pop-desc">First Read condition.</div>
                      <div className="pop-desc">
                        All payment options are available.
                      </div>
                    </div>
                  </Grid>
                  <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                    <div className="pop-right-main">
                      <div className="price">
                        Price: <span className="green">98 INR</span>
                      </div>
                      <div className="will-pay">
                        <div className="will-pay-text">I Will Pay</div>
                        <div className="pop-right-input">
                          <FormControl
                            sx={{ m: 1, width: "100%" }}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              endAdornment={
                                <InputAdornment position="end">
                                  INR
                                </InputAdornment>
                              }
                              aria-describedby="outlined-weight-helper-text"
                              inputProps={{
                                "aria-label": "weight",
                              }}
                              placeholder="0.00"
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className="will-pay">
                        <div className="will-pay-text">I Will recieve</div>
                        <div className="pop-right-input">
                          <FormControl
                            sx={{ m: 1, width: "100%" }}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              endAdornment={
                                <InputAdornment position="end">
                                  USDT
                                </InputAdornment>
                              }
                              aria-describedby="outlined-weight-helper-text"
                              inputProps={{
                                "aria-label": "weight",
                              }}
                              placeholder="1-5"
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className="display-3 gap">
                        <Button onClick={handleClose} className="cancel-btn">
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          className="bg-green"
                          onClick={handleBuyOpen}
                        >
                          Buy
                        </Button>
                        <Modal
                          open={Buy}
                          onClose={handleBuyClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box className="id-popup terms-modal">
                            <div
                              className="close-menu text-end cursor"
                              onClick={() => {
                                handleBuyClose();
                              }}
                            >
                              <CloseIcon />
                            </div>
                            <Typography
                              id="modal-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              P2P Terms
                            </Typography>
                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 2 }}
                            >
                              Make sure to scroll through and read the Kaavi P2P
                              Additional Verification Requirements & Guidelines
                              before agreeing.
                            </Typography>
                            <p>If you are a P2P taker:</p>
                            <List className="terms-lines">
                              <ListItem disablePadding>
                                You acknowledge and agree that Kaavi is not a
                                party singly or together with your counterparty
                                to the P2P transaction. You also acknowledge and
                                agree that you are sharing your personal
                                information for identity verification purposes
                                with your counterparty and not with Kaavi.
                              </ListItem>
                              <ListItem disablePadding>
                                Your conduct of a P2P transaction with a
                                counterparty will be deemed your agreement to
                                provide required personal information for
                                identity verification purposes to that
                                counterparty in accordance with the applicable
                                laws and regulations. Such information may be
                                used to verify your identity, identify traces of
                                money laundering, terrorist financing, fraud,
                                and other financial crimes or for other lawful
                                purposes.
                              </ListItem>
                              <ListItem disablePadding>
                                In addition to providing such information, you
                                agree to allow your counterparty to keep a
                                record of that information in compliance with
                                the applicable laws and regulations. You also
                                authorize the counterparty to conduct necessary
                                investigations directly or through a third party
                                to verify your identity or protect you and/or
                                the counterparty from financial crimes, such as
                                fraud or other financial crimes, and to take
                                necessary actions based on the results of such
                                investigations, including but not limited to,
                                sharing of your data and/or rejecting to
                                conclude a P2P transaction with you.
                              </ListItem>
                              <ListItem disablePadding>
                                Disclaimer: Kaavi does not have any kind of
                                involvement in your P2P transaction with your
                                counterparty or in your collecting or sharing of
                                information for identity verification purposes
                                whatsoever. Thus, Kaavi shall not be held
                                responsible with regards to how your personal
                                data is used by the counterparty.
                              </ListItem>
                            </List>
                            <div className="checkbox display-1">
                              <Checkbox />
                              <p>I have read and agree to the terms.</p>
                            </div>
                            <div className="cfrm-btn text-center">
                              <Button
                                onClick={() => {
                                  navigate("/orderdetails");
                                }}
                              >
                                Confirm
                              </Button>
                            </div>
                          </Box>
                        </Modal>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Fade>
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open2}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 1000,
              },
            }}
          >
            <Fade in={open2}>
              <Box sx={style}>
                {/* <div className="close-icon cursor">
                <HighlightOffIcon onClick={handleClose2} />
              </div> */}
                <div
                  className="close-menu text-end cursor"
                  onClick={() => {
                    handleClose2();
                  }}
                >
                  <CloseIcon />
                </div>
                <Grid container>
                  <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                    <div className="display-1 gap">
                      <div className="pop-avatar">
                        <Avatar
                          alt="Remy Sharp"
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQE4gyGOCj-ir1zYqWIgZUBJWSBZsXJtevdpjJlWpzVAg&s"
                          sx={{ width: 100, height: 100 }}
                        />
                      </div>
                      <div className="table-prfile-text pop">
                        <div className="usr">Sankar</div>
                        <div className="order">
                          13 Orders | 69.23% completion
                        </div>
                      </div>
                    </div>
                    <div className="display-1 time-details">
                      <div>
                        15 Minutes
                        <div className="order">payment Time Limit</div>
                      </div>
                      <div>
                        5.81 Minutes
                        <div className="order">Avg Release Time</div>
                      </div>
                      <div>
                        8.97584 USDT
                        <div className="order">Available</div>
                      </div>
                    </div>
                    <div className="display-2 gap aval2">
                      <Chip icon={<AccountBalanceIcon />} label="Bank" />
                      <Chip icon={<AccountBalanceWalletIcon />} label="UPI" />
                    </div>
                    <div className="pop-desc-main">
                      <div className="pop-desc">
                        Advertiser's Terms{" "}
                        <span className="red">(Please read carefully)</span>
                      </div>
                      <div className="pop-desc">First Read condition.</div>
                      <div className="pop-desc">
                        All payment options are available.
                      </div>
                    </div>
                  </Grid>
                  <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                    <div className="pop-right-main">
                      <div className="price">
                        Price: <span className="green">98 INR</span>
                      </div>
                      <div className="will-pay">
                        <div className="will-pay-text">I Will Pay</div>
                        <div className="pop-right-input">
                          <FormControl
                            sx={{ m: 1, width: "100%" }}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              endAdornment={
                                <InputAdornment position="end">
                                  INR
                                </InputAdornment>
                              }
                              aria-describedby="outlined-weight-helper-text"
                              inputProps={{
                                "aria-label": "weight",
                              }}
                              placeholder="0.00"
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className="will-pay">
                        <div className="will-pay-text">I Will recieve</div>
                        <div className="pop-right-input">
                          <FormControl
                            sx={{ m: 1, width: "100%" }}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              endAdornment={
                                <InputAdornment position="end">
                                  USDT
                                </InputAdornment>
                              }
                              aria-describedby="outlined-weight-helper-text"
                              inputProps={{
                                "aria-label": "weight",
                              }}
                              placeholder="1-5"
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className="display-3 gap">
                        <Button onClick={handleClose2} className="cancel-btn">
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          className="bg-red"
                          onClick={handleSellOpen}
                        >
                          Sell
                        </Button>
                        <Modal
                          open={Sell}
                          onClose={handleSellClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box className="id-popup terms-modal">
                            <div
                              className="close-menu text-end cursor"
                              onClick={() => {
                                handleSellClose();
                              }}
                            >
                              <CloseIcon />
                            </div>
                            <Typography
                              id="modal-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              P2P Terms
                            </Typography>
                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 2 }}
                            >
                              Make sure to scroll through and read the Kaavi P2P
                              Additional Verification Requirements & Guidelines
                              before agreeing.
                            </Typography>
                            <p>If you are a P2P taker:</p>
                            <List className="terms-lines">
                              <ListItem disablePadding>
                                You acknowledge and agree that Kaavi is not a
                                party singly or together with your counterparty
                                to the P2P transaction. You also acknowledge and
                                agree that you are sharing your personal
                                information for identity verification purposes
                                with your counterparty and not with Kaavi.
                              </ListItem>
                              <ListItem disablePadding>
                                Your conduct of a P2P transaction with a
                                counterparty will be deemed your agreement to
                                provide required personal information for
                                identity verification purposes to that
                                counterparty in accordance with the applicable
                                laws and regulations. Such information may be
                                used to verify your identity, identify traces of
                                money laundering, terrorist financing, fraud,
                                and other financial crimes or for other lawful
                                purposes.
                              </ListItem>
                              <ListItem disablePadding>
                                In addition to providing such information, you
                                agree to allow your counterparty to keep a
                                record of that information in compliance with
                                the applicable laws and regulations. You also
                                authorize the counterparty to conduct necessary
                                investigations directly or through a third party
                                to verify your identity or protect you and/or
                                the counterparty from financial crimes, such as
                                fraud or other financial crimes, and to take
                                necessary actions based on the results of such
                                investigations, including but not limited to,
                                sharing of your data and/or rejecting to
                                conclude a P2P transaction with you.
                              </ListItem>
                              <ListItem disablePadding>
                                Disclaimer: Kaavi does not have any kind of
                                involvement in your P2P transaction with your
                                counterparty or in your collecting or sharing of
                                information for identity verification purposes
                                whatsoever. Thus, Kaavi shall not be held
                                responsible with regards to how your personal
                                data is used by the counterparty.
                              </ListItem>
                            </List>
                            <div className="checkbox display-1">
                              <Checkbox />
                              <p>I have read and agree to the terms.</p>
                            </div>
                            <div className="cfrm-btn text-center">
                              <Button
                                onClick={() => {
                                  navigate("/orderdetails");
                                }}
                              >
                                Confirm
                              </Button>
                            </div>
                          </Box>
                        </Modal>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Fade>
          </Modal>
        </Grid>
      </div>
    </div>
  );
}

export default P2P;
