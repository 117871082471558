import { Grid } from '@mui/material'
import React, { } from 'react'
import Header from '../Header/Header'
import './Payment.scss'
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import HttpsIcon from '@mui/icons-material/Https';
import TextField from '@mui/material/TextField';

import { Link } from 'react-router-dom';


import darkarrow from '../../Img/dark-Layer.png';
import lightarrow from '../../Img/light-layer.png';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};




const tabs = [
    { name: 'Bank Payment', icon: <PersonIcon /> },
    { name: 'UPI', icon: <HttpsIcon /> },
]

// console.log(tabs, 'tabs');



const UPIAccount = () => {


    return (
        <div className='new-account'>
            <Grid container className='justi-center'>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Header />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <div class="ivon-back">
                    <Link to="/payment-option" className='light-mode-img'><img src={darkarrow}/></Link>
                    
                    <Link to="/payment-option" className="dark-mode-img"><img src={lightarrow}  /> </Link>
                </div>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={10}>
                <div className='account-head'>
               
                <Typography variant="h1" className='cmn-text'>ADD YOUR UPI / VPA ID FOR UPI PAYMENTS</Typography>
                </div>
               

                    <Grid container class="payment-left-main ticket-box">
                        <Grid xs={12} sm={12} md={8} lg={12} xl={12}>
                            <div className='profile-change'>
                                <Typography component='p'>ALIAS</Typography>
                                <TextField id="outlined-basic" label="Alias" variant="outlined" />
                            </div>

                        </Grid>
                        <Grid xs={12} sm={12} md={8} lg={12} xl={12}>
                            <div className='profile-change'>
                                <Typography component='p'>common.UPI / VPA ID</Typography>
                                <TextField id="outlined-basic" label="UPI id" variant="outlined" />
                            </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={8} lg={12} xl={12}>
                            <div className='profile-change choose-file'>
                                <Typography component='p'>QR Code</Typography>
                                <TextField id="outlined-basic" label="Choose File" type='file' variant="outlined" />
                            </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={8} lg={12} xl={12}>
                            <div className='profile-change submit'>
                                <TextField type='submit' variant="outlined" className="action-button sitebtn" />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default UPIAccount;
