import * as React from "react";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "./Login.scss";
import { useMediaQuery, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import lightlogo from "../../Img/light-mode-logo.png";
import usercalls from "../../Axios";
import consts from '../../constant';
import { toast, ToastContainer } from 'react-toastify';

export default function Resend() {


    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up("md"));


    const navigate = useNavigate();
    const [email, setEmail] = useState()
    const [load, setLoad] = useState(false)
    const path = usercalls()


    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     const data = new FormData(event.currentTarget);
    //     console.log({
    //         email: data.get("email"),
    //         password: data.get("password"),
    //     });
    // };

    const handleSubmit = async () => {
        try {
            setLoad(true)
            const url = `${consts.BackendUrl}/users/resendemail`
            const payload = {
                email: email,
            }
            const response = await path.postCallWithoutAuthToken({ url, payload });
            const result = await response.json();
            if (result.success) {
                toast.success(result.message)
                localStorage.setItem('email', email)
                setTimeout(() => {
                    setLoad(false)
                    navigate('/verify')
                }, 2000)
            }
            else {
                setLoad(false)
                toast.error(result.message)
            }
        } catch (error) {
            setLoad(false)
            console.log("🚀 ~ forgotpass ~ error:", error);
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="Login">
                <div className="logo">
                    <Link to="/">
                        {" "}
                        <img src={lightlogo} />
                    </Link>
                </div>
                <Grid
                    container
                    component="main"
                    sx={{ justifyContent: "center" }}
                >
                    <Grid item xs={11} sm={8} md={7} lg={7} xl={6}>
                        <Grid container sx={{ justifyContent: "center" }}>
                            <Grid item xs={12} sm={10} md={9} lg={6} xl={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                        padding: "25px",
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography component="h1" variant="h5" className="text-center c-accout" sx={{ mt: 1, mb: 3 }}>
                                        Resend Verification Email?
                                    </Typography>
                                    <Box
                                        // component="form"
                                        // noValidate
                                        // onSubmit={handleSubmit}
                                        sx={{ mt: 1 }}
                                    >
                                        <div className="email-fill">
                                            <div className="email-name">
                                                Email
                                            </div>
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="email"
                                                // label="Email Address"
                                                name="email"
                                                autoComplete="email"
                                                autoFocus
                                                value={email}
                                                onChange={(e) => { setEmail(e.target.value) }}
                                            />
                                        </div>



                                        <div className="login-btn">
                                            {
                                                load ?
                                                    <Button
                                                        type="submit"
                                                        fullWidth
                                                        sx={{ mt: 3, mb: 2 }}
                                                    >
                                                        Processing...
                                                    </Button> :
                                                    <Button
                                                        type="submit"
                                                        fullWidth
                                                        sx={{ mt: 3, mb: 2 }}
                                                        onClick={() => { handleSubmit() }}
                                                    >
                                                        Resend
                                                    </Button>
                                            }

                                        </div>


                                        <div className="text-center accnt-sign mt-10">
                                            <Link to="/register" variant="body2">
                                                Don't have an account? <span>Sign Up</span>
                                            </Link>
                                        </div>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
