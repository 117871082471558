import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import Header from '../Header/Header';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import { useState } from 'react';

import '../Authentication/Login.scss'
import { useMediaQuery, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}



export default function ProfileVerify() {
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
    };

    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));

    console.log(md, 'md');

    const [send, setSend] = useState(false)
    const [verify, setVerify] = useState(false)
    const navigate = useNavigate()

    return (
        <div className='profileverify'>
            <Grid container className='justi-center'>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Header />
                </Grid>
                <Grid item xs={12} sm={8} md={6} lg={5}  >
                    <Grid container sx={{ justifyContent: 'center', height: '100vh', alignItems: 'center' }}>
                        <Grid item xs={12} sm={12} md={12} lg={10} xl={10} >
                            <Box
                                sx={{
                                    my: 8,
                                    mx: 4,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',

                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    padding: '15px',
                                    borderRadius: '12px'

                                }}
                                className={!md && 'log-bcg'}
                            >
                                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                    <img src='https://firebasestorage.googleapis.com/v0/b/alpharive-tech.appspot.com/o/atom.png?alt=media&token=5361183b-ed76-4b36-a4bf-c15009da8927' alt='logo' style={{ width: '40px', height: '40px' }} />
                                </Avatar>
                                <Typography component="h1" variant="h5">
                                    Reset Password
                                </Typography>

                                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label={!verify ? "Email Address" : null}
                                        name="email"
                                        value={verify ? 'Sankar.alpharive@gmail.com' : null}
                                        autoComplete="email"
                                        autoFocus
                                    />
                                    {verify && <>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type="password"
                                            id="password"
                                            autoComplete="current-password"
                                        />
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="password"
                                            label="Confirm-Password"
                                            type="password"
                                            id="password"
                                            autoComplete="current-password"
                                        />
                                    </>}


                                    {send &&
                                        <Alert variant="filled" severity="success">
                                            Please check you email
                                        </Alert>
                                    }
                                    {verify ?

                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}

                                            onClick={(() => navigate('/success'))}
                                        >
                                            Reset
                                        </Button>

                                        :
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                            onClick={(() => setSend(!send))}
                                        >
                                            Send
                                        </Button>

                                    }

                                    <Grid container className='justi-center'>

                                        <Grid item>
                                            <div className='text-center'>
                                                <Link variant="body2" onClick={(() => { setVerify(!verify); setSend(!send) })} >
                                                    Verify email
                                                </Link>
                                            </div>

                                        </Grid>
                                    </Grid>
                                    <Copyright sx={{ mt: 5 }} />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

        </div>

    );
}
