import { Grid } from "@mui/material";
import React from "react";
import Header from "../Header/Header";
import "./Staking.scss";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import nodata from "../../Img/thankyou.gif";
import Card from "@mui/material/Card";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const tabs = [{ name: "Flexible" }, { name: "Fixed" }];

// console.log(tabs, "tabs");

const Staking = () => {
  // password show or not

  const [showPassword, setShowPassword] = React.useState(false);
  const handleShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="staking">
      <Grid container className="justi-center">
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header />
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={10} className="staking-body">
          <Grid container>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="payment-left-main ticket-box">
                <div className="staking display-1">
                  <div>
                    <Typography variant="h1" className="cmn-text text-primary">
                      Staking
                    </Typography>
                    <Typography component="p" className="cmn-text">
                      Simple & Secure. Search popular coins and start earning
                    </Typography>
                  </div>

                  <div className="stackingbannerright">
                    <div className="stacking-card">
                      <IconButton
                        className="passwordIcon"
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                      <Card className="card">
                        <div className="xchange-rate">
                          <Typography variant="h4">Est. Total Value</Typography>
                          <Typography variant="h5">
                            <span>0</span> USDT
                          </Typography>
                          <div className="value-hide-show">
                            <FormControl>
                              <OutlinedInput
                                id="outlined-adornment-password"
                                value="≈ $ 0.00"
                                type={showPassword ? "text" : "password"}
                                endAdornment={
                                  <InputAdornment position="end"></InputAdornment>
                                }
                                label="Password"
                              />
                            </FormControl>
                          </div>
                          <Link to="https://zurumi.com/stage/public/earning">
                            <h3>Account</h3>
                          </Link>
                        </div>
                        <div className="xchange-rate ryt">
                          <Typography variant="h4">30-Day Profit</Typography>
                          <Typography variant="h5">
                            <span>0</span> USDT
                          </Typography>
                          <div className="value-hide-show">
                            <FormControl>
                              <OutlinedInput
                                id="outlined-adornment-password"
                                value="≈ $ 0.00"
                                type={showPassword ? "text" : "password"}
                                endAdornment={
                                  <InputAdornment position="end"></InputAdornment>
                                }
                                label="Password"
                              />
                            </FormControl>
                          </div>
                          <Link to="https://zurumi.com/stage/public/earning">
                            <h3>History</h3>
                          </Link>
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>

              <div className="payment-left-main ticket-box">
                <div className="display-1">
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    className="vertiacal-tab-payment rules-staking"
                  >
                    {tabs.map((row, ind) => {
                      return (
                        <Tab
                          iconPosition="start"
                          icon={row.icon}
                          label={row.name}
                          {...a11yProps(ind)}
                        />
                      );
                    })}
                  </Tabs>
                </div>

                <TabPanel value={value} index={0}>
                  <div className="tab-1">
                    <div>
                      <ul>
                        <li>Real-Time APR rewards</li>
                        <li>Bonus Tiered APR Rewards</li>
                        <li>Redemption</li>
                      </ul>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div className="tab-1">
                    <ul>
                      <li>Rewards</li>
                      <li>Subscription and redemption</li>
                      <li>Early Redemption</li>
                    </ul>
                  </div>
                </TabPanel>
              </div>

              <div class="card-stacking">
                <div class="contain-width sitecontainer stacking">
                  <div class="eth-card-total">
                    <div class="card border-style-cls">
                      <Typography variant="h4">BTC - Bitcoin</Typography>
                      <Typography variant="h6"> Earning rate : 1%</Typography>
                      <Typography variant="h6"> Duration : 90 days</Typography>

                      <div class="card-btn">
                        <Link to="/subscribe">
                          <span>Subscribe</span>
                        </Link>
                      </div>
                    </div>
                    <div class="card border-style-cls">
                      <Typography variant="h4">ETH - Ethereum</Typography>
                      <Typography variant="h6"> Earning rate : 5%</Typography>
                      <Typography variant="h6"> Duration : 90 days</Typography>

                      <div class="card-btn">
                        <Link to="/subscribe">
                          <span>Subscribe</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="ticket-box"
            >
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className="row-head">
                      <TableCell className="head-bg">Date & Time</TableCell>
                      <TableCell className="head-bg">Title</TableCell>
                      <TableCell className="head-bg">Deposit Coin</TableCell>
                      <TableCell className="head-bg">Amount</TableCell>
                      <TableCell className="head-bg">Staking Reward</TableCell>
                      <TableCell className="head-bg">No. Of Days</TableCell>
                      <TableCell className="head-bg">
                        Total Estimated Reward
                      </TableCell>
                      <TableCell className="head-bg">Last Reward</TableCell>
                      <TableCell className="head-bg">Next Reward</TableCell>
                      <TableCell className="head-bg">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10}>
                        <div className="nodata">
                          <img src={nodata} alt="nodata" />
                          No Record Found
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Staking;
