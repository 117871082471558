import { Grid } from "@mui/material";
import React from "react";
import Header from "../Header/Header";
import "./Market.scss";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Btc from '../../Img/btc.png'
import eth from '../../Img/eth.png'
import tht from '../../Img/theter.png'
import shiba from '../../Img/shiba.svg'
import mana from '../../Img/mana.svg'
import trending_icon from '../../Img/trending_icon.svg'
import right_arw from '../../Img/right_arw.svg'
import zec from '../../Img/coins/zec.png'
import xec from '../../Img/coins/xec.png'
import kcs from '../../Img/coins/kcs.png'
import scr from '../../Img/coins/scr.png'
import cat from '../../Img/coins/cat.png'
import woop from '../../Img/coins/woop.png'
import pip from '../../Img/coins/pip.png'
import insp from '../../Img/coins/xec.png'
import ese from '../../Img/coins/ese.png'
import sol from '../../Img/coins/sol.png'
import down from '../../Img/down.svg'
import up from '../../Img/up.svg'
import top_gainers from '../../Img/top_gainers.png'
import top_losers from '../../Img/top_losers.png'
import searchs from '../../Img/search.svg'
import star from '../../Img/star.svg'
import usercalls from "../../Axios";
import consts from '../../constant';
import Pagination from '@mui/material/Pagination';
import Avatar from '@mui/material/Avatar';
import $ from 'jquery'
import { toast, ToastContainer } from 'react-toastify';
import axios from "axios";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Market = () => {
  const [value, setValue] = React.useState(0);
  const path = usercalls()

  //trending pairs
  const [datatbtc, setDatabtc] = useState({})
  const [databnb, setDatabnb] = useState({})
  const [dataeth, setDataeth] = useState({})

  //top gainers pairs
  const [dataprom, setDataProm] = useState({})
  const [datawing, setDataWing] = useState({})
  const [datagno, setDataGno] = useState({})

  //top volume
  const [datasol, setDataSol] = useState({})

  //pairs 
  const [pairs, setPairs] = useState([])

  const [page, setPage] = useState(1)
  const [perPage] = useState(10);

  //ws
  const [Subws, setSubWs] = useState()

  const handleChange1 = async (event, value) => {
    setPage(value);
    await UnSubScribeStream()
    // await getBinancePrice()
    await subScribeStream()
  };

  //serach pairs
  const [search, setSearch] = useState('')


  const apiCall = {
    "method": "SUBSCRIBE",
    "params": [
      "btcusdt@ticker",
      "bnbusdt@ticker",
      "ethusdt@ticker",
      "promusdt@ticker",
      "wingusdt@ticker",
      "gnousdt@ticker",
      "solusdt@ticker"
    ],
    "id": 1
  }

  const socket = () => {
    const ws = new WebSocket("wss://stream.binance.com:9443/ws");
    ws.onopen = () => {
      ws.send(JSON.stringify(apiCall));
    };

    ws.onmessage = function (event) {
      const json = JSON.parse(event.data);
      // console.log(json, 'json');

      try {
        if (json.s === "BTCUSDT") {
          setDatabtc(json);
        }
        else if (json.s === "BNBUSDT") {
          setDatabnb(json)
        }
        else if (json.s === "ETHUSDT") {
          setDataeth(json)
        }
        else if (json.s === "PROMUSDT") {
          setDataProm(json)
        }
        else if (json.s === "WINGUSDT") {
          setDataWing(json)
        }
        else if (json.s === "GNOUSDT") {
          setDataGno(json)
        }
        else if (json.s === "SOLUSDT") {
          setDataSol(json)
        }

      } catch (err) {
        console.log(err);
      }
    };

    return () => {
      console.log('Cleaning up WebSocket connection');
      ws.close();
    }
  }

  const getPairs = async () => {
    try {
      const url = `${consts.BackendUrl}/getPairs`
      const payload = {
        type: type
      }
      const response = await path.postCallWithoutAuthToken({ url, payload });
      const result = await response.json();
      if (result.success) {

        if (search) {
          let arr = []
          for (let i = 0; i < result?.result.length; i++) {
            if (result?.result[i]?.symbol.includes(search)) {
              arr.push(result?.result[i])
              setPairs(arr)
            }
          }
        }
        else {
          setPairs(result.result)
        }
      }
      else {
        setPairs([])
      }
    }
    catch (error) {
      console.log("🚀 ~ getPairs ~ error:", error);
    }
  }


  const getBinancePrice = async () => {
    try {
      if (pairs?.length > 0) {
        const start = (page - 1) * perPage;
        const end = start + perPage;
        const pairsToSubscribe = pairs?.slice(start, end);
        const symbols = pairsToSubscribe?.map(data => data.symbol);
        const { data } = await axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbols=${JSON.stringify(symbols)}`)
        if (data?.length > 0) {
          for (let i = 0; i < data?.length; i++) {
            const element = data[i];
            console.log(element?.symbol, element?.lastPrice, "pricesss")
            let priceHtml = parseFloat(element?.priceChangePercent).toFixed(2) > 0 ?
              `<span class="green" > +${parseFloat(element?.priceChangePercent).toFixed(2)} %</span> ` :
              `<span class="red" > ${parseFloat(element?.priceChangePercent).toFixed(2)} %</span> `;

            $(`.price-${element?.symbol}`).html(isNaN(element?.lastPrice) ? '0' : Number(element?.lastPrice).toFixed(6));
            $(`.change-${element?.symbol}`).html(priceHtml);
            $(`.high-${element?.symbol}`).html(isNaN(element?.highPrice) ? '0' : Number(element?.highPrice).toFixed(6));
            $(`.low-${element?.symbol}`).html(isNaN(element?.lowPrice) ? '0' : Number(element?.lowPrice).toFixed(6));
            $(`.volume-${element?.symbol}`).html(isNaN(element?.volume) ? '0' : Number(element?.volume).toFixed(2));
          }
        }
      }
    } catch (error) {
      console.log("🚀 ~ getBinancePrice ~ error:", error)
    }
  }
  const UnSubScribeStream = () => {
    // const ws = new WebSocket("wss://stream.binance.com:9443/ws");
    if (Subws) {
      Subws.close()
    }
  };

  const subScribeStream = () => {
    const ws = new WebSocket("wss://stream.binance.com:9443/ws");
    setSubWs(ws)
    const start = (page - 1) * perPage;
    const end = start + perPage;
    const pairsToSubscribe = pairs?.slice(start, end);
    const symbols = pairsToSubscribe?.map(data => `${data.symbol.toLowerCase()}@ticker`);
    ws.onopen = () => {
      // console.log(symbols, 'symbols');
      if (symbols.length > 0) {
        const priceApicall = {
          method: "SUBSCRIBE",
          params: symbols,
          id: 1
        };
        ws.send(JSON.stringify(priceApicall));
      }
    };

    ws.onmessage = (event) => {
      const json = JSON.parse(event.data);
      // console.log(json, 'pricesocket');

      try {
        let priceHtml = parseFloat(json?.P).toFixed(2) > 0 ?
          `<span class="green" > +${parseFloat(json?.P).toFixed(2)} %</span> ` :
          `<span class="red" > ${parseFloat(json?.P).toFixed(2)} %</span> `;

        $(`.price-${json?.s}`).html(isNaN(json?.c) ? '0' : Number(json?.c).toFixed(6));
        // $(`.change - ${ json?.s }`).html(isNaN(json?.P) ? '0' : Number(json?.P).toFixed(2) + "%");
        $(`.change-${json?.s}`).html(priceHtml);
        $(`.high-${json?.s}`).html(isNaN(json?.h) ? '0' : Number(json?.h).toFixed(6));
        $(`.low-${json?.s}`).html(isNaN(json?.l) ? '0' : Number(json?.l).toFixed(6));
        $(`.volume-${json?.s}`).html(isNaN(json?.v) ? '0' : Number(json?.v).toFixed(2));
      } catch (error) {
        console.error('Error updating price:', error);
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };

    ws.onclose = () => {
      console.log('WebSocket Connection Closed');
    };

    return () => {
      console.log('Cleaning up WebSocket connection');
      ws.close();
    };
  };



  useEffect(() => {
    socket()
  }, [])

  const [history, setHistory] = React.useState(0);
  const [type, setType] = useState('all')

  const handleChangeHistory = async (event, newHistory) => {
    setHistory(newHistory);
    setPage(1)
    if (newHistory == 0) {
      setType('all')
    }
    else if (newHistory == 1) {
      setType('spot')
    }
    else if (newHistory == 2) {
      setType('future')
    }
  };

  useEffect(() => {
    getPairs()
  }, [history, search])

  useEffect(() => {
    subScribeStream()
    getBinancePrice()
  }, [pairs, history, page, perPage, search])

  const [all, setall] = React.useState(0);

  const handleChangeall = (event, e) => {
    setall(e);
  };

  const [fav, setfav] = React.useState(0);

  const handleChangefav = (event, e) => {
    setfav(e);
  };



  const cards = [
    {
      img: `${Btc}`,
      coinname: "BTC",
      coinrate: "+7.09%",
      coinvalue: "$ 17.228000",
      coinname: "USDT",
    },
    {
      img: `${eth}`,
      coinname: "ETH",
      coinrate: "+7.09%",
      coinvalue: "$ 17.228000",
      coinname: "USDT",
    },
    {
      img: `${mana}`,
      coinname: "MANA",
      coinrate: "+7.09%",
      coinvalue: "$ 17.228000",
      coinname: "USDT",
    },
    {
      img: `${shiba}`,
      coinname: "SHIB",
      coinrate: "+7.09%",
      coinvalue: "$ 17.228000",
      coinname: "USDT",
    },
  ];

  const cardElements = cards.map((card) => (
    <div className="token-card">

      <div className="token-logo display-2">
        <div className="token-name">
          <div className="token-logo">
            <img src={card.img} alt="coin-name" />
          </div>
          {card.coinname}
        </div>
        <div className="token-rate green-bg">
          <div class="check_box"><div class="chk"><input type="checkbox" /><span class="round"></span></div></div>
        </div>
      </div>
      <div className="token-logo display-2">
        <div className="token-rate">
          <h5> {card.coinvalue}</h5>
          <p className="green" >{card.coinrate}</p>
        </div>
        <div className="token-name">{card.coinname}</div>
      </div>
    </div>
  ));

  // const tableHistory = [
  //   {
  //     img: `${ Btc }`,
  //     coin: "BTC",
  //     coinname: "Bitcoin",
  //     price: "$66,966.49",
  //     change: "-2.06%",
  //     market_cap: "$1.32T",
  //     volume: "$139.84M",
  //   },
  //   {
  //     img: `${ eth }`,
  //     coin: "ETH",
  //     coinname: "Ethereum",
  //     price: "$2,626.25",
  //     change: "-3.06%",
  //     market_cap: "$317.21B",
  //     volume: "$132.41M",
  //   },
  //   {
  //     img: `${ sol }`,
  //     coin: "SOL",
  //     coinname: "Solana",
  //     price: "$164.94",
  //     change: "-1.06%",
  //     market_cap: "$77.91B",
  //     volume: "$31.44M",
  //   },
  //   {
  //     img: `${ Btc }`,
  //     coin: "BTC",
  //     coinname: "Bitcoin",
  //     price: "$66,966.49",
  //     change: "-2.06%",
  //     market_cap: "$1.32T",
  //     volume: "$139.84M",
  //   },
  //   {
  //     img: `${ eth }`,
  //     coin: "ETH",
  //     coinname: "Ethereum",
  //     price: "$2,626.25",
  //     change: "-3.06%",
  //     market_cap: "$317.21B",
  //     volume: "$132.41M",
  //   },
  //   {
  //     img: `${ sol }`,
  //     coin: "SOL",
  //     coinname: "Solana",
  //     price: "$164.94",
  //     change: "-1.06%",
  //     market_cap: "$77.91B",
  //     volume: "$31.44M",
  //   },
  // ];



  const tableElements =

    pairs && [...pairs].slice((page - 1) * perPage, page * perPage)?.map((row, index) => (

      <TableRow className="cursor" key={index} >
        <TableCell align="left">
          <div className="display-1 coin-gap">
            {/* <div className="star-img">
            <img src={star} alt="star" />
          </div> */}
            <div className="coin-img">
              {
                !row?.imageurl ? <Avatar sx={{ width: 25, height: 25 }}>{row?.symbol?.split("")[0]}</Avatar> :
                  <img src={row.imageurl} alt="img" />
              }
            </div>
            <div className="coin-name">
              <p> {row?.symbol}</p>
              {
                history == 0 ?
                  <span> {row?.type}</span> :
                  <></>
              }
            </div>
          </div>


        </TableCell>
        <TableCell align="right" className={`price-${row?.symbol}`}> {row?.price ? row?.price : '0'} </TableCell>
        <TableCell align="right" className={`change-${row?.symbol}`} >{row?.change ? row?.change : '0'}</TableCell>
        <TableCell align="right" className={`high-${row?.symbol}`}>{row?.high ? row?.high : '0'}</TableCell>
        <TableCell align="right" className={`low-${row?.symbol}`}>{row?.low ? row?.low : '0'}</TableCell>
        <TableCell align="right" className={`volume-${row?.symbol}`}>{row?.volume ? row?.volume : '0'}</TableCell>
        <TableCell align="right">
          <div className="actions" >
            {/* <Link to={"#"}>Details</Link> */}
            <Link to={`/trade/${row?.baseCoin}_${row?.quoteCoin}`}>Trade</Link>
          </div>
        </TableCell>
      </TableRow>
    ));

  return (
    <div className="market-full">
      <ToastContainer />
      <Grid container>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header />
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="support-head contain-width">
            <h1>Markets Overview</h1>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <div className="market_overview contain-width">
                <div className="box">
                  <div className="abv_flx">
                    <div className="flx">
                      <img src={trending_icon} alt="img" />
                      <h5>Trending</h5>
                    </div>
                    {/* <Link to="/trade">More <img src={right_arw} alt="img" /></Link> */}
                  </div>
                  <ul>
                    <li>
                      <div><span className="num" >1</span><img src={zec} alt="img" className="coin" /><span>BNB</span> </div>
                      <div>
                        <span>${databnb?.a ? parseFloat(databnb?.a).toFixed(2) : '0'}</span>
                      </div>
                      <div>
                        {databnb.P > 0 ?
                          <span className="green">+{databnb?.P ? parseFloat(databnb?.P).toFixed(2) : '0'}% <img src={up} alt="img" /></span>
                          :
                          <span className="red">{databnb?.P ? parseFloat(databnb?.P).toFixed(2) : '0'}%  <img src={down} alt="img" /></span>}
                      </div>
                    </li>
                    <li>
                      <div><span className="num" >2</span><img src={xec} alt="img" className="coin" /><span>BTC</span> </div>
                      <div>
                        <span>${datatbtc?.a ? parseFloat(datatbtc?.a).toFixed(2) : '0'}</span>
                      </div>
                      <div>
                        {datatbtc.P > 0 ?
                          <span className="green">+{datatbtc?.P ? parseFloat(datatbtc?.P).toFixed(2) : '0'}% <img src={up} alt="img" /></span>
                          :
                          <span className="red">{datatbtc?.P ? parseFloat(datatbtc?.P).toFixed(2) : '0'}% <img src={down} alt="img" /></span>}
                      </div>
                    </li>
                    <li>
                      <div><span className="num" >3</span><img src={kcs} alt="img" className="coin" /><span>ETH</span> </div>
                      <div>
                        <span>${dataeth?.a ? parseFloat(dataeth?.a).toFixed(2) : '0'}</span>
                      </div>
                      <div>
                        {dataeth.P > 0 ?
                          <span className="green">+{dataeth?.P ? parseFloat(dataeth?.P).toFixed(2) : '0'}% <img src={up} alt="img" /></span>
                          :
                          <span className="red">{dataeth?.P ? parseFloat(dataeth?.P).toFixed(2) : '0'}% <img src={down} alt="img" /></span>}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <div className="market_overview contain-width">
                <div className="box">
                  <div className="abv_flx">
                    <div className="flx">
                      <img src={top_gainers} alt="img" />
                      <h5>Top Gainers</h5>
                    </div>
                    {/* <Link to="/trade">More <img src={right_arw} alt="img" /></Link> */}
                  </div>
                  <ul>
                    <li>
                      <div><span className="num" >1</span><img src={scr} alt="img" className="coin" /><span>PROM</span> </div>
                      <div>
                        <span>${dataprom?.a ? parseFloat(dataprom?.a).toFixed(2) : '0'}</span>
                      </div>
                      <div>
                        {dataprom.P > 0 ?
                          <span className="green">+{dataprom?.P ? parseFloat(dataprom?.P).toFixed(2) : '0'}% <img src={up} alt="img" /></span>
                          :
                          <span className="red">{dataprom?.P ? parseFloat(dataprom?.P).toFixed(2) : '0'}%  <img src={down} alt="img" /></span>}
                      </div>
                    </li>
                    <li>
                      <div><span className="num" >2</span><img src={cat} alt="img" className="coin" /><span>WING</span> </div>
                      <div>
                        <span>${datawing?.a ? parseFloat(datawing?.a).toFixed(2) : '0'}</span>
                      </div>
                      <div>
                        {datawing.P > 0 ?
                          <span className="green">+{datawing?.P ? parseFloat(datawing?.P).toFixed(2) : '0'}% <img src={up} alt="img" /></span>
                          :
                          <span className="red">{datawing?.P ? parseFloat(datawing?.P).toFixed(2) : '0'}%  <img src={down} alt="img" /></span>}
                      </div>
                    </li>
                    <li>
                      <div><span className="num" >3</span><img src={woop} alt="img" className="coin" /><span>GNO</span> </div>
                      <div>
                        <span>${datagno?.a ? parseFloat(datagno?.a).toFixed(2) : '0'}</span>
                      </div>
                      <div>
                        {datagno.P > 0 ?
                          <span className="green">+{datagno?.P ? parseFloat(datagno?.P).toFixed(2) : '0'}% <img src={up} alt="img" /></span>
                          :
                          <span className="red">{datagno?.P ? parseFloat(datagno?.P).toFixed(2) : '0'}%  <img src={down} alt="img" /></span>}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <div className="market_overview contain-width">
                <div className="box">
                  <div className="abv_flx">
                    <div className="flx">
                      <img src={top_losers} alt="img" />
                      <h5>Top Volume Coin</h5>
                    </div>
                    {/* <Link to="/trade">More <img src={right_arw} alt="img" /></Link> */}
                  </div>
                  <ul>
                    <li>
                      <div><span className="num" >1</span><img src={pip} alt="img" className="coin" /><span>BTC</span> </div>
                      <div>
                        <span>${datatbtc?.a ? parseFloat(datatbtc?.a).toFixed(2) : '0'}</span>
                      </div>
                      <div>
                        {datatbtc.P > 0 ?
                          <span className="green">+{datatbtc?.P ? parseFloat(datatbtc?.P).toFixed(2) : '0'}% <img src={up} alt="img" /></span>
                          :
                          <span className="red">{datatbtc?.P ? parseFloat(datatbtc?.P).toFixed(2) : '0'}% <img src={down} alt="img" /></span>}
                      </div>
                    </li>
                    <li>
                      <div><span className="num" >2</span><img src={insp} alt="img" className="coin" /><span>ETH</span> </div>
                      <div>
                        <span>${dataeth?.a ? parseFloat(dataeth?.a).toFixed(2) : '0'}</span>
                      </div>
                      <div>
                        {dataeth.P > 0 ?
                          <span className="green">+{dataeth?.P ? parseFloat(dataeth?.P).toFixed(2) : '0'}% <img src={up} alt="img" /></span>
                          :
                          <span className="red">{dataeth?.P ? parseFloat(dataeth?.P).toFixed(2) : '0'}% <img src={down} alt="img" /></span>}
                      </div>
                    </li>
                    <li>
                      <div><span className="num" >3</span><img src={ese} alt="img" className="coin" /><span>SOL</span> </div>
                      <div>
                        <span>${datasol?.a ? parseFloat(datasol?.a).toFixed(2) : '0'}</span>
                      </div>
                      <div>
                        {datasol.P > 0 ?
                          <span className="green">+{datasol?.P ? parseFloat(datasol?.P).toFixed(2) : '0'}% <img src={up} alt="img" /></span>
                          :
                          <span className="red">{datasol?.P ? parseFloat(datasol?.P).toFixed(2) : '0'}% <img src={down} alt="img" /></span>}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="ticket-box contain-width">
                <div className="market-current-history">
                  <div className="display-2 tab_bdr" >
                    <Tabs
                      value={history}
                      onChange={handleChangeHistory}
                      aria-label="basic tabs example"
                    >
                      {/* <Tab label="Favorites" {...a11yProps(0)} /> */}
                      <Tab label="All" {...a11yProps(0)} />
                      <Tab label="Spot" {...a11yProps(1)} />
                      <Tab label="Futures" {...a11yProps(2)} />
                    </Tabs>
                    <div>
                      <div className="input_grp">
                        <div className="input_txt left cursor-pointer">
                          <img src={searchs} alt="img" className="invert" />
                        </div>
                        <input type="text" placeholder="Search" value={search} onChange={(e) => { setSearch(e.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()); setPage(1) }} />
                      </div>
                    </div>
                  </div>
                  {/* <CustomTabPanel value={history} index={0} class="tab-content">
                    <div>
                      <div className="market-current-history_chg" >
                        <Tabs
                          value={fav}
                          onChange={handleChangefav}
                          aria-label="basic tabs example"
                        >
                          <Tab label="Spot" {...a11yProps(0)} />
                          <Tab label="Future" {...a11yProps(1)} />
                        </Tabs>
                        <CustomTabPanel value={fav} index={0} class="tab-content">
                          <div className="sm_title" >You may like</div>
                          <div className="gainer-history">{cardElements}</div>
                        </CustomTabPanel>
                        <CustomTabPanel value={fav} index={1} class="tab-content">
                          <div className="sm_title" >You may like</div>
                          <div className="gainer-history">{cardElements}</div>
                        </CustomTabPanel>

                      </div>
                    </div>
                  </CustomTabPanel> */}
                  <CustomTabPanel value={history} index={0} class="tab-content">
                    <div className="market-current-history_chg">
                      {/* <div className="display-2 tab-wrap" >
                        <Tabs
                          value={all}
                          onChange={handleChangeall}
                          aria-label="basic tabs example"
                        >
                          <Tab label="All" {...a11yProps(0)} />
                          <Tab label="Infrastructure" {...a11yProps(1)} />
                          <Tab label="Solana Ecosystem" {...a11yProps(2)} />
                          <Tab label="Memes" {...a11yProps(3)} />
                          <Tab label="Payments" {...a11yProps(4)} />
                          <Tab label="DeFi" {...a11yProps(5)} />
                        </Tabs>
                        <div>
                          <div className="check_box">
                            <div className="chk">
                              <input type="checkbox" />
                              <span className="round" ></span>
                            </div>
                            <span className="label_txt" >Available for Trading</span>
                          </div>
                        </div>
                      </div> */}
                      <CustomTabPanel value={all} index={0} class="tab-content">
                        {/* <div className="sub_head">Kaavi Market Watch</div> */}
                        <div className="market-table">
                          <TableContainer>
                            <Table aria-label="simple table" stickyHeader>
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left">Symbol</TableCell>
                                  <TableCell align="right">Price</TableCell>
                                  <TableCell align="right"> 24h Change</TableCell>
                                  <TableCell align="right">24h High</TableCell>
                                  <TableCell align="right"> 24h Low</TableCell>
                                  <TableCell align="right">24h Volume</TableCell>
                                  <TableCell align="right">Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {tableElements}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </CustomTabPanel>
                      <CustomTabPanel value={all} index={1} class="tab-content">
                        <div className="sub_head">Infrastructure</div>
                        <div className="market-table">
                          <TableContainer>
                            <Table aria-label="simple table" stickyHeader>
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left">Product</TableCell>
                                  <TableCell align="right">Price</TableCell>
                                  <TableCell align="right"><span className="green" > 24h</span> Change</TableCell>
                                  <TableCell align="right">Markets</TableCell>
                                  <TableCell align="right">Market Cap</TableCell>
                                  <TableCell align="right">24h Volume</TableCell>
                                  <TableCell align="right">Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {tableElements}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </CustomTabPanel>
                      <CustomTabPanel value={all} index={2} class="tab-content">
                        <div className="sub_head">Solana Ecosystem</div>
                        <div className="market-table">
                          <TableContainer>
                            <Table aria-label="simple table" stickyHeader>
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left">Product</TableCell>
                                  <TableCell align="right">Price</TableCell>
                                  <TableCell align="right"><span className="green" > 24h</span> Change</TableCell>
                                  <TableCell align="right">Markets</TableCell>
                                  <TableCell align="right">Market Cap</TableCell>
                                  <TableCell align="right">24h Volume</TableCell>
                                  <TableCell align="right">Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {tableElements}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </CustomTabPanel>
                      {/* <CustomTabPanel value={all} index={3} class="tab-content">
                        <div className="sub_head">Memes</div>
                        <div className="market-table">
                          <TableContainer>
                            <Table aria-label="simple table" stickyHeader>
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left">Product</TableCell>
                                  <TableCell align="right">Price</TableCell>
                                  <TableCell align="right"><span className="green" > 24h</span> Change</TableCell>
                                  <TableCell align="right">Markets</TableCell>
                                  <TableCell align="right">Market Cap</TableCell>
                                  <TableCell align="right">24h Volume</TableCell>
                                  <TableCell align="right">Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {tableElements}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </CustomTabPanel>
                      <CustomTabPanel value={all} index={4} class="tab-content">
                        <div className="sub_head">Payments</div>
                        <div className="market-table">
                          <TableContainer>
                            <Table aria-label="simple table" stickyHeader>
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left">Product</TableCell>
                                  <TableCell align="right">Price</TableCell>
                                  <TableCell align="right"><span className="green" > 24h</span> Change</TableCell>
                                  <TableCell align="right">Markets</TableCell>
                                  <TableCell align="right">Market Cap</TableCell>
                                  <TableCell align="right">24h Volume</TableCell>
                                  <TableCell align="right">Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {tableElements}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </CustomTabPanel>
                      <CustomTabPanel value={all} index={5} class="tab-content">
                        <div className="sub_head">DeFi</div>
                        <div className="market-table">
                          <TableContainer>
                            <Table aria-label="simple table" stickyHeader>
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left">Product</TableCell>
                                  <TableCell align="right">Price</TableCell>
                                  <TableCell align="right"><span className="green" > 24h</span> Change</TableCell>
                                  <TableCell align="right">Markets</TableCell>
                                  <TableCell align="right">Market Cap</TableCell>
                                  <TableCell align="right">24h Volume</TableCell>
                                  <TableCell align="right">Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {tableElements}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </CustomTabPanel> */}
                    </div>

                  </CustomTabPanel>
                  <CustomTabPanel value={history} index={1} class="tab-content">
                    <div className="market-table">
                      <TableContainer>
                        <Table aria-label="simple table" stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">Product</TableCell>
                              <TableCell align="right">Price</TableCell>
                              <TableCell align="right"><span className="green" > 24h</span> Change</TableCell>
                              <TableCell align="right">Markets</TableCell>
                              <TableCell align="right">Market Cap</TableCell>
                              <TableCell align="right">24h Volume</TableCell>
                              <TableCell align="right">Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {tableElements}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </CustomTabPanel>
                  <CustomTabPanel value={history} index={2} class="tab-content">
                    <div className="market-table">
                      <TableContainer>
                        <Table aria-label="simple table" stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">Product</TableCell>
                              <TableCell align="right">Price</TableCell>
                              <TableCell align="right"><span className="green" > 24h</span> Change</TableCell>
                              <TableCell align="right">Markets</TableCell>
                              <TableCell align="right">Market Cap</TableCell>
                              <TableCell align="right">24h Volume</TableCell>
                              <TableCell align="right">Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {tableElements}
                          </TableBody>
                        </Table>
                      </TableContainer>

                    </div>
                  </CustomTabPanel>
                </div>
                <div className="view-all text-center" style={{ paddingBottom: "20px" }}>
                  <Pagination count={Math.ceil(pairs?.length / perPage)} page={page} onChange={handleChange1} shape="rounded" />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Market;
