import { Button, Grid } from '@mui/material'
import React, { useState, useRef, useEffect } from 'react'
import Header from '../Header/Header'
import qr from '../../Img/qr-bar.jpg'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom';
import darkarrow from "../../Img/dark-Layer.png";
import lightarrow from "../../Img/light-layer.png";
import { Link } from "react-router-dom";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import usercalls from "../../Axios";
import consts from '../../constant';
import { QRCodeSVG } from "qrcode.react";


function Deposit() {

    const [age, setAge] = React.useState(10);
    const location = useLocation()
    const path = usercalls()


    const handleChange2 = (event) => {
        setAge(event.target.value);
    };

    const [network, setNetwork] = useState('')
    const [depositData, setDepositData] = useState({})

    const handleNetwork = (e) => {
        setNetwork(e.target.value)
    }


    const navigate = useNavigate()

    const [copySuccess, setCopySuccess] = useState("");
    const [copySuccessmemo, setCopySuccessmemo] = useState("");
    const inputRef = useRef();
    const defaultText = "0x2fc4d567b4e27fde365d6f12685b742d6ed19bb1";

    // const copyToClipBoard = async () => {
    //     try {
    //         if (inputRef.current) {
    //             const copyMe = inputRef.current.value;
    //             console.log(copyMe, 'copyme');

    //             await navigator.clipboard.writeText(copyMe);
    //             setCopySuccess("Copied!");
    //         }
    //     } catch (err) {
    //         setCopySuccess("Failed to copy!");
    //     }
    // };

    const copyToClipBoard = async (data, type) => {
        try {
            const copyMe = data
            await navigator.clipboard.writeText(copyMe);
            if (type == "memo") {
                setCopySuccessmemo("Copied!")
                setTimeout(() => {
                    setCopySuccessmemo('')
                }, 1000);
            } else {
                setCopySuccess("Copied!");
                setTimeout(() => {
                    setCopySuccess('')
                }, 1000);
            }

        } catch (err) {
            setCopySuccess("Failed to copy!");
        }
    };

    const getWalletsById = async () => {
        try {
            const payload = {
                id: location?.state
            }
            const url = `${consts.BackendUrl}/getWalletById`
            const response = await path.postCall({ url, payload });
            const result = await response.json();
            if (result?.success) {
                setDepositData(result.result)
            }
            else {
                setDepositData({})
            }
        } catch (error) {
            setDepositData({})
            console.log("🚀 ~ getwalletbyid ~ error:", error);
        }
    }

    useEffect(() => {
        getWalletsById()
    }, [])

    const [minDep, setMinDep] = useState()

    const [depositAddress, setDepositAddress] = useState()
    const [memo, setMemo] = useState()


    const setAdd = async (network) => {
        try {
            var address = depositData?.address_list?.find(row => row?.network == network)
            if (address && address?.address) {
                setDepositAddress(address?.address)
                setMemo(address?.tag)
            } else {
                setDepositAddress("")
                setMemo("")
                const payload = {
                    id: location?.state,
                    asset: depositData?.coinname,
                    network: network
                }
                const url = `${consts.BackendUrl}/createDepositAddress`
                const response = await path.postCall({ url, payload });
                const result = await response.json();
                if (result?.success == true) {
                    getWalletsById()
                    setDepositAddress(result?.result?.address)
                    if (result?.result?.tag) {
                        setMemo(result?.result?.tag)
                    }
                }
            }
        } catch (error) {
            console.log("🚀 ~ setAdd ~ error:", error)
        }
    }


    return (
        <div className='deposit-history'>
            <Grid container className='justi-center'>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Header />
                </Grid>
            </Grid>
            <div className='contain-width'>
                <Grid container className='justi-center'>

                    <Grid xs={12} sm={12} md={12} lg={12} xl={9}>
                        {/* <div className='back-arrow'>
                        <ArrowBackIcon onClick={(() => { navigate(-1) })} className='cursor' />
                    </div> */}
                        <div class="ivon-back">
                            <Link to="/wallet" className="light-mode-img">
                                <img src={darkarrow} />
                            </Link>

                            <Link to="/wallet" className="dark-mode-img">
                                <img src={lightarrow} />
                            </Link>
                        </div>
                    </Grid>
                    <Grid xs={12} sm={12} md={6} lg={12} xl={12}>

                        <div className='deposit-div-1 ticket-box'>
                            <div className='dh-title text-center display-2 gap'>
                                {/* <div className='table-logo'>
                                <img src={coin.img} alt={coin.img} />
                            </div> */}
                                {/* {coin.coin} */}
                                Deposit Address
                            </div>
                            {
                                depositAddress &&
                                <>
                                    <div className='last-update text-center'>
                                        Scan QR code to Deposit
                                    </div>
                                    <div className='qr-image text-center'>
                                        {/* <img src={qr} alt='qr' /> */}
                                        <QRCodeSVG value={depositAddress} includeMargin={true} />
                                    </div>
                                </>
                            }
                            <div className='disclaimer text-center'>
                                Disclaimer
                            </div>
                            <div className='disclaimer text-center'>
                                Please deposit only USDT to this address. If you deposit any other coins, it will be lost forever
                            </div>
                            <div className='dep-select'>
                                <div className='last-update'>Select Crypto</div>
                                {/* <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Us</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={depositData?.coinname}
                                        label="Usdt"
                                        onChange={handleChange2}
                                    >
                                        <MenuItem value={10}>USDT</MenuItem>
                                        <MenuItem value={20}>Euro</MenuItem>
                                        <MenuItem value={30}>Riyal</MenuItem>
                                    </Select>
                                </FormControl> */}
                                <TextField id="outlined-basic" variant="outlined" value={depositData?.coinname} fullWidth />
                            </div>
                            <div className='dep-select'>
                                <div className='last-update'>Select Network</div>
                                <FormControl fullWidth>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={network}
                                        onChange={handleNetwork}
                                    >
                                        {depositData?.networkList?.length > 0 &&
                                            depositData?.networkList.map((row) => (
                                                <MenuItem key={row?.network} value={row?.network} onClick={() => { setMinDep(row?.mininumDeposit); setAdd(row?.network) }} >
                                                    {row?.network}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            <div>
                                <div className='last-update'>Destination Address </div>
                                <div className='wallet-address-input display-2 dep-select'>
                                    {/* <TextField id="outlined-basic" variant="outlined" ref={inputRef} defaultValue={defaultText} /> */}
                                    <TextField id="outlined-basic" variant="outlined" value={depositAddress} />
                                    <Button onClick={() => { copyToClipBoard(depositAddress, "deposit") }} className="clipboard">
                                        <ContentCopyIcon />
                                    </Button>
                                    {copySuccess}
                                </div>

                            </div>
                            {
                                memo &&
                                <div>
                                    <div className='last-update'>Memo </div>
                                    <div className='wallet-address-input display-2 dep-select'>
                                        {/* <TextField id="outlined-basic" variant="outlined" ref={inputRef} defaultValue={defaultText} /> */}
                                        <TextField id="outlined-basic" variant="outlined" value={memo} />
                                        <Button onClick={() => { copyToClipBoard(memo, "memo") }} className="clipboard">
                                            <ContentCopyIcon />
                                        </Button>
                                        {copySuccessmemo}
                                    </div>
                                </div>
                            }
                            {minDep && <div style={{ color: "white" }} >Mininum Deposite: {minDep}</div>}
                            <div className='proceed-withdraw'>
                                <Button variant='contained' onClick={() => { navigate('/wallet') }}>
                                    Done
                                </Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Deposit
