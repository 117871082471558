import { Button, Grid } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Header from '../Header/Header'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, Link } from 'react-router-dom';
import darkarrow from "../../Img/dark-Layer.png";
import lightarrow from "../../Img/light-layer.png";
import { useLocation } from 'react-router-dom';
import usercalls from "../../Axios";
import consts from '../../constant';
import { toast, ToastContainer } from 'react-toastify';

function Withdraw() {

    const [age, setAge] = React.useState(10);
    const location = useLocation()
    const path = usercalls()

    const handleChange2 = (event) => {
        setAge(event.target.value);
    };

    const [network, setNetwork] = useState(10)
    const [withdrawData, setWithdrawtData] = useState({})

    const [address, setAddress] = useState("")

    const [amount, setAmount] = useState("")


    const [selcNetwork, setSelcNetwork] = useState()

    const handleNewtwork = (e) => {
        console.log(e.target.value, "e.target.value")
        setNetwork(e.target.value)
    }

    const navigate = useNavigate()

    const getWalletsById = async () => {
        try {
            const payload = {
                id: location?.state?.data
            }
            const url = `${consts.BackendUrl}/getWalletById`
            const response = await path.postCall({ url, payload });
            const result = await response.json();
            if (result?.success) {
                setWithdrawtData(result.result)
            }
            else {
                setWithdrawtData({})
            }
        } catch (error) {
            setWithdrawtData({})
            console.log("🚀 ~ getwalletbyid ~ error:", error);
        }
    }

    useEffect(() => {
        getWalletsById()
    }, [])

    const [status, setStatus] = useState(false)

    const withDraw = async () => {
        try {
            // const regex = `${selcNetwork?.addressRegex}`
            // const regex = /^(0x)[0-9A-Fa-f]{40}$/
            // console.log("🚀 ~ withDraw ~ regex:", regex, address)
            if (network == "") {
                toast.error("Please Select Network")
            } else if (address == "") {
                toast.error("Please Enter Destination Address")
            }
            // else if (!regex?.test(address)) {
            //     toast.error("Please Enter Correct Address")
            // }
            else if (amount == "") {
                toast.error("Please Enter Amount")
            } else if (Number(amount) < Number(selcNetwork?.withdrawMin)) {
                toast.error("Please Enter Correct Amount")
            } else if (Number(amount) > Number(selcNetwork?.withdrawMax)) {
                toast.error("Maximum Amount Reached")
            } else if (Number(amount) > Number(location?.state?.amount)) {
                toast.error("You Dont Have Sufficient Balance")
            } else {
                setStatus(true)
                const payload = {
                    Address: address,
                    Network: network,
                    Amount: amount,
                    Coinname: withdrawData?.coinname
                }
                const url = `${consts.BackendUrl}/WithDraw`
                const response = await path.postCall({ url, payload });
                const result = await response.json();
                if (result?.success == true) {
                    toast.success(result?.message)
                    setTimeout(() => {
                        navigate("/wallet")
                    }, 1000);
                } else {
                    toast.error(result?.message)
                }
                setStatus(false)
            }
        } catch (error) {
            setStatus(false)
            console.log("🚀 ~ withDraw ~ error:", error)
        }
    }


    return (
        <div className='deposit-history'>
            <ToastContainer />
            <Grid container>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Header />
                </Grid>
            </Grid>
            <div className='contain-width'>
                <Grid container className='justi-center'>

                    <Grid xs={12} sm={12} md={12} lg={12} xl={9}>
                        <div class="ivon-back">
                            <Link to="/wallet" className="light-mode-img">
                                <img src={darkarrow} />
                            </Link>

                            <Link to="/wallet" className="dark-mode-img">
                                <img src={lightarrow} />
                            </Link>
                        </div>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='deposit-div-1 ticket-box'>
                            <div className='dh-title text-center display-2 gap'>
                                Withdraw Coin From Wallet to Another
                            </div>
                            <div className='dep-select'>
                                <div className='last-update'>Select Crypto</div>
                                {/* <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Us</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={age}
                                        label="Usdt"
                                        onChange={handleChange2}
                                    >
                                        <MenuItem value={10}>USDT</MenuItem>
                                        <MenuItem value={20}>Euro</MenuItem>
                                        <MenuItem value={30}>Riyal</MenuItem>
                                    </Select>
                                </FormControl> */}
                                <TextField id="outlined-basic" variant="outlined" value={withdrawData?.coinname} fullWidth />
                            </div>
                            <div className='dep-select'>
                                <div className='last-update'>Select Network</div>
                                <FormControl fullWidth>
                                    {/* <InputLabel id="demo-simple-select-label">Us</InputLabel> */}
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={network}
                                        onChange={handleNewtwork}
                                    >
                                        {withdrawData?.networkList?.length > 0 &&
                                            withdrawData?.networkList.map((row) => (
                                                <MenuItem key={row?.network} value={row?.network} onClick={() => { setSelcNetwork(row) }} >
                                                    {row?.network}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            <div>
                                <div className='last-update'>Destination Address
                                </div>
                                <div className='wallet-address-input display-2 dep-select'>
                                    <TextField id="outlined-basic" variant="outlined" value={address} onChange={(e) => setAddress(e.target.value)} />
                                </div>
                            </div>
                            <div className='dep-select'>
                                <div className='last-update'>Amount</div>
                                <div className='wallet-address-input'>
                                    <TextField type='number' id="outlined-basic" variant="outlined" value={amount} onChange={(e) => setAmount(e.target.value)} />
                                </div>
                            </div>
                            <div className='text-right proceed-left'>
                                Withdrawal Limit : {selcNetwork?.withdrawMin ? selcNetwork?.withdrawMin : 0} MIN  - {selcNetwork?.withdrawMax ? selcNetwork?.withdrawMax : 0} MAX
                            </div>
                            <div className='text-right proceed-left'>
                                Wallet Balance in {withdrawData?.coinname} : {location?.state?.amount} {withdrawData?.coinname}
                            </div>
                            <div className='text-right proceed-left'>
                                Withdraw Fee {selcNetwork?.withdrawFee ? selcNetwork?.withdrawFee : 0} {withdrawData?.coinname ? withdrawData?.coinname : 0}
                            </div>
                            <div className='proceed-withdraw'>
                                <Button variant='contained' disabled={status} onClick={() => { withDraw() }} >
                                    Proceed Withdraw
                                </Button>
                            </div>

                            <div className='disclaimer text-left'>
                                Disclaimer
                            </div>
                            <div className='disclaimer text-left'>
                                Please deposit only USDT to this address. If you deposit any other coins, it will be lost forever
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Withdraw
