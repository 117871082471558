import React, { useState, useEffect, useRef } from 'react'
import { Button, Grid } from '@mui/material'
import Header from '../Header/Header'
// import ApexChart from './Apexchart'
import Tradeview from './Tradeview';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './Trade.scss'
import OrderBook from './OrderBook';
import { useTheme, useMediaQuery } from '@mui/material';
import Placeorder from './Placeorder';
import Modal from '@mui/material/Modal';
// import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import $ from 'jquery'
import nodata from '../../Img/thankyou.gif'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Btcoin from '../../Img/btcoin.png'
import { Drop, Drop2, Info, M1, M2, M3, Msg, Trading, Wifi } from '../../SvgCollection';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TradeHistory from './TradeHistory';
import consts from '../../constant';
import usercalls from '../../Axios'
import { useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import loading from '../../Img/favicon.png'
import PlaceOrderFuture from './PlaceOrderFuture';
import PlaceOrderMargin from './PlaceOrderMargin';
import { useLocation } from 'react-router-dom';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const style = {
    position: 'absolute',
    top: '12%',
    left: '0%',  // Start from the left side of the screen
    transform: 'translateY(-50%)', // Center vertically
    width: 600,
    bgcolor: 'var(--model)',
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 2,
    '@media (max-width: 767.98px)': {
        width: '90%',
        left: '0%'
    },
};





function Trade(props) {

    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

    const path = usercalls()
    const sm = useMediaQuery(theme.breakpoints.up('sm'));

    const loaction = useLocation();

    const placeRoute = loaction.pathname?.split('/')[1];

    const [openLoad, setOpenLoad] = useState(false);
    const handleCloseLoad = () => {
        setOpenLoad(false);
    };
    const handleOpenLoad = () => {
        setOpenLoad(true);
    };

    const { id } = useParams()
    const mobilemd = useMediaQuery(theme.breakpoints.up('md'));

    // type like spot features margin
    const [type, setType] = useState("spot")

    const [quoteCoin, setQuoteCoin] = useState("USDT")

    const [binancesoc, setBinanceSoc] = useState()

    const [value, setValue] = useState(0);

    const [currentPair, setCurrentPair] = useState()

    const [currentSoc, setCurrentSoc] = useState()

    const currentSocket = async () => {
        try {
            const ws = new WebSocket("wss://stream.binance.com:9443/ws");
            setCurrentSoc(ws)
            ws.onopen = (event) => {
                const symbols = `${currentPair?.split("_")?.join("")?.toLowerCase()}@ticker`
                const priceApicall = {
                    "method": "SUBSCRIBE",
                    "params": [symbols],
                    "id": 1
                }
                ws.send(JSON.stringify(priceApicall));
            };

            ws.onmessage = function (event) {
                const json = JSON.parse(event.data);
                try {
                    $(`.prices-${json?.s}`).html(`<span class=${Number(json?.P).toFixed(2) > 0 ? 'tr-green' : 'tr-red'} >${Number(json?.c).toFixed(4)}</span>`);
                    $(`.24hp-${json?.s}`).html(`<span class=${Number(json?.P).toFixed(2) > 0 ? 'tr-green' : 'tr-red'} >${Number(json?.p).toFixed(2)}</span>`);
                    $(`.24hpc-${json?.s}`).html(`<span class=${Number(json?.P).toFixed(2) > 0 ? 'tr-green' : 'tr-red'} >${Number(json?.P).toFixed(2) + "%"}</span>`);
                    $(`.open-${json?.s}`).html(Number(json?.o).toFixed(2));
                    $(`.vol-${json?.s}`).html(Number(json?.v).toFixed(2));
                    $(`.high-${json?.s}`).html(Number(json?.h).toFixed(2));
                    $(`.low-${json?.s}`).html(Number(json?.l).toFixed(2));
                } catch (error) {
                    console.log(error, 'err');

                }
            };

            ws.onerror = (error) => {
                console.error('WebSocket Error:', error);
            };

            ws.onclose = () => {
                console.log('WebSocket Connection Closed');
            };

            return () => {
                console.log('Cleaning up WebSocket connection');
                ws.close();
            };
        } catch (error) {
            console.log("🚀 ~ currentSocket ~ error:", error)
        }
    }

    useEffect(() => {
        if (currentPair) {
            currentSocket()
        }
    }, [currentPair])

    useEffect(() => {
        if (id) {
            setCurrentPair(id)
        }
    }, [id])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const [value5, setValue5] = useState(0);

    const handleChange5 = (event, newValue) => {
        setValue5(newValue);
    };


    const [value6, setValue6] = useState(0);

    const handleChange6 = (event, newValue) => {
        setValue6(newValue);
    };

    const [value7, setValue7] = useState(0);

    const handleChange7 = (event, newValue) => {
        const tabLabels = ['USDT', 'USDC', 'BTC', 'ETH'];
        setValue7(newValue);
        setQuoteCoin(tabLabels[newValue])
    };


    const [value8, setValue8] = useState(0);

    const handleChange8 = (event, newValue) => {
        setValue8(newValue);
    };

    const [valueTab2, setValueTab2] = useState(0);

    const handleChangeTab2 = (event, newValue) => {
        const tabLabels = ['spot', 'future', 'spot'];
        console.log('Selected Tab Label:', tabLabels[newValue]); // Log the label of the selected tab
        setValueTab2(newValue);
        setType(tabLabels[newValue])
    };

    const [age, setAge] = useState(10);

    const [dropDown, setDropDown] = useState(false)

    const handleChangeAge = (event) => {
        setAge(event.target.value);

    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    /*drawer */

    const [bysel, setBysel] = useState(null);


    // console.log(bysel, 'bysel');
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });



    const [num, setNum] = useState([
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
        { n1: '66,922.6' },
    ])


    const subscribePair = async () => {
        try {
            const ws = new WebSocket("wss://stream.binance.com:9443/ws");
            setBinanceSoc(ws)
            ws.onopen = (event) => {
                const symbols = pairsList?.map(data => `${data?.symbol?.toLowerCase()}@ticker`);
                const priceApicall = {
                    "method": "SUBSCRIBE",
                    "params": symbols,
                    "id": 1
                }
                ws.send(JSON.stringify(priceApicall));
            };

            ws.onmessage = function (event) {
                const json = JSON.parse(event.data);
                try {
                    $(`.price-${json?.s}`).html(`<span class=${Number(json?.P).toFixed(2) > 0 ? 'tr-green' : 'tr-red'} > ${Number(json?.c).toFixed(6)}</span>`);
                    $(`.24hrchange-${json?.s}`).html(`<span class=${Number(json?.P).toFixed(2) > 0 ? 'tr-green' : 'tr-red'} >${Number(json?.P).toFixed(2) + "%"}</span>`);
                } catch (error) {
                    console.log(error, 'err');
                }
            };

            ws.onerror = (error) => {
                console.error('WebSocket Error:', error);
            };

            ws.onclose = () => {
                console.log('WebSocket Connection Closed');
            };

            return () => {
                console.log('Cleaning up WebSocket connection');
                ws.close();
            };
        } catch (error) {
            console.log("🚀 ~ subscribePair ~ error:", error)
        }
    }

    const unSubscribePair = async () => {
        try {
            binancesoc.close()

        } catch (error) {
            console.log("🚀 ~ unSubscribePair ~ error:", error)
        }
    }

    // pairs list
    const [pairsList, setPairsList] = useState([])
    const searchDebounceTimeout = useRef(null);


    const fetchPairsList = async (search) => {
        try {
            setPairsList([])
            const url = `${consts.BackendUrl}/getPairs`
            var payload = {
                type: type,
                quoteCoin: quoteCoin
            }
            const response = await path.postCallWithoutAuthToken({ url, payload })
            const result = await response.json();
            if (result?.success == true) {
                // console.log(result?.result, "result")

                if ((search != "") && (search != undefined)) {

                    var matchPairs = [];

                    for (var i = 0; i < result?.result.length; i++) {
                        var element = result?.result[i];

                        if (element?.symbol.includes(search.toUpperCase())) {

                            matchPairs.push(element);

                        }

                    }

                    if (matchPairs.length > 0) {
                        setPairsList([...matchPairs]);
                    } else {
                        setPairsList();
                    }

                } else {
                    setPairsList(result?.result)
                }


            } else {
                setPairsList([])
            }
        } catch (error) {
            console.log("🚀 ~ fetchPairsList ~ error:", error)
        }
    }

    useEffect(() => {
        fetchPairsList()
    }, [type, quoteCoin])

    useEffect(() => {
        if (pairsList?.length > 0) {
            unSubscribePair()
            subscribePair()
        }
    }, [pairsList])

    const handleSearch = async (search) => {

        if (searchDebounceTimeout.current) {
            clearTimeout(searchDebounceTimeout.current);
        }

        searchDebounceTimeout.current = setTimeout(() => {
            fetchPairsList(search);
        }, 500);

    };


    const [balanceStatus, setBalanceStatus] = useState("")


    const [prices, setPrices] = useState([])
    const [historyStatus, setHistoryStatus] = useState()

    const midpoint = Math.floor(num.length / 2);
    return (
        <div className='Tradepage'>
            <Grid container>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Header />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} >
                    <div className="display-2 padding-10 ">
                        <div className="display-1" style={{ gap: "20px", overflowY: !sm && "scroll" }}>
                            {/* <label for="filter" class="switch" aria-label="Toggle Filter">
                                <input type="checkbox" id="filter" />
                                <span>Manual</span>
                                <span>Bot</span>
                            </label> */}

                            {/* <div className="hoz-des"></div> */}
                            <div className="select-form display-1 cursor" onClick={handleOpen}>
                                {/* <img src={Btcoin} alt="Btcoin" className='Btcoin' /> */}
                                {currentPair?.split("_")?.join("/")}
                                <Drop />
                            </div>

                            {/* <Info className='cursor' /> */}
                            <div className="display-1" style={{ gap: "50px", overflowY: !sm && "scroll" }}>
                                <div className="total-data">
                                    <div className={`prices-${currentPair?.split("_")?.join("")}`}  >-</div>
                                    <div className="total-sub">
                                        <span className={`24hp-${currentPair?.split("_")?.join("")}`} >-</span><span className={`24hpc-${currentPair?.split("_")?.join("")}`} >-</span>
                                    </div>
                                </div>

                                <div className="lh-24">
                                    24h High
                                    <div className="lh-sub">
                                        <span className={`high-${currentPair?.split("_")?.join("")}`} >-</span>
                                    </div>
                                </div>

                                <div className="lh-24">
                                    24h Low
                                    <div className="lh-sub">
                                        <span className={`low-${currentPair?.split("_")?.join("")}`} >-</span>
                                    </div>
                                </div>

                                <div className="lh-24">
                                    24h Volume ({currentPair?.split("_")[0]})
                                    <div className="lh-sub">
                                        <span className={`vol-${currentPair?.split("_")?.join("")}`} >-</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* <div className="display-1" style={{ gap: "20px" }}>
                            <Wifi />

                            <div className="hoz-des"></div>

                            <div className="display-1 lh-sub">
                                <Msg />
                                News
                            </div>

                            <div className="display-1 lh-sub">
                                <Trading />
                                Trading Info
                            </div>

                            <div className="hoz-des"></div>
                            <M1 />
                            <M2 />
                            <M3 />
                        </div> */}

                    </div>


                </Grid>
                <Grid xs={12} sm={12} md={7} lg={6} xl={7.8} >
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ padding: '10px 0px' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='tabs-trade'>
                                <Tab label="Chart" {...a11yProps(0)} />
                                <Tab label="Market Depth" {...a11yProps(1)} />
                                {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <Tradeview pair={currentPair?.split("_")?.join("")} />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <Tradeview />
                        </CustomTabPanel>
                    </Box>

                </Grid>
                <Grid xs={12} sm={12} md={5} lg={2.5} xl={2} >
                    <OrderBook pair={currentPair} setPrices={setPrices} />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={3.5} xl={2.2} >
                    {placeRoute === 'trade' ? <Placeorder prices={prices} setHistoryStatus={setHistoryStatus} balanceStatus={balanceStatus} /> : placeRoute === 'margin' ? <PlaceOrderMargin prices={prices} setHistoryStatus={setHistoryStatus} /> : <PlaceOrderFuture prices={prices} setHistoryStatus={setHistoryStatus} />}
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} order={!mobilemd ? 4 : 4}>
                    <TradeHistory historyStatus={historyStatus} setBalanceStatus={setBalanceStatus} />
                </Grid>
            </Grid>
            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Slide in={open} direction="right">
                    <Box sx={{ ...style }} className='modal-trade'>
                        <div className="text-right">
                            <HighlightOffIcon sx={{ fill: 'var(--clr-font)', cursor: 'pointer' }} onClick={handleClose} />
                        </div>
                        <div className="mt-10">
                            <div class="group">
                                <svg viewBox="0 0 24 24" aria-hidden="true" class="search-icon">
                                    <g>
                                        <path
                                            d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"
                                        ></path>
                                    </g>
                                </svg>

                                <input
                                    onChange={(e) => handleSearch(e.target.value)}
                                    id="query"
                                    class="input"
                                    type="text"
                                    placeholder="Search..."
                                    name="searchbar"
                                />
                            </div>

                        </div>
                        <Box sx={{ width: '100%' }}>
                            <Box >
                                <Tabs value={valueTab2} onChange={handleChangeTab2} aria-label="basic tabs example" className='tabs-trade'>
                                    <Tab label="spot" {...a11yProps(0)} />
                                    <Tab label="future" {...a11yProps(1)} />
                                    <Tab label="Margin" {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel>
                                <Box sx={{ width: '100%' }}>
                                    <Box >
                                        <Tabs value={value7} onChange={handleChange7} aria-label="basic tabs example" className='tabs-trade'>
                                            <Tab label="USDT" {...a11yProps(0)} />
                                            <Tab label="USDC" {...a11yProps(1)} />
                                            <Tab label="BTC"{...a11yProps(2)} />
                                            <Tab label="ETH"{...a11yProps(3)} />
                                        </Tabs>
                                    </Box>
                                    <CustomTabPanel className='cust-box'>
                                        <TableContainer className='trade-left-table model'>
                                            <Table aria-label="simple table" stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align='left'>Trading Pairs/Vol </TableCell>
                                                        <TableCell align='right'>Price</TableCell>
                                                        <TableCell align='right'>24H Change</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {pairsList?.length > 0 && pairsList?.map((row, ind) => (
                                                        <TableRow
                                                            key={ind}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            onClick={() => {
                                                                window.location.href = `/${placeRoute}/${row?.baseCoin}_${row?.quoteCoin}`
                                                            }}
                                                        >
                                                            <TableCell component="th" scope="row" align='left' >
                                                                <div className="select-form display-1 cursor" onClick={handleOpen}>
                                                                    {/* <img src={Btcoin} alt="Btcoin" className='Btcoin' /> */}
                                                                    {row?.baseCoin}/{row?.quoteCoin}
                                                                </div>

                                                            </TableCell>

                                                            <TableCell component="th" className={`price-${row?.symbol}`} scope="row" align='right'>
                                                            </TableCell>
                                                            <TableCell component="th" className={`24hrchange-${row?.symbol}`} scope="row" align='right'>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </CustomTabPanel>
                                </Box>
                            </CustomTabPanel>
                        </Box>
                    </Box>
                </Slide>
            </Modal>
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={openLoad}
                onClick={handleCloseLoad}
            >

                <div class="loading-bar">
                    <img src={loading} alt="loading" width="80px" height="80px" style={{ marginTop: '15px' }} />
                </div>

            </Backdrop>
        </div >
    )
}

export default Trade
