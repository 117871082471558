// soundUtils.js
import tone from './click.wav'


const clickSound = new Audio(tone);

// Function to play sound
export const playClickSound = () => {
    clickSound.currentTime = 0;
    clickSound.play();
};

// Function to add event listeners
export const addClickSoundListener = () => {
    // document.addEventListener('click', playClickSound);
    // document.addEventListener('change', playClickSound);
};

// Function to remove event listeners (for cleanup if needed)
export const removeClickSoundListener = () => {
    // document.removeEventListener('click', playClickSound);
    // document.removeEventListener('change', playClickSound);
};