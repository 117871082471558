import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import Header from "../Header/Header";
import "./Staking.scss";
import darkarrow from "../../Img/dark-Layer.png";
import lightarrow from "../../Img/light-layer.png";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";
import HttpsIcon from "@mui/icons-material/Https";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import ErrorIcon from "@mui/icons-material/Error";
import EastIcon from "@mui/icons-material/East";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const tabs = [{ name: "Flexible" }, { name: "Fixed" }];

// console.log(tabs, "tabs");

const Subscribe = () => {
  // password show or not

  const [showPassword, setShowPassword] = React.useState(false);
  const handleShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="staking">
      <Grid container className="justi-center">
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header />
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={10} className="staking-body">
          <Grid container>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <div class="ivon-back">
                <Link to="/staking" className="light-mode-img">
                  <img src={darkarrow} />
                </Link>

                <Link to="/staking" className="dark-mode-img">
                  <img src={lightarrow} />
                </Link>
              </div>
              <div class="container sitecontainer payment-left-main cryptosaving ticket-box">
                <div class="saving-page">
                  <div class="contain-width">
                    <div class="data-cnt">
                      <Typography variant="h1" className="cmn-text">
                        Subscribe
                      </Typography>
                    </div>
                    <div class="pop-sep">
                      <div class="duration">Duration (Days)</div>
                      <div class="ratio-btn">
                        <TextField
                          type="radio"
                          id="axscat"
                          name="duration_title"
                          value="120"
                        />
                        <label for="axscat">90 days</label>
                      </div>
                    </div>
                    <div className="profile-change">
                      <Typography variant="label">
                        Select Reward Type
                      </Typography>
                      <div class="max-amt">
                        <TextField
                          id="outlined-basic amount"
                          placeholder="Enter amount"
                          variant="outlined"
                          class="input-box"
                          name="no_of_coin"
                        />
                        <span id="errorText"></span>
                        <div class="bn-input-suffix pop-sep">
                          <div class="max-btn">
                            <TextField
                              type="button"
                              class="css-i6m49h"
                              value="Max"
                              id="max_amount"
                            />
                            <div class="btn-img">
                              <p>BTC</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="buybtn">
                      <div class="avaliable-btn">
                        <Typography variant="h4">
                          0.00000000 BTC <span>Available</span>
                        </Typography>
                      </div>
                    </div>

                    <div class="enough">
                      <div class="info">
                        <ErrorIcon />
                        <Typography variant="h4">
                          Don’t have enough crypto?
                        </Typography>
                      </div>
                      <div class="info">
                        <Typography variant="h4">
                          <Link to="">Convert</Link>
                        </Typography>
                        <EastIcon />
                      </div>
                    </div>

                    <div class="minmum">
                      <Typography variant="h4">Amount Limits</Typography>
                      <Typography variant="h4">
                        <span>Minimum: </span>1 BTC
                      </Typography>
                    </div>

                    <div class="left-txt">
                      <div class="summary-detail">
                        <Typography variant="h4">Summary</Typography>
                        <div class="locked-est">
                          <div class="est-update">
                            <Typography variant="p" component="p">
                              Locked Est. APR<span>8.10%</span>
                            </Typography>
                          </div>
                          <div class="data-result">
                            <Typography
                              variant="p"
                              component="p"
                              className="green-text"
                            >
                              --SOL
                            </Typography>
                          </div>
                        </div>
                        <div class="distribution-date">
                          <div class="locked-est">
                            <div class="est-update">
                              <Typography variant="p" component="p">
                                First Distribution Date
                              </Typography>
                            </div>
                            <div class="data-result">
                              <Typography
                                variant="p"
                                component="p"
                                className="date-text"
                              >
                                2024-05-24 &nbsp; 00.00
                              </Typography>
                            </div>
                          </div>
                          <div class="locked-est">
                            <div class="est-update">
                              <Typography variant="p" component="p">
                                Interest End Date <Link to="#">View Rules</Link>
                              </Typography>
                            </div>
                            <div class="data-result">
                              <Typography
                                variant="p"
                                component="p"
                                className="date-text"
                              >
                                2024-05-24 &nbsp; 00.00
                              </Typography>
                            </div>
                          </div>
                          <div class="locked-est">
                            <div class="est-update">
                              <Typography variant="p" component="p">
                                Next Subscription Date
                              </Typography>
                            </div>
                            <div class="data-result">
                              <Typography
                                variant="p"
                                component="p"
                                className="date-text"
                              >
                                2024-05-24 &nbsp; 00.00
                              </Typography>
                            </div>
                          </div>
                          <div class="apr-rules">
                            <ul>
                              <li>
                                The Simple Earn APR is subject to change on a
                                daily basis. APR does not mean the actual or
                                predicted returns in fiat currency.
                              </li>
                              <li>
                                Early redemption will return your assets to your
                                Spot Wallet within 48-72 hours.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div class="cnfrm-btn">
                        <Button type="submit" id="submitButton">
                          Confirm
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Subscribe;
