import React, { createContext, useState, useEffect } from 'react';

// Create the ThemeContext
export const ThemeContext = createContext();

const getThemeFromStorage = () => {
    const theme = localStorage.getItem('theme');
    return theme ? JSON.parse(theme) : false;
};

// Create the ThemeProvider component
export const ThemeProvider = ({ children, sendingModule }) => {
    const [isLightMode, setIsLightMode] = useState(getThemeFromStorage());

    const handleDarkmode = () => {
        setIsLightMode((prevMode) => {
            const newMode = !prevMode;
            // Optionally call sendingModule here if needed
            if (sendingModule) sendingModule(newMode);
            return newMode;
        });
    };

    useEffect(() => {
        document.body.className = isLightMode ? 'light-theme' : 'dark-theme';
        localStorage.setItem('theme', JSON.stringify(isLightMode));
    }, [isLightMode]);

    return (
        <ThemeContext.Provider value={{ isLightMode, handleDarkmode }}>
            {children}
        </ThemeContext.Provider>
    );
};
