import { Button, Grid } from "@mui/material";
import { React, useState, useRef  } from "react";
import Header from "../Header/Header";
import "./Twoverify.scss";
import { TextField } from "@mui/material";
import Stack from "@mui/material/Stack";
import qr from "../../Img/qr.png";
import GoogleIcon from "@mui/icons-material/Google";
import darkarrow from "../../Img/dark-Layer.png";
import lightarrow from "../../Img/light-layer.png";
import { Link } from "react-router-dom";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import googlelogo from '../../Img/google-logo.png'

const Twofaverify = () => {
  const [copySuccess, setCopySuccess] = useState("");
  const inputRef = useRef(null);
  const defaultText = "some text to copy";

  const copyToClipBoard = async () => {
    try {
      if (inputRef.current) {
        const copyMe = inputRef.current.value;
        await navigator.clipboard.writeText(copyMe);
        setCopySuccess("Copied!");
      }
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };


  return (
    <div className="twofa-verify">
      <Grid container>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header />
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <div class="ivon-back">
            <Link to="/profilenew" className="light-mode-img">
              <img src={darkarrow} />
            </Link>

            <Link to="/profilenew" className="dark-mode-img">
              <img src={lightarrow} />
            </Link>
          </div>
          <div className="support-head contain-width">
            <h1>Google 2FA Authentication</h1>
          </div>
          <Grid container className="contain-width" spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="ticket-box">
                <h2>Setup Google 2FA Authentication</h2>
                <div className="google-auth">
                  <ul className="auth-steps">
                    <li> Download Google Authenticator</li>
                    <li>
                      Add key phrase into Google Authenticator and remember the
                      key phrase
                    </li>
                  </ul>
                  <div className="qr-bar">
                    <img src={qr} />
                    <div className="copy-id">
                    <TextField type="text" ref={inputRef} defaultValue={defaultText}  />
                      <Button onClick={copyToClipBoard} className="clipboard">
                        <ContentCopyIcon/>
                      </Button>
                    
                    </div>
                    {copySuccess}
                  </div>
                  <div className="google-fa-code">
                    <div className="fa-code">
                      {/* <GoogleIcon /> */}
                      <img src={googlelogo}/>
                      Google 2FA Code
                    </div>
                    <TextField fullWidth type="text" />
                  </div>
                  <div className="confirm-cancel">
                    <Stack spacing={2} direction="row">
                      <Button variant="contained">Confirm</Button>
                      <Button variant="outlined">Cancel</Button>
                    </Stack>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Twofaverify;
