import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const PrivateRoute = ({ children }) => {
    var [isAuthenticated, setIsAuthenticated] = useState()
    var [returnurl, setReturnUrl] = useState("")



    useEffect(() => {
        handleFunc()
    }, [])

    const handleFunc = async () => {
        var token = window.localStorage.getItem('Rikosta');
        if (token) {
            setIsAuthenticated(true)
            setReturnUrl('/trade')
        } else {
            setIsAuthenticated(false)
            setReturnUrl('/login')
            setTimeout(() => {
                toast.error("Please Login")
            }, 1000);
        }
    }
    return isAuthenticated && isAuthenticated == true ? children : isAuthenticated == false ? <Navigate to={returnurl} /> : "";
};

export default PrivateRoute;