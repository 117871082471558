import { Button, Grid } from '@mui/material'
import React, { useState } from 'react'
import Header from '../Header/Header'
// import './Profile.scss'
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import HttpsIcon from '@mui/icons-material/Https';
import DescriptionIcon from '@mui/icons-material/Description';
import PaidIcon from '@mui/icons-material/Paid';
import LogoutIcon from '@mui/icons-material/Logout';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import idfront from '../../Img/id-front.webp'
import upload from '../../Img/upload.jpg'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


const tabs = [
    { name: 'Personal Details', icon: <PersonIcon /> },
    { name: 'Address Details', icon: <HttpsIcon /> },
    { name: 'Document Upload', icon: <DescriptionIcon /> },
    { name: 'Identity Details ', icon: <PaidIcon /> },
    { name: 'Finish!', icon: <LogoutIcon /> },
]

// console.log(tabs, 'tabs');

const Profile = () => {

    /* Image upload*/

    const [imageUrl, setImageUrl] = useState(null);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        console.log(reader, "result of file");

        reader.onloadend = () => {
            setImageUrl(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [age, setAge] = React.useState('');

    const handleChange2 = (event) => {
        setAge(event.target.value);
    };

    return (
        <div className='Profile'>
            <Grid container className='justi-center'>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Header />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={10}>
                    <Grid container >
                        <Grid xs={12} sm={12} md={12} lg={12} xl={4}>
                            <div className='Profile-left-main'>

                                <div>

                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="Vertical tabs example"
                                        className='vertiacal-tab-profile'

                                    >
                                        {tabs.map((row, ind) => {
                                            return (
                                                <Tab iconPosition="start" icon={row.icon} label={row.name} {...a11yProps(ind)} />
                                            )
                                        })}



                                    </Tabs>


                                </div>
                            </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={12} xl={8}>
                            <div className='Profile-right-main'>
                                <TabPanel value={value} index={0}>
                                    <div className='tab-1'>
                                        <div>
                                            Personal Details
                                        </div>
                                    </div>
                                    <Grid container>
                                        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <div className='profile-change'>
                                                <TextField id="outlined-basic" label="First Name" variant="outlined" />
                                            </div>

                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <div className='profile-change'>
                                                <TextField id="outlined-basic" label="Last Name" variant="outlined" />
                                            </div>
                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className='profile-change'>
                                                <TextField id="outlined-basic" label="Phone" type='number' variant="outlined" />
                                            </div>
                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>

                                            <div className='profile-change'>
                                                <Box sx={{ maxWidth: 230 }}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">select-gender</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={age}
                                                            label="select-gender"
                                                            onChange={handleChange2}
                                                        >
                                                            <MenuItem value={10}>Male</MenuItem>
                                                            <MenuItem value={20}>Female</MenuItem>
                                                            {/* {/ <MenuItem value={30}>Thirty</MenuItem> /} */}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </div>
                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker']}>
                                                    <DatePicker label="Basic date picker" />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>

                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <div className='tab-1'>
                                        <div>
                                            Address Details
                                        </div>
                                    </div>
                                    <Grid container>
                                        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>

                                            <div className='profile-change'>
                                                <Box sx={{ maxWidth: 230 }}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">select-country</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={age}
                                                            label="select-country"
                                                            onChange={handleChange2}
                                                        >
                                                            <MenuItem value={10}>India</MenuItem>
                                                            <MenuItem value={20}>China</MenuItem>
                                                            {/* {/ <MenuItem value={30}>Thirty</MenuItem> /} */}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </div>

                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <div className='profile-change'>
                                                <TextField id="outlined-basic" label="State" variant="outlined" />
                                            </div>
                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <div className='profile-change'>
                                                <TextField id="outlined-basic" label="City" type='number' variant="outlined" />
                                            </div>
                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>


                                            <div className='profile-change'>
                                                <TextField id="outlined-basic" label="Zip/Postal Code" type='number' variant="outlined" />
                                            </div>

                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <div className='profile-change'>
                                                <TextField id="outlined-basic" label="Line 1" type='number' variant="outlined" />
                                            </div>
                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <div className='profile-change'>
                                                <TextField id="outlined-basic" label="Line 2" type='number' variant="outlined" />
                                            </div>
                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <div className='profile-change'>
                                                <Button variant='contained'>Next</Button>
                                            </div>
                                        </Grid>

                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <div className='tab-1'>
                                        <div>
                                            Document Upload
                                        </div>
                                    </div>
                                    <Grid container>
                                        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>

                                            <div className='profile-change'>
                                                <Box sx={{ maxWidth: 230 }}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Id-Card-Type</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={age}
                                                            label="Id-Card-Type"
                                                            onChange={handleChange2}
                                                        >
                                                            <MenuItem value={10}>Adhaar Card</MenuItem>
                                                            <MenuItem value={20}>Pan Card</MenuItem>
                                                            {/* {/ <MenuItem value={30}>Thirty</MenuItem> /} */}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </div>

                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <div className='profile-change'>
                                                <TextField id="outlined-basic" label="Identity Number" variant="outlined" />
                                            </div>
                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <div className='profile-avatarnew'>

                                                <div className='text-center'>Front Side</div>
                                                <Button
                                                    component="label"
                                                    role={undefined}
                                                    // variant="contained"
                                                    tabIndex={-1}
                                                // startIcon={<CloudUploadIcon />}

                                                >
                                                    <Avatar alt="Remy Sharp" src={imageUrl ? imageUrl : idfront} />
                                                    <VisuallyHiddenInput type="file" onChange={handleImageUpload} />
                                                </Button>
                                            </div>
                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>


                                            <div className='profile-avatarnew'>
                                                <div className='text-center'>Back Side</div>
                                                <Button
                                                    component="label"
                                                    role={undefined}
                                                    // variant="contained"
                                                    tabIndex={-1}
                                                // startIcon={<CloudUploadIcon />}

                                                >
                                                    <Avatar alt="Remy Sharp" src={imageUrl ? imageUrl : idfront} />
                                                    <VisuallyHiddenInput type="file" onChange={handleImageUpload} />
                                                </Button>


                                            </div>

                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <div className='profile-avatarnew'>
                                                <div className='text-center'>Selfile Photo</div>
                                                <Button
                                                    component="label"
                                                    role={undefined}
                                                    // variant="contained"
                                                    tabIndex={-1}
                                                // startIcon={<CloudUploadIcon />}

                                                >
                                                    <Avatar alt="Remy Sharp" src={imageUrl ? imageUrl : upload} />
                                                    <VisuallyHiddenInput type="file" onChange={handleImageUpload} />
                                                </Button>


                                            </div>
                                        </Grid>

                                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className='profile-change'>
                                                <Button variant='contained'>Next</Button>
                                            </div>
                                        </Grid>

                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                    Item Four
                                </TabPanel>
                                <TabPanel value={value} index={4}>
                                    Item Five
                                </TabPanel>
                                <TabPanel value={value} index={5}>
                                    Item Six
                                </TabPanel>
                                <TabPanel value={value} index={6}>
                                    Item Seven
                                </TabPanel>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Profile
