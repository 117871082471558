import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "./Login.scss";
import { useMediaQuery, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import lightlogo from "../../Img/light-mode-logo.png";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function Reset() {
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get("email"),
            password: data.get("password"),
        });
    };

    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up("md"));

    console.log(md, "md");

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div className="Login">
            <div className="logo">
                <Link to="/">
                    {" "}
                    <img src={lightlogo} />
                </Link>
            </div>
            <Grid
                container
                component="main"
                sx={{ justifyContent: "center" }}
            >
                <Grid item xs={11} sm={8} md={7} lg={7} xl={6}>
                    <Grid container sx={{ justifyContent: "center" }}>
                        <Grid item xs={12} sm={10} md={9} lg={6} xl={6}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                    padding: "25px",
                                    borderRadius: "20px",
                                }}
                            >
                                <Typography component="h1" variant="h5" className="text-center c-accout" sx={{ mt: 1, mb: 3 }}>
                                    Reset Password
                                </Typography>
                                <Box
                                    component="form"
                                    noValidate
                                    onSubmit={handleSubmit}
                                    sx={{ mt: 1 }}
                                >

                                    <div className="email-fill " style={{ marginTop: "10px" }} >
                                        <div className="email-name">
                                            Old Password
                                        </div>
                                        <FormControl sx={{ mt: 1, width: '100%' }} variant="outlined">
                                            {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label={
                                                                showPassword ? 'hide the password' : 'display the password'
                                                            }
                                                            onClick={handleClickShowPassword}

                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            // label="Password"
                                            />
                                        </FormControl>
                                    </div>


                                    <div className="email-fill " style={{ marginTop: "10px" }} >
                                        <div className="email-name">
                                            New Password
                                        </div>
                                        <FormControl sx={{ mt: 1, width: '100%' }} variant="outlined">
                                            {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label={
                                                                showPassword ? 'hide the password' : 'display the password'
                                                            }
                                                            onClick={handleClickShowPassword}

                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            // label="Password"
                                            />
                                        </FormControl>
                                    </div>


                                    <div className="email-fill " style={{ marginTop: "10px" }} >
                                        <div className="email-name">
                                            Confirm  Password
                                        </div>
                                        <FormControl sx={{ mt: 1, width: '100%' }} variant="outlined">
                                            {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label={
                                                                showPassword ? 'hide the password' : 'display the password'
                                                            }
                                                            onClick={handleClickShowPassword}

                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            // label="Password"
                                            />
                                        </FormControl>
                                    </div>

                                    <div className="login-btn">
                                        <Button
                                            type="submit"
                                            fullWidth
                                            sx={{ mt: 3, mb: 2 }}

                                        >
                                            Reset
                                        </Button>
                                    </div>


                                    <div className="text-center accnt-sign mt-10">
                                        <Link to="/register" variant="body2">
                                            Don't have an account? <span>Sign Up</span>
                                        </Link>
                                    </div>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
