// TradingViewWidget.jsx
// import React, { useEffect, useRef, memo } from 'react';

// function Tradeview() {
//     const container = useRef();

//     useEffect(
//         () => {
//             const script = document.createElement("script");
//             script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
//             script.type = "text/javascript";
//             script.async = true;
//             script.innerHTML = `
//         {
//           "width": "100%",
//           "height": "610",
//           "symbol": "NASDAQ:AAPL",
//           "interval": "D",
//           "timezone": "Etc/UTC",
//           "theme": "${window.localStorage.getItem('theme') === 'true' ? "light" : "dark"}" ,
//           "style": "1",
//           "locale": "en",
//           "allow_symbol_change": true,
//           "calendar": false,
//           "support_host": "https://www.tradingview.com"
//         }`;
//             container.current.appendChild(script);
//         },
//         []
//     );

//     return (
//         <div className="tradingview-widget-container" ref={container}>
//             <div className="tradingview-widget-container__widget"></div>
//             <div className="tradingview-widget-copyright"><a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a></div>
//         </div>
//     );
// }

// export default memo(Tradeview);


import TradingViewWidget, { Themes } from 'react-tradingview-widget';

const Tradeview = ({ pair }) => (
    <TradingViewWidget symbol={`Binance:${pair}`} id='tdview' theme={Themes.DARK} />
);

export default Tradeview;