import { Grid } from '@mui/material'
import React from 'react'
import Header from '../Header/Header'
import './Payment.scss'
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import HttpsIcon from '@mui/icons-material/Https';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';
import darkarrow from '../../Img/dark-Layer.png';
import lightarrow from '../../Img/light-layer.png';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};




const tabs = [
    { name: 'Bank Payment', icon: <PersonIcon /> },
    { name: 'UPI', icon: <HttpsIcon /> },
]

// console.log(tabs, 'tabs');



const BankAccount = () => {

    const [acctype, setAcctype] = React.useState('');

    const handleAcctype = (event) => {
        setAcctype(event.target.value);
    };

    return (
        <div className='bank new-account'>
            <Grid container className='justi-center'>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Header />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={10}>
                <div className='account-head'>
                <div class="ivon-back">
                    <Link to="/payment-option" className='light-mode-img'><img src={darkarrow}/></Link>
                    
                    <Link to="/payment-option" className="dark-mode-img"><img src={lightarrow}  /> </Link>
                </div>
                <Typography variant="h1" className='cmn-text'>COMMON.ADD_BANK</Typography>
                </div>
               

                    <Grid container className="container sitecontainer payment-left-main" spacing={2}>
                                            <Grid xs={12} sm={12} md={8} lg={6} xl={6}>
                                                <div className='profile-change'>
                                                {/* <Typography component='p'>ACCOUNT NUMBER</Typography> */}
                                                    <TextField id="outlined-basic" label="Account number" variant="outlined" />
                                                </div>

                                            </Grid>
                                            <Grid xs={12} sm={12} md={8} lg={6} xl={6}>
                                                <div className='profile-change'>
                                                {/* <Typography component='p'>BANK NAME</Typography> */}
                                                    <TextField id="outlined-basic" label="bank name" variant="outlined" />
                                                </div>
                                            </Grid>
                                            <Grid xs={12} sm={12} md={8} lg={6} xl={6}>
                                                <div className='profile-change'>
                                                {/* <Typography component='p'>IFSC CODE</Typography> */}
                                                    <TextField id="outlined-basic" label="IFSC code" type='text' variant="outlined" />
                                                </div>
                                            </Grid>
                                            <Grid xs={12} sm={12} md={8} lg={6} xl={6}>
                                            <div className='profile-change'>
                                                <Box>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Account Type</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={acctype}
                                                            label="select-gender"
                                                            onChange={handleAcctype}
                                                        >
                                                            <MenuItem value={10}>Account Type</MenuItem>
                                                            <MenuItem value={20}>Current</MenuItem>
                                                            <MenuItem value={30}>Savings</MenuItem>
                                                            {/* <MenuItem value={30}>Thirty</MenuItem> */}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </div>
                                            </Grid>
                                            <Grid xs={12} sm={12} md={8} lg={6} xl={6}>
                                                <div className='profile-change submit'>
                                                    <TextField  type='submit' variant="outlined" className="action-button sitebtn" />
                                                </div>
                                            </Grid>
                                            

                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default BankAccount;
