import { Button } from '@mui/material';
import React, { useEffect, useContext } from 'react';
import sun from '../../Img/sun.gif';
import './Darkmode.scss';
import { Sun } from '../../SvgCollection';
import { ThemeContext } from './ThemeProvider';

function Darkmode() {
    const { isLightMode, handleDarkmode } = useContext(ThemeContext);

    // useEffect(() => {
    //     console.log('isLightMode:', isLightMode); // Check the value on component mount
    // }, [isLightMode]);

    return (
        <div className='darkmode'>
            <Button onClick={handleDarkmode}>
                {isLightMode ? <img src={sun} alt='sun' /> : <Sun />}
            </Button>
        </div>
    );
}

export default Darkmode;
