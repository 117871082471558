import { Grid, Box, Button } from '@mui/material'
import React, { useState } from 'react'
import Header from '../Header/Header'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import darkarrow from "../../Img/dark-Layer.png";
import lightarrow from "../../Img/light-layer.png";
import { Link } from "react-router-dom";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const names = [
    'BANK TRANSFER',
    'UPI',
    'GPAY',
    'GPAy-PHILLIPINES',

];

function PostNewAd() {


    const [personName, setPersonName] = React.useState([]);

    const handleChange3 = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const navigate = useNavigate()

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [age, setAge] = useState(10);

    const handleChange2 = (event) => {
        setAge(event.target.value);
    };

    const [payment, setPayment] = useState(10);

    const handleChangePayment = (event) => {
        setPayment(event.target.value);
    };


    return (
        <div className='post-new-Ad'>
            <Grid container className='justi-center'>

                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Header />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <div class="ivon-back">
            <Link to="/p2p" className="light-mode-img">
              <img src={darkarrow} />
            </Link>
            <Link to="/p2p" className="dark-mode-img">
              <img src={lightarrow} />
            </Link>
          </div> 
                    <div className='post-ad-title text-center'>
                   
                        Post Normal Ad
                    </div>

                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={10}>
                    {/* <div>
                        Sell Type & Price
                    </div> */}
                    <div className='postnew-ad buy-sell-main-tab2 ' >
                        <Box sx={{ padding: '10px 0px' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label=" I Want to Buy" className={value === 0 && 'buy-bg-clr'} {...a11yProps(0)} />
                                <Tab label='I Want to Sell' className={value === 1 && 'buy-bg-clr'} {...a11yProps(1)} />

                            </Tabs>
                        </Box>
                        <div className='asset-main'>
                            <CustomTabPanel value={value} index={0}>
                                <Grid container >
                                    <Grid xs={12} sm={5.5} md={5.5} lg={5.5} xl={5.5}>
                                        <div>
                                            Asset
                                            <div>
                                                <FormControl sx={{ m: 1, minWidth: '100%' }} size="small">
                                                    {/* <InputLabel id="demo-select-small-label">All Coin</InputLabel> */}
                                                    <Select
                                                        labelId="demo-select-small-label"
                                                        id="demo-select-small"
                                                        value={age}
                                                        // label="All Coin"
                                                        onChange={handleChange2}
                                                        className='p2p-select'
                                                    >

                                                        <MenuItem value={10}>USDT</MenuItem>
                                                        {/* <MenuItem value={20}>INR</MenuItem>
                                                        <MenuItem value={30}>USD</MenuItem>
                                                        <MenuItem value={40}>Euro</MenuItem> */}

                                                    </Select>
                                                </FormControl>

                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={1} md={1} lg={1} xl={1}>
                                        <div className='display-3'>
                                            <CompareArrowsIcon />
                                        </div>

                                    </Grid>
                                    <Grid xs={12} sm={5.5} md={5.5} lg={5.5} xl={5.5}>
                                        <div>
                                            Asset
                                            <div>
                                                <FormControl sx={{ m: 1, minWidth: '100%' }} size="small">
                                                    {/* <InputLabel id="demo-select-small-label">All Coin</InputLabel> */}
                                                    <Select
                                                        labelId="demo-select-small-label"
                                                        id="demo-select-small"
                                                        value={payment}
                                                        // label="All Coin"
                                                        className='p2p-select'
                                                        onChange={handleChangePayment}
                                                    >

                                                        <MenuItem value={10}>INR</MenuItem>
                                                        {/* <MenuItem value={20}>Net Banking</MenuItem>
                                                        <MenuItem value={30}>Gpay</MenuItem>
                                                        <MenuItem value={40}>UPI</MenuItem> */}

                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div className='pric-vol-text'>
                                            Select Payment Method
                                        </div>
                                        <FormControl sx={{ m: 1, width: { xs: '100%', sm: '300px' } }} className='multi-select'>
                                            {/* <InputLabel id="demo-multiple-checkbox-label">Select Payment Method</InputLabel> */}
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                value={personName}
                                                onChange={handleChange3}
                                                input={<OutlinedInput placeholder='Select Payment Method' />}
                                                className='p2p-select'
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                            >
                                                {names.map((name) => (
                                                    <MenuItem key={name} value={name}>
                                                        <Checkbox checked={personName.indexOf(name) > -1} />
                                                        <ListItemText primary={name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <div className='pric-vol-text'>
                                            Price
                                        </div>
                                        <div className='pric-vol'>
                                            <TextField id="outlined-basic" placeholder='90' variant="outlined" />
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <div className='pric-vol-text'>
                                            Volume
                                        </div>
                                        <div className='pric-vol'>
                                            <TextField id="outlined-basic" placeholder='90' variant="outlined" />
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <div className='pric-vol-text'>
                                            Minimum Limit
                                        </div>
                                        <div className='pric-vol'>
                                            <TextField id="outlined-basic" placeholder='90' variant="outlined" />
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <div className='pric-vol-text'>
                                            Maximum Limit
                                        </div>
                                        <div className='pric-vol'>
                                            <TextField id="outlined-basic" placeholder='90' variant="outlined" />
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={2} md={2} lg={2} xl={2}>
                                        <div className='post-ad'>
                                            <Button variant='contained'>
                                                Post Ad
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div>


                                </div>
                            </CustomTabPanel>
                        </div>
                        <div className='asset-main'>
                            <CustomTabPanel value={value} index={1}>
                                <Grid container>
                                    <Grid xs={12} sm={5.5} md={5.5} lg={5.5} xl={5.5}>
                                        <div>
                                            Asset
                                            <div>
                                                <FormControl sx={{ m: 1, minWidth: '100%' }} size="small">
                                                    {/* <InputLabel id="demo-select-small-label">All Coin</InputLabel> */}
                                                    <Select
                                                        labelId="demo-select-small-label"
                                                        id="demo-select-small"
                                                        value={age}
                                                        // label="All Coin"
                                                        onChange={handleChange2}
                                                        className='p2p-select'
                                                    >

                                                        <MenuItem value={10}>USDT</MenuItem>
                                                        {/* <MenuItem value={20}>INR</MenuItem>
                                                        <MenuItem value={30}>USD</MenuItem>
                                                        <MenuItem value={40}>Euro</MenuItem> */}

                                                    </Select>
                                                </FormControl>

                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={1} md={1} lg={1} xl={1}>
                                        <div className='display-3'>
                                            <CompareArrowsIcon />
                                        </div>

                                    </Grid>
                                    <Grid xs={12} sm={5.5} md={5.5} lg={5.5} xl={5.5}>
                                        <div>
                                            Asset
                                            <div>
                                                <FormControl sx={{ m: 1, minWidth: '100%' }} size="small">
                                                    {/* <InputLabel id="demo-select-small-label">All Coin</InputLabel> */}
                                                    <Select
                                                        labelId="demo-select-small-label"
                                                        id="demo-select-small"
                                                        value={payment}
                                                        // label="All Coin"
                                                        onChange={handleChangePayment}
                                                        className='p2p-select'
                                                    >

                                                        <MenuItem value={10}>INR</MenuItem>
                                                        {/* <MenuItem value={20}>Net Banking</MenuItem>
                                                        <MenuItem value={30}>Gpay</MenuItem>
                                                        <MenuItem value={40}>UPI</MenuItem> */}

                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div className='pric-vol-text'>
                                            Select Payment Method
                                        </div>
                                        <FormControl sx={{ m: 1, width: { xs: '100%', sm: '300px' } }} className='multi-select'>
                                            {/* <InputLabel id="demo-multiple-checkbox-label">Select Payment Method</InputLabel> */}
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                value={personName}
                                                onChange={handleChange3}
                                                input={<OutlinedInput placeholder='Select Payment Method' />}

                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                                className='p2p-select'
                                            >
                                                {names.map((name) => (
                                                    <MenuItem key={name} value={name}>
                                                        <Checkbox checked={personName.indexOf(name) > -1} />
                                                        <ListItemText primary={name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <div className='pric-vol-text'>
                                            Price
                                        </div>
                                        <div className='pric-vol'>
                                            <TextField id="outlined-basic" placeholder='90' variant="outlined" />
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <div className='pric-vol-text'>
                                            Volume
                                        </div>
                                        <div className='pric-vol'>
                                            <TextField id="outlined-basic" placeholder='90' variant="outlined" />
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <div className='pric-vol-text'>
                                            Minimum Limit
                                        </div>
                                        <div className='pric-vol'>
                                            <TextField id="outlined-basic" placeholder='90' variant="outlined" />
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <div className='pric-vol-text'>
                                            Maximum Limit
                                        </div>
                                        <div className='pric-vol'>
                                            <TextField id="outlined-basic" placeholder='90' variant="outlined" />
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={2} md={2} lg={2} xl={1}>
                                        <div className='post-ad'>
                                            <Button variant='contained'>
                                                Post Ad
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div>


                                </div>
                            </CustomTabPanel>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default PostNewAd
