import axios from "axios";


export default function Endpoints() {

    const getCall = async (data) => {
        const { url } = data;
        const rawResponse = await fetch(url, {
            method: 'GET'
        })
        return rawResponse;
    }

    const getCallWithToken = async (data) => {
        const { url } = data;
        const validateToken = localStorage.getItem('Rikosta');
        const rawResponse = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${validateToken}`
            },
        })
        return rawResponse;
    }

    const postCall = async (data) => {
        const { url, payload } = data;
        const validateToken = localStorage.getItem('Rikosta');
        const rawResponse = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${validateToken}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
            },
            body: JSON.stringify(payload)
        })
        return rawResponse;
    }

    const tokenCall = async (data) => {
        const { url, payload, token } = data;
        console.log(data, 'data');

        const rawResponse = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${token}`,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
            },
            body: JSON.stringify(payload)
        })
        return rawResponse;
    }

    const postCallWithoutAuthToken = async (data) => {

        const { url, payload } = data;
        let rawResponse
        try {
            rawResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            })
        }
        catch (error) {
            console.log(error, 'er');

        }
        return rawResponse;
    }

    const postCallTokenonly = async (data) => {
        const { url } = data;
        const validateToken = localStorage.getItem('Rikosta');
        const rawResponse = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${validateToken}`
            }
        })
        return rawResponse;
    }



    return {
        postCallWithoutAuthToken, getCall, postCall, postCallTokenonly, getCallWithToken, tokenCall
    }
}


