import React, { useState } from "react";
import Header from "../Header/Header";
import { Grid, Box, Avatar } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import darkarrow from "../../Img/dark-Layer.png";
import lightarrow from "../../Img/light-layer.png";
import "./CopySettings.scss";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';  
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const CopySettings = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className='copysettings-full'>
         <Header />
         <div className="contain-width">
         <Grid container>
         <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
           <div className='copysetting-banner display-1'>
           <div class="ivon-back">
            <Link to="/copytrade" className="light-mode-img">
              <img src={darkarrow} />
            </Link>
            <Link to="/copytrade" className="dark-mode-img">
              <img src={lightarrow} />
            </Link>
          </div>
          <h1>Public Copy Settings</h1>
           </div>
         </Grid>
         <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className='setting-details-cnt'>
            <h2>Contract God of Wealth</h2>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Fixed ratio" {...a11yProps(0)} />
          <Tab label="Fixed Amount" {...a11yProps(1)} />
        </Tabs>
        <CustomTabPanel value={value} index={0}>
        <Grid container>
        <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
        <div className="fixed-ratio-cnt">
           <p>* Orders will be opened in proportion (Your available margin balance/ Leader trader's available margin balance)</p>
           <div className="lock-up-cnt display-2">
             <p>Lock-up Period</p>
             <p>7D</p>
           </div>
           <div className="ratio-amount-field">
            <label>Copy Amount</label>
            <TextField fullWidth id="fullWidth" placeholder="50-200,000" />
           </div>
        </div>
        <div className="fixed-ratio-cnt">

           <div className="ratio-amount-field">
            <label>Total Stop Loss</label>
            <TextField fullWidth id="fullWidth" placeholder="0-95" />
            <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam, laborum non nostrum libero impedit aspernatur architecto fugiat labore amet. Cumque modi possimus maiores dicta. Rerum aliquam harum quam qui amet?</span>
           </div>
        </div>
        </Grid>
        
        </Grid>
        
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <Grid container>
        <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
        <div className="fixed-ratio-cnt">
           <p>* Each order will be opened at a fixed margin amount (cost per order).</p>
           <div className="lock-up-cnt display-2">
             <p>Lock-up Period</p>
             <p>7D</p>
           </div>
           <div className="ratio-amount-field">
            <label>Cost Per Order</label>
            <TextField fullWidth id="fullWidth" placeholder="10-1,000"/>
           </div>
        </div>
        <div className="fixed-ratio-cnt">
        <div className="ratio-amount-field">
            <label>Copy Amount</label>
            <TextField fullWidth id="fullWidth" placeholder="50-200,000" />
           </div>
        </div>
        <div className="fixed-ratio-cnt">

           <div className="ratio-amount-field">
            <label>Total Stop Loss</label>
            <TextField fullWidth id="fullWidth" placeholder="0-95"/>
            <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam, laborum non nostrum libero impedit aspernatur architecto fugiat labore amet. Cumque modi possimus maiores dicta. Rerum aliquam harum quam qui amet?</span>
           </div>
        </div>
        </Grid>
        
        </Grid>
      </CustomTabPanel>

    
          </div>
         </Grid>
         <Grid xs={12} sm={12} md={12} lg={13} xl={6}>
         <div className="advanced-setting">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
         Advanced Settings
        </AccordionSummary>
        <AccordionDetails>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </AccordionDetails>
      </Accordion>

      </div>
         </Grid>
         </Grid>
         </div>
    </div>
  )
}

export default CopySettings
