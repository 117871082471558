import { Grid } from "@mui/material";
import React from "react";
import Header from "../Header/Header";
import "./Earning.scss";
import income from "../../Img/earning1.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import nodata from "../../Img/thankyou.gif";



const Earning = () => {
  const cards = [
    { img: `${income}`, earn: "Total income", value: "0 USDT" },
    { img: `${income}`, earn: "Staking income", value: "0 USDT" },
    { img: `${income}`, earn: "Team Staking", value: "0 USDT" },
    { img: `${income}`, earn: "Total withdraw", value: "0 USDT" },
    { img: `${income}`, earn: "Main Balance", value: "0 USDT" },
  ];

  const cardElements = cards.map((card) => (
    <div className="card-part">
      <div className="earning-img">
        <div className="img-part">
          <img src={card.img} alt={card.img} />
        </div>
        <div className="card-detail">
          <div className="rank">{card.earn}</div>
          <div className="suit">{card.value}</div>
        </div>
      </div>
    </div>
  ));

  const tableHistory = [
    {
      date: "27.05.2024",
      stake: "400 days",
      coin: "BTC",
      interest: "percentage",
      amount: "0.003",
    },
    {
      date: "27.05.2024",
      stake: "400 days",
      coin: "BTC",
      interest: "percentage",
      amount: "0.003",
    },
    {
      date: "27.05.2024",
      stake: "400 days",
      coin: "BTC",
      interest: "percentage",
      amount: "0.003",
    },
    {
      date: "27.05.2024",
      stake: "400 days",
      coin: "BTC",
      interest: "percentage",
      amount: "0.003",
    },
    {
      date: "27.05.2024",
      stake: "400 days",
      coin: "BTC",
      interest: "percentage",
      amount: "0.003",
    },
  ];

  const tableElements = tableHistory.map((table) => (
    <TableRow>
      <TableCell align="left">{table.date}</TableCell>
      <TableCell align="center">{table.stake}</TableCell>
      <TableCell align="center">{table.coin}</TableCell>
      <TableCell align="center">{table.interest}</TableCell>
      <TableCell align="center">{table.amount}</TableCell>
    </TableRow>
  ));

  return (
    <div className="earning-full">
      <Grid container>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header />
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="support-head contain-width">
            <h1>Earning</h1>
          </div>
          <Grid container className="contain-width" spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="ticket-box">
                <div className="card-container">{cardElements}</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="ticket-box">
                <h3>TOTAL INCOME HISTORY</h3>
                <div className="history-table">
                  <TableContainer>
                    <Table aria-label="simple table" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Date & Time</TableCell>
                          <TableCell align="center">Stake Type</TableCell>
                          <TableCell align="center">Coin</TableCell>
                          <TableCell align="center">Interest Type</TableCell>
                          <TableCell align="center">Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* {tableHistory.map((row, ind) => {
                          return (
                            <TableRow>
                              <TableCell align="left">{row.date}</TableCell>
                              <TableCell align="center">{row.stake}</TableCell>
                              <TableCell align="center">{row.coin}</TableCell>
                              <TableCell align="center">
                                {row.interest}
                              </TableCell>
                              <TableCell align="center">{row.amount}</TableCell>
                            </TableRow>
                          );
                        })} */}
                        {tableElements}
                        <TableCell align="center" colSpan={10}>
                          <div className="nodata">
                            <img src={nodata} alt="nodata" />
                            No Record Found
                          </div>
                        </TableCell>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Earning;
