import { Grid } from "@mui/material";
import React, { useContext } from "react";
import Header from "../Header/Header";
import "./footer.scss";

import { Link } from "react-router-dom";

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import { ThemeContext } from '../DarkMode/ThemeProvider';

import qrcode from '../../Img/qrcode.png';
import social1 from '../../Img/social1.png';
import social2 from '../../Img/social2.png';
import social3 from '../../Img/social3.png';
import social4 from '../../Img/social4.png';
import social5 from '../../Img/social5.png';
import social6 from '../../Img/social6.png';
import social7 from '../../Img/social7.png';
import social8 from '../../Img/social8.png';
import lightlogo from '../../Img/light-mode-logo.png';
import darkLogo from "../../Img/reglogo.png";

const Footer = () => {

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const { isLightMode, handleDarkmode } = useContext(ThemeContext);
    return (
        <div>
            <footer>
                <div className="contain-width">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={9} xl={10} sx={{ margin: "Auto" }} >
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                                    <img src={isLightMode ? darkLogo : lightlogo} className="logo" />
                                    <ul className="social" >
                                        <li><a href="#"><img src={social1} alt="img" /></a></li>
                                        <li><a href="#"><img src={social2} alt="img" /></a></li>
                                        <li><a href="#"><img src={social3} alt="img" /></a></li>
                                        <li><a href="#"><img src={social4} alt="img" /></a></li>
                                        <li><a href="#"><img src={social5} alt="img" /></a></li>
                                        <li><a href="#"><img src={social6} alt="img" /></a></li>
                                        <li><a href="#"><img src={social7} alt="img" /></a></li>
                                        <li><a href="#"><img src={social8} alt="img" /></a></li>
                                    </ul>
                                    <p>Customer Support: @Kaaviexchange</p>
                                    <p>Business Cooperation: @Kaaviexchange</p>
                                    <p>Quant Trading & MM: bd@Kaaviexchange.com</p>
                                    <p>VIP Services: support@Kaaviexchange.com</p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={9} xl={9} >
                                    <div className="link_flx">
                                        <div className="links" >
                                            <h5>About</h5>
                                            <ul>
                                                <li><Link to="#">Crypto Information</Link></li>
                                                <li><Link to="#">Media Kit</Link></li>
                                                <li><Link to="#">Kaavi Community</Link></li>
                                                <li><Link to="#">Kaavi Zone</Link></li>
                                                <li><Link to="#">Announcements</Link></li>
                                            </ul>
                                        </div>
                                        <div className="links" >
                                            <h5>Compliance</h5>
                                            <ul>
                                                <li><Link to="#">Legal Statement</Link></li>
                                                <li><Link to="#">Risk Disclosure</Link></li>
                                                <li><Link to="#">User Agreement</Link></li>
                                                <li><Link to="#">Privacy Policy</Link></li>
                                                <li><Link to="#">Whistleblower Notice</Link></li>
                                                <li><Link to="#">AML/KYC Policies</Link></li>
                                                <li><Link to="#">Law Enforcement</Link></li>
                                            </ul>
                                        </div>
                                        <div className="links" >
                                            <h5>Products</h5>
                                            <ul>
                                                <li><Link to="#">P2P</Link></li>
                                                <li><Link to="#">Futures Pro</Link></li>
                                                <li><Link to="#">Spot</Link></li>
                                                <li><Link to="#">Copy Trade</Link></li>
                                                <li><Link to="#">Margin Trade</Link></li>
                                            </ul>
                                        </div>
                                        <div className="links" >
                                            <h5>Services</h5>
                                            <ul>
                                                <li><Link to="#">Proof of Reserves</Link></li>
                                                <li><Link to="#">OTC</Link></li>
                                                <li><Link to="#">Download</Link></li>
                                                <li><Link to="#">Affiliate</Link></li>
                                            </ul>
                                        </div>
                                        <div className="links" >
                                            <h5>Support</h5>
                                            <ul>
                                                <li><Link to="#">Help Center</Link></li>
                                                <li><Link to="#">Fee Schedule</Link></li>
                                                <li><Link to="#">Trading Rules</Link></li>
                                                <li><Link to="#">Official Verification</Link></li>
                                                <li><Link to="#">Submit Feedback</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={8} sx={{ margin: "Auto" }} >
                            <div className="flx_blw">
                                <div>@2024 Kaavi Exchange All Rights Reserved</div>
                                {/* <div>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">English</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            label="Age"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={"English"}>English</MenuItem>
                                            <MenuItem value={"Russian"}>Russian</MenuItem> 
                                            <MenuItem value={"Spanish"}>Spanish</MenuItem> 
                                            <MenuItem value={"Italian"}>Italian</MenuItem> 
                                            <MenuItem value={"French"}>French</MenuItem> 
                                        </Select>
                                    </FormControl>
                                </div> */}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
