import { Grid } from "@mui/material";
import React from "react";
import Header from "../Header/Header";
import "./Referral.scss";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import nodata from "../../Img/thankyou.gif";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { TextField } from "@mui/material";
import consts from "../../constant";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Referral = () => {

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const tableHistory = [
    {
      coinname: "BTC/USDT",
      stake: "0.00122",
      coin: "0.001217",
      interest: "-2.57% ",
    },
    {
      coinname: "ETH/USDT",
      stake: "0.00019",
      coin: "0.1552",
      interest: "-2.57% ",
    },
    {
      coinname: "THT/USDT",
      stake: "0.00122",
      coin: "0.001217",
      interest: "-2.57% ",
    },
    {
      coinname: "SHIB/USDT",
      stake: "0.00122",
      coin: "0.1552",
      interest: "-2.57% ",
    },
    {
      coinname: "MANA/BTC",
      stake: "0.00019",
      coin: "0.001217",
      interest: "-2.57% ",
    },
  ];

  const tableElements = tableHistory.map((table) => (
    <TableRow className="cursor">
      <TableCell align="left">
        <div className="display-2 coin-gap">
          <div className="coin-img">{/* <img src={table.img} /> */}</div>
          <div className="coin-name">{table.coinname}</div>
        </div>
      </TableCell>
      <TableCell align="left">{table.stake}</TableCell>
      <TableCell align="left">{table.coin}</TableCell>
      <TableCell align="left">
        <div className="low-bg">
          {" "}
          <span>{table.interest}</span>{" "}
        </div>
      </TableCell>
      <TableCell align="center">{table.amount}</TableCell>
      <TableCell align="center" className="trade-btn">
        <Link to={`/trade/${consts?.MainPair}`}> {table.trade}</Link>
      </TableCell>
    </TableRow>
  ));

  return (
    <div className="referral-full">
      <Grid container>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header />
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="support-head contain-width">
            <h1>Referral Information</h1>
          </div>
          <Grid container className="contain-width" spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="ticket-box market-tab">
                <div className="referral-info">
                  <div className="field-box">
                    <label>Refer URL</label>
                    <TextField
                      fullWidth
                      disabled
                    />
                    {/* <Button onClick={handleCopy}><ContentCopyIcon/></Button>
                    {copySuccess && <span>{copySuccess}</span>} */}
                  </div>
                  <div className="field-box">
                    <label>Refer ID </label>
                    <TextField fullWidth value="ZU612964127" disabled />
                  </div>
                  <div className="field-box">
                    <label>My Role</label>
                    <TextField fullWidth value="personal" disabled />
                  </div>
                  <div className="field-box">
                    <label>My overall stake</label>
                    <TextField fullWidth disabled value="0" />
                  </div>
                  <div className="field-box">
                    <label>My bussiness</label>
                    <TextField fullWidth disabled value="0" />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="ticket-box">
                <h4>LEVEL INFO</h4>
                <div className="gen-tab">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Gen2" {...a11yProps(0)} />
                  </Tabs>
                  <CustomTabPanel value={value} index={0}>
                    <div className="details">
                      <TableContainer>
                        <Table aria-label="simple table" stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">S.NO</TableCell>
                              <TableCell align="left">Name</TableCell>
                              <TableCell align="left">Email</TableCell>
                              <TableCell align="left">Role</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>

                            {/* {tableElements} */}
                            <TableCell align="center" colSpan={10}>
                              <div className="nodata">
                                <img src={nodata} alt="nodata" />
                                No Record Found
                              </div>
                            </TableCell>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </CustomTabPanel>
                </div>
              </div>

            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Referral;
