import React, { useState, useEffect } from 'react'
import { Button, Grid } from '@mui/material'
import Header from '../Header/Header'
import Footer from '../Footer/footer'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import './Copytrade.css'
import Box from '@mui/material/Box';
import { Bgeye, Fire, Profile } from '../../SvgCollection';
import { Link } from 'react-router-dom';

import kcs from '../../Img/coins/kcs.png'
import scr from '../../Img/coins/scr.png'
import cat from '../../Img/coins/cat.png'
import Avatar from '@mui/material/Avatar';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import prf from '../../Img/prf.png';
import card_chart from '../../Img/card_chart.png';
import card_icon from '../../Img/card_icon.png';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Copytrade() {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <div className='copytrade'>
            <Grid container sx={{ justifyContent: 'center' }}>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Header />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container sx={{ justifyContent: 'center' }} className='copytrade-banner'>
                        <Grid xs={12} sm={12} md={12} lg={10} xl={9}>
                            <div className="padding-10">
                                <div className="display-2 " style={{ flexDirection: !matches && 'column', gap: "20px" }}>
                                    <div className="copy-headung">
                                        Copy Trading Pro
                                    </div>
                                    <div className="copy-h-tbn display-1 cursor">

                                        <Profile />
                                        Become a top trader
                                    </div>
                                </div>
                                <div className="display-2 " style={{ justifyContent: !matches && 'center' }}>
                                    <div className="copy-h-para mt-20">
                                        Copy expert strategies and capture market signals
                                        <div className="mt-20 display-1">
                                            <Avatar
                                                alt="Remy Sharp"
                                                src="/static/images/avatar/1.jpg"
                                                sx={{ width: 30, height: 30 }}
                                            />
                                            <div className="lgIn">Login</div>
                                            <Bgeye />
                                        </div>
                                        <div className="mt-20 display-2">
                                            <div className="">
                                                ****
                                                <div className="mt-10 copy-sub-p">
                                                    Copy trading balance (USDT)
                                                </div>
                                            </div>
                                            <div className="">
                                                ****
                                                <div className="mt-10 copy-sub-p">
                                                    Copy trading net profit (USDT)
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={10} xl={9}>
                    <Box sx={{ width: '100%' }}>
                        <Box >
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='copytrade-tab'>
                                <Tab label={<div className="display-1">
                                    <Fire />Top traders
                                </div>
                                } {...a11yProps(0)} />
                                <Tab label="All traders" {...a11yProps(1)} />

                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <div className="display-2">
                                <div className="cpy-h">
                                    Highest copy trading PnL (3W)
                                </div>
                            </div>
                            <section className="card_box">
                                <div className="contain-width">
                                    <Grid container spacing={2} sx={{ justifyContent: "space-between" }} >
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={3} >
                                            <div className="box cursor">
                                                <div className="h_flx">
                                                    <div className="flx" >
                                                        <img src={prf} alt="img" />
                                                        <div class="w-100">
                                                            <h5>Xiao Hai</h5>
                                                            <span>Too busy making money to …</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                                        <div>8 / <div className="grey">100</div></div>
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <div>
                                                        <div className="green">+6.64%</div>
                                                        <span>ROI</span>
                                                    </div>
                                                    <div className="days">30 days</div>
                                                </div>
                                                <img src={card_chart} alt="img" />
                                                <div className="f_box">
                                                    <div>
                                                        <div>Total PnL (3W)</div>
                                                        <div>$356.54</div>
                                                    </div>
                                                    <div>
                                                        <div>Copy trading PnL (3W)</div>
                                                        <div>$29550.81</div>
                                                    </div>
                                                    <div>
                                                        <div>Win rate (3W)</div>
                                                        <div>65.80%</div>
                                                    </div>
                                                    <div>
                                                        <div className="coins" >
                                                            <img src={kcs} alt="img" />
                                                            <img src={scr} alt="img" />
                                                            <img src={cat} alt="img" />
                                                        </div>
                                                        <div>
                                                            <img src={card_icon} alt="img" className="icon" />10%</div>
                                                    </div>
                                                </div>
                                                <Link to={"#"} className="copy_btn" >Copy</Link>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={3} >
                                            <div className="box cursor">
                                                <div className="h_flx">
                                                    <div className="flx" >
                                                        <img src={prf} alt="img" />
                                                        <div class="w-100">
                                                            <h5>Xiao Hai</h5>
                                                            <span>Too busy making money to …</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                                        <div>8 / <div className="grey">100</div></div>
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <div>
                                                        <div className="green">+6.64%</div>
                                                        <span>ROI</span>
                                                    </div>
                                                    <div className="days">30 days</div>
                                                </div>
                                                <img src={card_chart} alt="img" />
                                                <div className="f_box">
                                                    <div>
                                                        <div>Total PnL (3W)</div>
                                                        <div>$356.54</div>
                                                    </div>
                                                    <div>
                                                        <div>Copy trading PnL (3W)</div>
                                                        <div>$29550.81</div>
                                                    </div>
                                                    <div>
                                                        <div>Win rate (3W)</div>
                                                        <div>65.80%</div>
                                                    </div>
                                                    <div>
                                                        <div className="coins" >
                                                            <img src={kcs} alt="img" />
                                                            <img src={scr} alt="img" />
                                                            <img src={cat} alt="img" />
                                                        </div>
                                                        <div>
                                                            <img src={card_icon} alt="img" className="icon" />10%</div>
                                                    </div>
                                                </div>
                                                <Link to={"#"} className="copy_btn" >Copy</Link>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={3} >
                                            <div className="box">
                                                <div className="h_flx">
                                                    <div className="flx" >
                                                        <img src={prf} alt="img" />
                                                        <div class="w-100">
                                                            <h5>Xiao Hai</h5>
                                                            <span>Too busy making money to …</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                                        <div>8 / <div className="grey">100</div></div>
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <div>
                                                        <div className="green">+6.64%</div>
                                                        <span>ROI</span>
                                                    </div>
                                                    <div className="days">30 days</div>
                                                </div>
                                                <img src={card_chart} alt="img" />
                                                <div className="f_box">
                                                    <div>
                                                        <div>Total PnL (3W)</div>
                                                        <div>$356.54</div>
                                                    </div>
                                                    <div>
                                                        <div>Copy trading PnL (3W)</div>
                                                        <div>$29550.81</div>
                                                    </div>
                                                    <div>
                                                        <div>Win rate (3W)</div>
                                                        <div>65.80%</div>
                                                    </div>
                                                    <div>
                                                        <div className="coins" >
                                                            <img src={kcs} alt="img" />
                                                            <img src={scr} alt="img" />
                                                            <img src={cat} alt="img" />
                                                        </div>
                                                        <div>
                                                            <img src={card_icon} alt="img" className="icon" />10%</div>
                                                    </div>
                                                </div>
                                                <Link to={"#"} className="copy_btn" >Copy</Link>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </section>
                            <div className="display-2">
                                <div className="cpy-h">
                                    Highest total PnL (3W)
                                </div>
                            </div>
                            <section className="card_box">
                                <div className="contain-width">
                                    <Grid container spacing={2} sx={{ justifyContent: "space-between" }} >
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={3} >
                                            <div className="box">
                                                <div className="h_flx">
                                                    <div className="flx" >
                                                        <img src={prf} alt="img" />
                                                        <div class="w-100">
                                                            <h5>Xiao Hai</h5>
                                                            <span>Too busy making money to …</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                                        <div>8 / <div className="grey">100</div></div>
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <div>
                                                        <div className="green">+6.64%</div>
                                                        <span>ROI</span>
                                                    </div>
                                                    <div className="days">30 days</div>
                                                </div>
                                                <img src={card_chart} alt="img" />
                                                <div className="f_box">
                                                    <div>
                                                        <div>Total PnL (3W)</div>
                                                        <div>$356.54</div>
                                                    </div>
                                                    <div>
                                                        <div>Copy trading PnL (3W)</div>
                                                        <div>$29550.81</div>
                                                    </div>
                                                    <div>
                                                        <div>Win rate (3W)</div>
                                                        <div>65.80%</div>
                                                    </div>
                                                    <div>
                                                        <div className="coins" >
                                                            <img src={kcs} alt="img" />
                                                            <img src={scr} alt="img" />
                                                            <img src={cat} alt="img" />
                                                        </div>
                                                        <div>
                                                            <img src={card_icon} alt="img" className="icon" />10%</div>
                                                    </div>
                                                </div>
                                                <Link to={"#"} className="copy_btn" >Copy</Link>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={3} >
                                            <div className="box">
                                                <div className="h_flx">
                                                    <div className="flx" >
                                                        <img src={prf} alt="img" />
                                                        <div class="w-100">
                                                            <h5>Xiao Hai</h5>
                                                            <span>Too busy making money to …</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                                        <div>8 / <div className="grey">100</div></div>
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <div>
                                                        <div className="green">+6.64%</div>
                                                        <span>ROI</span>
                                                    </div>
                                                    <div className="days">30 days</div>
                                                </div>
                                                <img src={card_chart} alt="img" />
                                                <div className="f_box">
                                                    <div>
                                                        <div>Total PnL (3W)</div>
                                                        <div>$356.54</div>
                                                    </div>
                                                    <div>
                                                        <div>Copy trading PnL (3W)</div>
                                                        <div>$29550.81</div>
                                                    </div>
                                                    <div>
                                                        <div>Win rate (3W)</div>
                                                        <div>65.80%</div>
                                                    </div>
                                                    <div>
                                                        <div className="coins" >
                                                            <img src={kcs} alt="img" />
                                                            <img src={scr} alt="img" />
                                                            <img src={cat} alt="img" />
                                                        </div>
                                                        <div>
                                                            <img src={card_icon} alt="img" className="icon" />10%</div>
                                                    </div>
                                                </div>
                                                <Link to={"#"} className="copy_btn" >Copy</Link>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={3} >
                                            <div className="box">
                                                <div className="h_flx">
                                                    <div className="flx" >
                                                        <img src={prf} alt="img" />
                                                        <div class="w-100">
                                                            <h5>Xiao Hai</h5>
                                                            <span>Too busy making money to …</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                                        <div>8 / <div className="grey">100</div></div>
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <div>
                                                        <div className="green">+6.64%</div>
                                                        <span>ROI</span>
                                                    </div>
                                                    <div className="days">30 days</div>
                                                </div>
                                                <img src={card_chart} alt="img" />
                                                <div className="f_box">
                                                    <div>
                                                        <div>Total PnL (3W)</div>
                                                        <div>$356.54</div>
                                                    </div>
                                                    <div>
                                                        <div>Copy trading PnL (3W)</div>
                                                        <div>$29550.81</div>
                                                    </div>
                                                    <div>
                                                        <div>Win rate (3W)</div>
                                                        <div>65.80%</div>
                                                    </div>
                                                    <div>
                                                        <div className="coins" >
                                                            <img src={kcs} alt="img" />
                                                            <img src={scr} alt="img" />
                                                            <img src={cat} alt="img" />
                                                        </div>
                                                        <div>
                                                            <img src={card_icon} alt="img" className="icon" />10%</div>
                                                    </div>
                                                </div>
                                                <Link to={"#"} className="copy_btn" >Copy</Link>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </section>
                            <div className="display-2">
                                <div className="cpy-h">
                                    Highest win rate (3W)
                                </div>
                            </div>
                            <section className="card_box">
                                <div className="contain-width">
                                    <Grid container spacing={2} sx={{ justifyContent: "space-between" }} >
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={3} >
                                            <div className="box">
                                                <div className="h_flx">
                                                    <div className="flx" >
                                                        <img src={prf} alt="img" />
                                                        <div class="w-100">
                                                            <h5>Xiao Hai</h5>
                                                            <span>Too busy making money to …</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                                        <div>8 / <div className="grey">100</div></div>
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <div>
                                                        <div className="green">+6.64%</div>
                                                        <span>ROI</span>
                                                    </div>
                                                    <div className="days">30 days</div>
                                                </div>
                                                <img src={card_chart} alt="img" />
                                                <div className="f_box">
                                                    <div>
                                                        <div>Total PnL (3W)</div>
                                                        <div>$356.54</div>
                                                    </div>
                                                    <div>
                                                        <div>Copy trading PnL (3W)</div>
                                                        <div>$29550.81</div>
                                                    </div>
                                                    <div>
                                                        <div>Win rate (3W)</div>
                                                        <div>65.80%</div>
                                                    </div>
                                                    <div>
                                                        <div className="coins" >
                                                            <img src={kcs} alt="img" />
                                                            <img src={scr} alt="img" />
                                                            <img src={cat} alt="img" />
                                                        </div>
                                                        <div>
                                                            <img src={card_icon} alt="img" className="icon" />10%</div>
                                                    </div>
                                                </div>
                                                <Link to={"#"} className="copy_btn" >Copy</Link>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={3} >
                                            <div className="box">
                                                <div className="h_flx">
                                                    <div className="flx" >
                                                        <img src={prf} alt="img" />
                                                        <div class="w-100">
                                                            <h5>Xiao Hai</h5>
                                                            <span>Too busy making money to …</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                                        <div>8 / <div className="grey">100</div></div>
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <div>
                                                        <div className="green">+6.64%</div>
                                                        <span>ROI</span>
                                                    </div>
                                                    <div className="days">30 days</div>
                                                </div>
                                                <img src={card_chart} alt="img" />
                                                <div className="f_box">
                                                    <div>
                                                        <div>Total PnL (3W)</div>
                                                        <div>$356.54</div>
                                                    </div>
                                                    <div>
                                                        <div>Copy trading PnL (3W)</div>
                                                        <div>$29550.81</div>
                                                    </div>
                                                    <div>
                                                        <div>Win rate (3W)</div>
                                                        <div>65.80%</div>
                                                    </div>
                                                    <div>
                                                        <div className="coins" >
                                                            <img src={kcs} alt="img" />
                                                            <img src={scr} alt="img" />
                                                            <img src={cat} alt="img" />
                                                        </div>
                                                        <div>
                                                            <img src={card_icon} alt="img" className="icon" />10%</div>
                                                    </div>
                                                </div>
                                                <Link to={"#"} className="copy_btn" >Copy</Link>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={3} >
                                            <div className="box">
                                                <div className="h_flx">
                                                    <div className="flx" >
                                                        <img src={prf} alt="img" />
                                                        <div class="w-100">
                                                            <h5>Xiao Hai</h5>
                                                            <span>Too busy making money to …</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                                        <div>8 / <div className="grey">100</div></div>
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <div>
                                                        <div className="green">+6.64%</div>
                                                        <span>ROI</span>
                                                    </div>
                                                    <div className="days">30 days</div>
                                                </div>
                                                <img src={card_chart} alt="img" />
                                                <div className="f_box">
                                                    <div>
                                                        <div>Total PnL (3W)</div>
                                                        <div>$356.54</div>
                                                    </div>
                                                    <div>
                                                        <div>Copy trading PnL (3W)</div>
                                                        <div>$29550.81</div>
                                                    </div>
                                                    <div>
                                                        <div>Win rate (3W)</div>
                                                        <div>65.80%</div>
                                                    </div>
                                                    <div>
                                                        <div className="coins" >
                                                            <img src={kcs} alt="img" />
                                                            <img src={scr} alt="img" />
                                                            <img src={cat} alt="img" />
                                                        </div>
                                                        <div>
                                                            <img src={card_icon} alt="img" className="icon" />10%</div>
                                                    </div>
                                                </div>
                                                <Link to={"#"} className="copy_btn" >Copy</Link>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </section>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            Item Two
                        </CustomTabPanel>

                    </Box>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Footer />
                </Grid>
            </Grid >
        </div >
    )
}

export default Copytrade
