import { Button, Grid } from '@mui/material'
import React, { } from 'react'
import Header from '../Header/Header'
import './Payment.scss'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import HttpsIcon from '@mui/icons-material/Https';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import nodata from '../../Img/thankyou.gif'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}



const tabs = [
    { name: 'Bank Payment', icon: <PersonIcon /> },
    { name: 'UPI', icon: <HttpsIcon /> },
]

// console.log(tabs, 'tabs');

function Bankdata(
    bankname,
    accno,
    acctype,
    IFSC,
    action,
) {
    return { bankname, accno, acctype, IFSC, action };
}

const bankdatarows = [
    Bankdata('Indian', '656565767', 'joined', 'IND0001123', 'Remove'),
    Bankdata('Indian', '656565767', 'joined', 'IND0001123', 'Add'),
    Bankdata('Indian', '656565767', 'joined', 'IND0001123', 'Add'),
    Bankdata('Indian', '656565767', 'joined', 'IND0001123', 'Remove'),
];

function upidata(
    alias,
    upiid,
    qrcode,
    action,
) {
    return { alias, upiid, qrcode, action };
}

const upidatarows = [
    upidata('alias', 'abc@icici', 'hovercode', 'Remove'),
    upidata('alias', 'abc@sbi', 'hovercode', 'Add'),
    upidata('alias', 'abc@hdfc', 'hovercode', 'Add'),
    upidata('alias', 'abc@canara', 'hovercode', 'Remove'),
];

const Payment = () => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    return (
        <div className='payment'>
            <Grid container className='justi-center'>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Header />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={10}>
                <Typography variant="h1" className='cmn-text'>Payment Option</Typography>

                    <Grid container className='contain-width'>
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className='payment-left-main ticket-box'>

                                <div className='display-1'>

                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="Vertical tabs example"
                                        className='vertiacal-tab-payment'

                                    >
                                        {tabs.map((row, ind) => {
                                            return (
                                                <Tab iconPosition="start" icon={row.icon} label={row.name} {...a11yProps(ind)} />
                                            )
                                        })}

                                    </Tabs>

                                    <Link to="/newupi-account" class="btn add-ac">Add a New Account</Link>
                                </div>

                                <TabPanel value={value} index={0}>
                                    <div className='tab-1'>
                                        <div>
                                            {/* Bank Payment */}
                                        </div>
                                    </div>
                                    <Grid container>
                                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <TableContainer>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow className='row-head'>
                                                            <TableCell className='head-bg'>Bank Name</TableCell>
                                                            <TableCell className='head-bg'>Account Number</TableCell>
                                                            <TableCell className='head-bg'>Account Type</TableCell>
                                                            <TableCell className='head-bg'>IFSC Code</TableCell>
                                                            <TableCell className='head-bg'>Action</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {bankdatarows?.map((bankdatarows, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell component="th" scope="row" className='body-bg'>{bankdatarows.bankname}</TableCell>
                                                                <TableCell className='body-bg overflow'>{bankdatarows.accno}</TableCell>
                                                                <TableCell className='body-bg overflow'>{bankdatarows.acctype}</TableCell>
                                                                <TableCell className='body-bg'>{bankdatarows.IFSC}</TableCell>
                                                                <TableCell className='body-bg'>
                                                                    <div className='icon-space'>
                                                                        <Button className="badge badge-danger">{bankdatarows.action}</Button>
                                                                        {/* <Button > 
                                                        {<DownloadIcon color="primary" />}
                                                        </Button> */}
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                        <TableRow>
                                                             <TableCell align='center' colSpan={10}>
                                                            <div className='nodata'>
                                                                <img src={nodata} alt='nodata' />
                                                                No Record Found
                                                            </div>


                                                        </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <div className='tab-1'>
                                    </div>
                                    <Grid container>
                                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <TableContainer>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow className='row-head'>
                                                            <TableCell className='head-bg'>Alias</TableCell>
                                                            <TableCell className='head-bg'>UPI ID</TableCell>
                                                            <TableCell className='head-bg'>QR Code</TableCell>
                                                            <TableCell className='head-bg'>Action</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {upidatarows?.map((upidatarows, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell component="th" scope="row" className='body-bg'>{upidatarows.alias}</TableCell>
                                                                <TableCell className='body-bg overflow'>{upidatarows.upiid}</TableCell>
                                                                <TableCell className='body-bg overflow'>{upidatarows.qrcode}</TableCell>
                                                                <TableCell className='body-bg'>
                                                                    <div className='icon-space'>
                                                                        <Button className="badge badge-danger" onClick={() => { }}>{upidatarows.action}</Button>
                                                                        {/* <Button > 
                                                        {<DownloadIcon color="primary" />}
                                                        </Button> */}
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                        <TableRow>
                                                             <TableCell align='center' colSpan={10}>
                                                            <div className='nodata'>
                                                                <img src={nodata} alt='nodata' />
                                                                No Record Found
                                                            </div>


                                                        </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            </div>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Payment
